import React, { useContext, useMemo, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Icon from '../../atoms/Icon/Icon';
import Notification from '../../molecules/Notification/Notification';
import Cart from '../../organisms/Cart/Cart';
import CartContext from '../../../context/CartProvider';
import * as navCartStyles from './NavCart.module.css';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

/**
 * @param {string} mode - cart view mode, 'bubble' | 'drawer'
 * @param {object} styles - an object of class names
 * @returns {JSXElement} Nav Cart button
 */

const NavCart = ({ styles, mode }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const mergedStyles = { ...(styles || {}), ...navCartStyles };
  const cart = useContext(CartContext);

  const numberBadge = useMemo(() => {
    const numberItems = get(cart, 'state.cart.numberItems');

    if (!numberItems) {
      return null;
    }
    return <span className={mergedStyles.number}></span>;
  }, [cart, mergedStyles.number]);

  const isBubble = mode === 'bubble';

  const toggleDrawer = isOpen => () => {
    if (!isBubble) {
      setIsOpenDrawer(isOpen);
    }
  };
  return (
    <>
      <div className={navCartStyles.cartIconContainer} >
        <span role="presentation" onClick={toggleDrawer(true)}>
          <Icon symbol="bag" />
        </span>
      </div>
      {numberBadge}
      <Notification />
      {isBubble && (
        <div className={mergedStyles.userDropdown}>
          <Cart cartType="mini" />
        </div>
      )}
      {!isBubble && (
        <Drawer
          anchor="right"
          open={isOpenDrawer}
          PaperProps={{
            className: mergedStyles.miniCartDrawer
          }}
          onClose={toggleDrawer(false)}
        >
          <div className={mergedStyles.miniCartTitle}>
            <span className="subtitle" data-carttitle>
              <ContentfulTranslationText keyName="bag">Bag</ContentfulTranslationText>
            </span>
            <span
              role="presentation"
              data-closebtn
              onClick={toggleDrawer(false)}
            >
              <ContentfulTranslationText keyName="close">Close</ContentfulTranslationText>
            </span>
          </div>
          <Cart cartType="mini" onClick={toggleDrawer(false)}/>
        </Drawer>
      )}
    </>
  );
};

NavCart.propTypes = {
  styles: PropTypes.shape({}),
  mode: PropTypes.oneOf(['bubble', 'drawer'])
};

NavCart.defaultProps = {
  styles: {},
  mode: 'bubble'
};

export default NavCart;
