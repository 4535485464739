import React from 'react'

import Icon from '../../atoms/Icon/Icon';
import ContentfulTranslationText from '../ContentfulTranslationText/ContentfulTranslationText';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';
import * as styles from './Options.module.css'

const Options = ({ title, price, selected, disabled, selectOption, appendDollar = false, showChecked = false, isMulti = false, currency = 'AUD', isOos = false }) => {
    const selectedOption = selected ? styles.selected : '';
    
    return (
        <div
            role={'presentation'} 
            className={`${styles.root} ${selectedOption} ${disabled ? styles.disabled : ''} ${isOos ? styles.isOos : ''}`} 
            onClick={!disabled ? () => selectOption(!selected) : null}
            >
            <div className={`${styles.optionTitle}`}>{title}</div>
            {price && (
                <div className={styles.subtitle}>
                    {appendDollar === true ? <CurrencyFormatter currency={currency} amount={price} /> : price}
                </div>
            )}

            {isOos && <span className={styles.soldOut}><ContentfulTranslationText keyName="outOfStock">sold out</ContentfulTranslationText></span>}

            {isMulti && (
                <>
                {showChecked ?
                    <div className={`${styles.checkContainer} ${styles.checked}`}>
                        <div className={styles.checkIcon}>
                            <Icon symbol={'tick'}></Icon>
                        </div>
                    </div>
                    :
                    <div className={styles.checkContainer}></div>
                }
                </>
            )}
        </div>
    )
}

export default Options
