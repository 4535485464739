import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import * as styles from './RegionSelect.module.css';

const RegionSelect = props => {
  const currentPath = `/`; //`${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`;
  const regions = typeof window !== "undefined" ? [
    {enabled: true, lang: 'x-default', label: 'Global', path: `${currentPath}`},
    {enabled: true, lang: 'en-au', label: 'Australia/New Zealand', path: `/en-au${currentPath}`},
    {enabled: true, lang: 'en-eu', label: 'Europe', path: `/en-eu${currentPath}`},
    {enabled: true, lang: 'en-gb', label: 'United Kingdom', path: `/en-gb${currentPath}`},
    {enabled: true, lang: 'en-us', label: 'United States', path: `/en-us${currentPath}`},
    {enabled: false, lang: 'en-ca', label: 'Canada', path: `/en-ca${currentPath}`},
    {enabled: false, lang: 'de-de', label: 'Germany', path: `/de-de${currentPath}`},
    {enabled: true, lang: 'it-it', label: 'Italy', path: `/it-it${currentPath}`},
  ] : [{lang: 'x-default', path: `/`}];

  return (
    <div className={ styles.regionSelect }>
      {regions.filter(region => region.enabled).map((region, rIndex) => (
        <a href={region.path} key={rIndex}><Icon symbol={region.lang} /> <span>{region.label}</span></a>
      ))}
    </div>
  );
};

export default RegionSelect;
