import React from 'react'

const Minus = () => {
    return (  
        <svg viewBox='0 0 27 3' fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke="#000" strokeWidth="3" d="M27 1.5H0"/>
        </svg>
    );
}
 
export default Minus;