import React from 'react'

const featureTech = () => (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="8" y1="-4.37114e-08" x2="8" y2="28" stroke="currentColor" strokeWidth="2"/>
        <line x1="20" y1="3" x2="20" y2="25" stroke="currentColor" strokeWidth="2"/>
        <line x1="14" y1="6" x2="14" y2="22" stroke="currentColor" strokeWidth="2"/>
        <line x1="1" y1="6" x2="1" y2="22" stroke="currentColor" strokeWidth="2"/>
        <line x1="25" y1="10" x2="25" y2="18" stroke="currentColor" strokeWidth="2"/>
    </svg>
)

export default featureTech
