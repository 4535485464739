import React, { useContext, useState, useEffect } from 'react';
import Button from '../../atoms/Button/Button';
import Container from '../../atoms/Container/Container';
import GraphQLContext from '../../../context/GraphQLProvider';
import Icon from '../../atoms/Icon/Icon';
import ReviewForm from './ReviewForm';
import ReviewContext from '../../../context/ReviewProvider';
// import ReviewContext from '../../../context/ReviewProvider';
// import Review from "../../molecules/Review/Review"
import ReviewsIoWidget from '../../atoms/ReviewsIoWidget/ReviewsIoWidget';

import * as styles from './Reviews.module.css';

const Reviews = ({ product, reviewsList, sku, type, multiple }) => {
  const graphQLContext = useContext(GraphQLContext);
  const ctxReview = useContext(ReviewContext);
  const showReviewForm = ctxReview && ctxReview.showReviewForm;
  // console.log(ReviewContext);
  // console.log(ctxReview);
  // console.log(showReviewForm);
  // console.log(GraphQLContext);
  // console.log(graphQLContext);

  const [queryRunning, setQueryRunning] = useState(false);
  const [queryComplete, setQueryComplete] = useState(false);
  // const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (!queryRunning && !queryComplete) {
      setQueryRunning(true);
    }
  }, [
    product,
    graphQLContext,
    queryRunning,
    setQueryRunning,
    queryComplete,
    setQueryComplete
  ]);

  const reloadReviews = () => {
    setQueryComplete(false);
    setQueryRunning(false);
  };

  if (type === 'carousel') {
    return (
      <>
        {reviewsList && (
          <div className={styles.reviewContainer} id="reviews">
            <Container size="large">
              <Container size="medium">
              {/* <div> */}
                <div className={styles.reviewHeader}>
                  <h3 className={styles.reviewTitle}>Reviews</h3>
                  <div>
                    <Button
                      type="a"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        showReviewForm();
                      }}
                    >
                      <Icon symbol="addReview" />
                      Add your review
                    </Button>
                    <ReviewForm product={product} onReload={reloadReviews} />
                  </div>
                </div>
              </Container>

                <ReviewsIoWidget sku={sku} type={type} />

                {/* {reviewsList.totalRating > 0 && (<div>
                    {reviewsList.reviews.map((item, index) => (
                        <div data-show-more={showMore} key={index}>
                            <Review name={item.reviewer.first_name + ' ' + item.reviewer.last_name} title={item.name} content={item.review} rate={item.rating} />
                        </div>
                    ))}
                </div>)}

                {reviewsList.totalRating < 1 && (
                    <div className={styles.reviewBlockBlury}>
                        <Review 
                            name="Katy, Sydney" 
                            title="Fantastic products!" 
                            content="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit. Sed elit est, volutpat sit amet placerat ut, sodales eu erat."
                            rate={4.5} />
                        <Button type="a" className="small" level="primary" href="#" flat onClick={() => { showReviewForm() }}>Add your review</Button>
                    </div>
                )} */}
              {/* </div> */}
              {/* {reviewsList.totalRating > 1 && !showMore && (
                <div className={styles.reviewMore}>
                  <Button
                    type="a"
                    level="primary"
                    href="#"
                    flat
                    onClick={(e) => {
                      e.preventDefault();
                      setShowMore(true);
                    }}
                  >
                    See more
                  </Button>
                </div>
              )} */}
            </Container>
          </div>
        )}
      </>
    );
  } else if (type === 'rating') {
    return (
    <div className={styles.widgetContainer}>
      <ReviewsIoWidget sku={sku} type= {type} />
    </div>);
  } else if (type === 'richSnippets') {
    return <ReviewsIoWidget sku={sku} type={type} multiple={multiple} />
  }
};

export default Reviews;
