import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { bcApi } from '../../../helpers/bigcommerce';
import Button from '../../atoms/Button/Button';
import Dialog from '../../atoms/Dialog/Dialog';
import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';
import ProductImages from '../../molecules/ProductImages/ProductImages';
import ProductOptions from '../../molecules/ProductOptions/ProductOptions';
import * as styles from './PdpGwpDialog.module.css';
import { graphql, useStaticQuery } from 'gatsby';

const fetchProducts = async (sku) => {
  return bcApi(`catalog/products?sku:in=${sku}&include=variants,images,custom_fields,options,modifiers,bulk_pricing_rules`, 'GET')
    .then(({ response, status }) => {
      if (!response || status !== 200) {
          return [];
      }
      return response;
      }
  );
};

const PdpGwpDialog = ({
  gwp,
  gwpMessage,
  openModal,
  triggerModal
}) => {

  const productPrices = useStaticQuery(graphql`
    query {
      allBigCommerceProductPricing {
        edges {
          node {
            product_id
            price {
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
            retail_price {
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
            sale_price {
              is_on_sale
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
            calculated_price {
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
          }
        }
      }
    }
  `);

  const [quantity, setQuantity] = useState(1);
  const [inStock, setInStock] = useState(true);
  const [hasOptions, setHasOptions] = useState(false);
  const [optionsData, setOptionsData] = useState(false);
  const [calculatedPrice, setCalculatedPrice] = useState(false);
  const [product, setProduct] = useState(null);
  
  const getProductData = async (sku) => {
    const products = await fetchProducts(sku);

    if (products && products.data.length > 0) {
      const product = products.data[0];

      product['entityId'] = product.id;

      if (product.variants) {
        product.variants.map((variant) => {
          variant['entityId'] = variant.id
  
          variant.option_values = variant.option_values.map((option) => {
            setHasOptions(true);
            option['option_value_id'] = option.id
            return option;
          })
  
          return variant;
        })
      }

      if (product.modifiers) {
        product.modifiers.map((modifier) => {
          modifier.option_values = modifier.option_values.map((option) => {
            setHasOptions(true);
            option['option_value_id'] = option.id
            return option;
          })
  
          return modifier;
        })
      }

      if (product &&
        typeof product.inventory_tracking !== 'undefined' &&
        typeof product.inventory_level !== 'undefined' &&
        typeof product.inventory_warning_level !== 'undefined' &&
        product.inventory_tracking === 'product' &&
        product.inventory_level < 1
      ) {
        console.log('No inventory found', product);
        setQuantity(0);
        setInStock(false);
      }

      if (productPrices && productPrices.allBigCommerceProductPricing.edges.length > 0) {
        productPrices.allBigCommerceProductPricing.edges.map((acc, item) => {
          if (acc.node.product_id === product.entityId) {
            product['detailedPrices'] = acc.node;
          }
          return acc;
        }, {})
      }
      
      // console.log('Product', product);
      setProduct(product);
    }
  };

  useEffect(() => {

    if (gwp && gwp.value) {
      getProductData(gwp.value);
    }

  // eslint-disable-next-line
  }, [gwp]);

  useEffect(() => {
    if (optionsData.modifierOptions) {
        let extraValue = 0;
        Object.values(optionsData.modifierOptions).map(mOptions => {
            if (Array.isArray(mOptions)) {
                mOptions.map(mOption => {
                    if (mOption.price) {
                        extraValue += parseInt(mOption.price, 10);
                    }
                    return true;
                });
            } else {
                if (mOptions.price) {
                    extraValue += parseInt(mOptions.price, 10);
                }
            }
            
            return true;
        });

        const productPrice = typeof get(product, 'detailedPrices.calculated_price') !== 'object' 
        ? get(product, 'detailedPrices.calculated_price') 
        : (get(product, 'detailedPrices.calculated_price.tax_inclusive') || product.price);


        if (extraValue > 0) {
            setCalculatedPrice(extraValue + productPrice);
        } else {
            setCalculatedPrice(false);
        }
    }

    // setOptionsData(optionsData);
  }, [optionsData, product])

  return (
    <Dialog open={openModal} size="md" hideBtnCancel hideBtnOk onCancel={() => {triggerModal(false)}} title={product && product.name}>
      {product && <div className={styles.root}>
        <div className={styles.images}>
          <ProductImages images={product.images} identifier="product-images" type={'slider'} />
        </div>
        <div className={styles.details}>
          {/* <h6>{product.name}</h6> */}
          {('custom_fields' in product && product.custom_fields.find(cf => cf.name === 'blurb')) && (
            <div className={styles.productDescriptionShow}>
              {product.custom_fields.find(cf => cf.name === 'blurb').value}
            </div>
          )}
          <div className={`subtitle small ${styles.gwpMessage}`}>{gwpMessage || (hasOptions ? 'Please select your size' : 'We also recommend this item to your purchases, click button below to add in your cart.')}</div>
          {product && product.variants && product.modifiers && (
            <div className={styles.options}>
              <ProductOptions 
                product={product} 
                sku={product.sku}
                setOptionsData={setOptionsData}
                scrollToProductInformation={() => {}} />
            </div>
          )}

          <div className={styles.addToCart}>
            {inStock?.available === 'po-stock' && (
              <span className={styles.preorderDetail}>
                {inStock?.preorder_message.replace('%%DATE%%', inStock?.preorder_release_date)}
              </span>
            )}

            {!optionsData && (
              <Button fullWidth={true} disabled={true} level="primary" type="span">Checking availability...</Button>
            )}

            {optionsData && (
              <>
                {((optionsData.requiredInventories && Object.values(optionsData.requiredInventories).filter(oos => !oos).length > 0) || !inStock) ? (
                  <Button fullWidth={true} disabled={true} level="primary" type="span">Out of stock</Button>
                ) : (
                  <AddToCartButton
                    productId={
                        product.variants && Array.isArray(product.variants)
                        ? product.variants[0].product_id
                        : product.entityId
                    }
                    variantId={
                        product.variants && Array.isArray(product.variants) ? product.variants[0].entityId : null
                    }
                    optionSelections={[
                        ...(
                            optionsData && 'requiredInventories' in optionsData ?
                                Object.keys(optionsData.requiredInventories).map(optionId => ({option_id: parseInt(optionId, 10), option_value: optionsData.requiredInventories[optionId].option_value_id})) :
                                []
                        ),
                        ...(
                            optionsData && 'variantOptions' in optionsData ?
                                Object.keys(optionsData.variantOptions).map(optionId => ({option_id: parseInt(optionId, 10), option_value: optionsData.variantOptions[optionId].option_value_id})) :
                                []
                        )
                    ]}
                    extraProducts={[
                      ...(
                        optionsData && 'modifierOptions' in optionsData ?
                            Object.values(optionsData.modifierOptions).filter(option => {
                                if (Array.isArray(option)) {
                                    let allValid = true;
                                    option.map(o => {
                                        if (!('sku' in o)) allValid = false;
                                        return true;
                                    });
                                    return allValid;
                                } else {
                                    return option.sku || false
                                }
                            }) :
                            []
                      )
                    ]}
                    {...(inStock?.available === 'no-stock' && {disabled: true})}
                    quantity={quantity}
                    additionalAction={() => {
                      triggerModal(false)
                    }}
                    priceDisplay={true}
                    priceObject={{
                        price: product.price,
                        calculated_price: product.calculated_price,
                        sale_price: product.sale_price,
                        styles: styles,
                        override_price: calculatedPrice
                    }}>
                      {(inStock?.available === "no-stock") ? 'Out of Stock' : (
                        (inStock?.available === "po-stock") ? 'Pre-Order' : "Add to bag"
                      )}
                  </AddToCartButton>
                )}
              </>
            )}
          </div>
        </div>
      </div>}

      {!product && (
        <h6>Product not found!</h6>
      )}
    </Dialog>
  );
};

export default PdpGwpDialog;
