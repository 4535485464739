/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext, useEffect, useState } from "react"
// import Helmet from "react-helmet"
import Seo from "../Seo/Seo"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import BindContext from '../../../context/BindProvider'

import { setStorage, getCookie, getStorage } from "../../../helpers/general";

import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import Button from "../../atoms/Button/Button"
import ContentfulTranslationText from "../../atoms/ContentfulTranslationText/ContentfulTranslationText"

import * as styles from './Layout.module.css'
// CSS not modular here to provide global styles
import "./Layout.css"
import "./Globals.css"

const Layout = ({ bodyClass, headerStyle, noPadding, children, scripts, seo = {}, bare, mainClass = '' }) => {
  const bind = useContext(BindContext);
  const [displayLocationSuggest, setSuggestion] = useState(false);
  const initBind = bind && bind.init
  // const [messages, setMessages] = useState([]);
  // const [fetchedMessages, setFetchedMessages] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (typeof window !== 'undefined' && ['/login', '/login/', '/signup', '/signup/', '/forgotpassword', '/forgotpassword/'].indexOf(window.location.pathname) === -1) {
    setStorage('lastPage', window.location.href, true);
  }

  const locales = typeof window !== "undefined" ? [
    {lang: 'en-eu', path: `/en-eu${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'en-gb', path: `/en-gb${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'en-us', path: `/en-us${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'en-ca', path: `/en-ca${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'en-au', path: `/en-au${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'en-nz', path: `/en-au${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'de-de', path: `/de-de${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'it-it', path: `/it-it${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
    {lang: 'x-default', path: `${window.location.pathname.replace(process.env.GATSBY_ENVIRONMENT_PATH, '')}`},
  ] : [{lang: 'x-default', path: `/`}];

  const suggestLocation = getCookie('fc_suggest_location');
  const [dismissedLocation, setDismissed] = useState(getStorage('fc_suggest_dismissed'));

  useEffect(() => {
    if (suggestLocation && !dismissedLocation) {
      const geoData = suggestLocation.split('|');
      if (process.env.GATSBY_ENVIRONMENT_PATH.indexOf(geoData[1]) === -1) {
        setSuggestion(geoData);
      }
    }
  }, [suggestLocation, dismissedLocation]);

  const dismissSuggestion = () => {
    setSuggestion(false);
    setStorage('fc_suggest_dismissed', true);
    setDismissed(true);
  }

  // useEffect(() => {
  //   if (messages.length === 0 && !fetchedMessages) {
  //     setFetchedMessages(true);
  //     const fetchedMessages = data.allBuilderModels.globalInfoBannerContent.map((message) => {
  //       return {
  //         text: message.data.text.replace(/\*(.*?)\*/g, '<strong>$1</strong>'),
  //         link: message.data.link,
  //       }
  //     });
  //     setMessages(fetchedMessages);
  //   }
  //   initBind()
  // }, [initBind, data, messages, setMessages, fetchedMessages, setFetchedMessages])

  const messages = [
    {text: "This is a hardcoded example message", link: "/shop/shop-all"}
  ]

  useEffect(() => {
    initBind()

    if (window) { // Keeps on top when page reload
      window.scrollTo(0, 0)
    }
  }, [initBind])

  useEffect(() => {
    if (['/it-it'].indexOf(process.env.GATSBY_ENVIRONMENT_PATH) > -1) {
      const intervalZe = setInterval(() => {
        if (typeof window !== 'undefined' && typeof window.zE === 'function') {
          clearInterval(intervalZe);
          window.zE('webWidget', 'setLocale', 'it');
        }
      }, 200)
    }
  }, []);

  return (
    <>
      <Seo {...seo}>
        {scripts}
        
        {['/en-eu', '/en-gb'].indexOf(process.env.GATSBY_ENVIRONMENT_PATH) > -1 && (
          <script src="https://www.privacylab.it/elmo.php?code=21156455668&lang=en"></script>
        )}
        {['/it-it'].indexOf(process.env.GATSBY_ENVIRONMENT_PATH) > -1 && (
          <script src="https://www.privacylab.it/elmo.php?code=21156455668"></script>
        )}

        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=633d5f92-f460-43a8-a3d3-8a71e8bcdce9"></script>
        {/* {['/it-it'].indexOf(process.env.GATSBY_ENVIRONMENT_PATH) > -1 && (
          <script type="text/javascript">zE('webWidget', 'setLocale', 'it');</script>
        )} */}
        
        {locales.map((locale, lKey) => (
          <link key={lKey} rel="alternate" href={locale.path} hrefLang={locale.lang} />
        ))}
      </Seo>
      {!bare && (<Header siteTitle={data.site.siteMetadata?.title || `Title`} messages={messages} headerStyle={headerStyle} bodyClass={bodyClass}/>)}
      <main className={`${!noPadding ? styles.withPadding : ''} ${mainClass}`}>{children}</main>
      {!bare && (<Footer />)}
      {displayLocationSuggest && (
        <div className="geoSuggest">
          <div>
            <ContentfulTranslationText keyName="geoMessage" dynamicData={{"DETECTLOCATION": decodeURI(displayLocationSuggest[0])}}>
              We have detected that you are in {decodeURI(displayLocationSuggest[0])}.<br />
              There is an optimised version of the site for your location.
            </ContentfulTranslationText>
            <div className="btnGroup">
              <Button level="primary" href={`${process.env.GATSBY_SITE_PATH}${locales.find(locale => locale.lang === displayLocationSuggest[1])?.path}`}>
                <ContentfulTranslationText keyName="geoRedirectButton">
                  Take me there
                </ContentfulTranslationText>
              </Button>
              <Button level="secondary" type="button" onClick={() => dismissSuggestion()}>
                <ContentfulTranslationText keyName="geoStayButton">
                  Stay here
                </ContentfulTranslationText>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
