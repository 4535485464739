import React, { useContext, useEffect, useState } from 'react';
import CartContext from '../../../context/CartProvider';
import { bcApi } from '../../../helpers/bigcommerce';

import Button from '../../atoms/Button/Button';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import Price from '../Price/Price';

const AddToCartButton = ({
  children,
  productId,
  variantId,
  optionSelections,
  extraProducts,
  additionalAction,
  className,
  fullWidth,
  quantity,
  disabled = false,
  priceDisplay = false,
  priceObject = null,
  taxCalc
}) => {
  const value = useContext(CartContext);
  const addToCart = value && value.addToCart;
  const addAllToCart = value && value.addAllToCart;
  const addingToCartState = value && value.state.addingToCart;

  const [addedToCart, setAddedToCart] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [additionalPurchase, setAdditionalPurchase] = useState(false);
  const [priceDisplayData, setPriceDisplayData] = useState(priceObject);

  useEffect(() => {
    setPriceDisplayData(priceObject);
  }, [priceObject]);

  useEffect(() => {
    setAddingToCart(addingToCartState);
  }, [addingToCartState]);

  if (!productId) return null;

  const clickEvent = async ele => {
    ele.preventDefault();
    ele.stopPropagation();
    setAddingToCart(productId);
    if (extraProducts && extraProducts.length > 0) {
      const items = [
        {
          product_id: productId,
        }
      ];
      if (optionSelections) {
        items[0].option_selections = optionSelections;
      } else {
        items[0].variant_id = variantId;
      }

      const skus = extraProducts.flatMap(options => {
        if (Array.isArray(options)) {
          return options.filter(option => option.sku || false).map(option => option.sku);
        } else if ('sku' in options) {
          return [options.sku];
        } else {
          return [null]
        }
      });

      const extras = await bcApi(`catalog/variants?sku:in=${skus.join(',')}&include_fields=inventory_level,product_id`);
      const prodIds = [...new Set(extras.response.data.map(variant => variant.product_id))];
      const extrasParents = await bcApi(`catalog/products?id:in=${prodIds.join(',')}&include_fields=inventory_tracking`);
      extras.response.data.map(variant => {
        const tracking = extrasParents.response.data.find(parent => parent.id === variant.product_id);
        if (variant.inventory_level > 0 || tracking.inventory_tracking === "none") {
          items.push({
            product_id: variant.product_id,
            variant_id: variant.id
          });
        }
        return true;
      });

      await addAllToCart(items);
    } else if (quantity && parseInt(quantity, 10) > 0) {
      await addToCart(productId, variantId, optionSelections, null, quantity);
    } else {
      await addToCart(productId, variantId, optionSelections);
    }
    additionalAction && additionalAction();

    setAddingToCart(false);
    setAddedToCart(true);
    setAdditionalPurchase(true);
    setTimeout(() => {
      setAddedToCart(false);
    }, 3000);
  }

  const priceShow = () => {
    return (
      <>
        &nbsp;-&nbsp;
        <Price
          entityId={productId} 
          price={priceDisplayData.price} 
          calculated_price={priceDisplayData.calculated_price} 
          sale_price={priceDisplayData.sale_price}
          styles={priceDisplayData.styles}
          override_price={priceDisplayData.override_price}
          taxCalc={taxCalc}
      />
    </>
    )
  }

  if (className) {
    return (
      <span
        role="presentation"
        className={className}
        onClick={e => clickEvent(e)}
      >
        {children}
        {priceDisplay && priceObject && priceShow()}
      </span>
    );
  } else {
    return (
      <div className="bc-form bc-addtocart-form">
        <div className="bc-product-form__product-message"></div>
        <Button
          level="primary"
          type="buttonSubmit"
          disabled={disabled || addingToCart === productId || addedToCart}
          onClick={e => clickEvent(e)}
          size={fullWidth && 'fullWidth'}
        >
          {addedToCart ? (
            <><ContentfulTranslationText keyName="addedToBag">Added to bag</ContentfulTranslationText>!</>
          ) : (
            <>
              {addingToCart === productId ? 
                (<><ContentfulTranslationText keyName="addingToBag">Adding to bag</ContentfulTranslationText></>) : 
                (additionalPurchase ? (<><ContentfulTranslationText keyName="addAnother">Add another</ContentfulTranslationText></>) : children)
              }
              {priceDisplay && priceObject && priceShow()}
            </>
          )}
        </Button>
      </div>
    );
  }
};

export default AddToCartButton;
