import React, { useEffect, useState } from 'react';
import { has, get } from 'lodash';
import { scroller } from 'react-scroll';
import InnerImageZoom from 'react-inner-image-zoom';
import Slider from '../../atoms/Slider/Slider';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import * as styles from './ProductImages.module.css'

const ProductImages = props => {
  const sliderRef = React.createRef();
  const { images, identifier, type, currentOptions, goToId } = props;
  const [sortedImages, setSortedImages] = useState([]);
  const [initCI360, setInitCI360] = useState(false);
  const [currentLocalOptions, setCurrentLocalOptions] = useState(false);

  // console.log("In productImages", currentOptions);

  const customPaging = () => {
    return(<div className={styles.mobilePagination}></div>)
  }

    useEffect(() => {
      const options = (currentOptions && 'imageChangeData' in currentOptions) ? Object.values(currentOptions.imageChangeData) : ['Gloss', 'Clear'];
      if (images && ((sortedImages.length === 0 && images.length > 0) || JSON.stringify(options) !== JSON.stringify(currentLocalOptions))) {
          const imagesObjRaw = [...images];
          let imagesObj = [];
          if (type === '360') {
            imagesObjRaw.sort((a, b) => {
              const desc_a = parseInt(a.description.split(' ')[0], 10);
              const desc_b = parseInt(b.description.split(' ')[0], 10);
              if (desc_a === desc_b) {
                return 0;
              } else {
                return (desc_a < desc_b) ? -1 : 1;
              }
            });

            imagesObj = [...imagesObjRaw.filter(img => {
              const desc = img.description.split(' ');
              desc.splice(0, 1);
              let match = true;
              desc.map(option => {
                if (options.indexOf(option) === -1) {
                  match = false;
                }

                return true;
              });
              return match;
            })];
          } else {
            imagesObjRaw.sort(a => a.is_thumbnail ? -1 : 1);
            imagesObj = [...imagesObjRaw];
          }
          
          setSortedImages(imagesObj);
          setCurrentLocalOptions(options);
      }

      if (typeof window !== 'undefined' && get(window, 'CI360.init', false) && type === '360' && !initCI360) {
        const product360 = document.getElementById('product360');
        if (product360 && !initCI360) {
          setInitCI360(true);
          window.CI360.init();

          var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
              if (mutation.type === "attributes" && mutation.attributeName === "data-image-list-x") {
                window.CI360.update('product360');
              }
            });
          });
          
          observer.observe(product360, {
            attributes: true //configure it to listen to attribute changes
          });
        }
      }
    }, [images, sortedImages, initCI360, type, currentOptions, currentLocalOptions]);

    useEffect(() => {
      if (goToId && goToId !== null) {
        let goToIndex = null;
        sortedImages.map((image, index) => {
          if (image.from_variant && image.id === goToId) {
            goToIndex = index;
          }
          return image;
        })

        if (goToIndex) {
          sliderRef.current.slickGoTo(goToIndex)
        }
      }
    }, [goToId, sortedImages, sliderRef])

    const onClickThumb = evt => {
        evt.preventDefault();
        if (window.innerWidth > 767) {
          scroller.scrollTo(
            evt.currentTarget.getAttribute('href').replace('#', ''),
            {
              duration: 800,
              delay: 0,
              offset: -180,
              smooth: 'easeInOutQuart'
            }
          );
        } else {
          const _parent = document.querySelector(
            `.${evt.currentTarget.getAttribute('data-image-class')}`
          ).parentElement;
          document
            .querySelectorAll(
              `.${evt.currentTarget.getAttribute('data-image-class')}`
            )
            .forEach((_el, _index) => {
              _el.classList.remove(
                evt.currentTarget.getAttribute('data-image-class')
              );
            });
    
          _parent
            // .querySelectorAll('img')
            .querySelectorAll('.productImageContainer')
            [
              parseInt(evt.currentTarget.getAttribute('data-key'), 10)
            ].classList.add(evt.currentTarget.getAttribute('data-image-class'));
        }
    
        return false;
    };

    return (
        <>
            {(type === 'thumbnails' && sortedImages && sortedImages.length > 1) && sortedImages.map((img, index) => (
                <a
                    href={`#${identifier}-${index + 1}`}
                    onClick={onClickThumb}
                    key={index}
                    data-key={index}
                    data-image-class={styles.productImageActive}
                    className={styles.productImageThumb}
                >
                    <img
                        loading="lazy"
                        src={
                            has(img, 'node') ? img.node.url : img.url_thumbnail
                        }
                        alt={img.description}
                    />
                </a>
            ))}

            {(type === 'gallery' && sortedImages) && sortedImages.map((img, index) => (
                // <img
                //     loading="lazy"
                //     src={
                //         has(img, 'node')
                //           ? img.node.url
                //           : has(img, 'url_zoom')
                //           ? img.url_zoom
                //           : img.url_standard
                //       }
                //     alt={img.description}
                //     id={`${identifier}-${index + 1}`}
                //     key={index}
                //     className={index === 0 ? styles.productImageActive : ''}
                // />
                <div id={`${identifier}-${index + 1}`} key={index} className={`productImageContainer ${index === 0 ? styles.productImageActive : ''}`}>
                  <InnerImageZoom 
                    src={
                      has(img, 'node')
                        ? img.node.url
                        : has(img, 'url_zoom')
                        ? img.url_zoom
                        : img.url_standard
                    }
                    zoomSrc={
                      has(img, 'node')
                        ? img.node.url
                        : has(img, 'url_zoom')
                        ? img.url_zoom
                        : img.url_standard
                    }
                    alt={img.description}
                  />
                </div>
            ))}
            {(type === 'gallery' && sortedImages && sortedImages.length === 0) && (
                <div>
                    <img
                      loading="lazy"
                      src="https://via.placeholder.com/1000x1000?text=No+Images+Available"
                      alt="No product images"
                    />
                </div>
            )}
            {(type === 'slider' && sortedImages) && (
              <div className={`${styles.imageContainer} ${styles.imageSlider}`}>
                <Slider 
                  sliderRef={sliderRef}
                  dotsClass={styles.customDots} 
                  customPaging={customPaging} 
                  customArrows={true} 
                  autoplay={false}>
                  {sortedImages.map((img, index) => (
                    <div key={index} className={styles.productSliderImageWrapp}>
                      <img
                          data-slide-id={img.from_variant ? img.id : index}
                          data-index-id={index}
                          loading="lazy"
                          src={img.url_zoom} // Standard quality was not good
                          alt={img.description}
                          key={index}
                          className={styles.productSliderImage}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
              
            )}
            {(type === 'slider' && sortedImages && sortedImages.length === 0) && (
                <div className={`${styles.imageContainer}`}>
                    <img
                        loading="lazy"
                        src="https://via.placeholder.com/1000x1000?text=No+Images+Available"
                        alt="No product images"
                    />
                </div>
            )}

            {(type === '360' && sortedImages && sortedImages.length > 0) && (
              <div className={`${styles.imageContainer} ${styles.imageRotator}`}>
                <div
                  id="product360"
                  className={`cloudimage-360 ${styles.product360}`}
                  data-image-list-x={`[${sortedImages.map(image => `"${image.url_zoom}"`)}]`}
                ></div>
              </div>
            )}

            {!sortedImages && (
              <></>
            )}
        </>
    )
}

export default ProductImages;