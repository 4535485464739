import React from 'react'

const LogoRed = props => (
<svg width="99" height="77" viewBox="0 0 99 77" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M37.0129 34.174H25.0695L18.4124 25.8266H40.7116L47.304 31.7576V60.54L35.0947 44.7412H40.8188V38.0141L37.0129 34.174Z" fill="#FF3300"/>
<path fillRule="evenodd" clipRule="evenodd" d="M61.2415 34.174H73.1849L79.8443 25.8266H57.5384L50.9572 31.7576V60.54L63.1552 44.7412H57.4326V38.0141L61.2415 34.174Z" fill="#FF3300"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.0666 22.6587H87.1778L49.0905 68.5879L11.0666 22.6587ZM0.455322 17.5808L49.0875 76.332L97.8032 17.5808H0.455322Z" fill="#FF3300"/>
<path d="M27.1767 9.66233C27.1767 10.3819 26.7939 10.741 26.0292 10.741H18.8068C18.0413 10.741 17.6623 10.3819 17.6623 9.66233V4.94656C17.6623 4.2324 18.0413 3.87337 18.8068 3.87337H26.0292C26.7939 3.87337 27.1767 4.2324 27.1767 4.94656V9.66233ZM28.8693 2.02827C28.0919 1.25558 27.1447 0.866108 26.0292 0.866108H19.4204H18.8068H0V13.7451H2.86689V8.80924H10.7624L12.4788 5.80354H2.86689V3.87337H14.9205C14.8379 4.20976 14.7924 4.56567 14.7924 4.94343V9.67169C14.7924 10.8378 15.1826 11.8071 15.96 12.583C16.7412 13.3564 17.6899 13.7451 18.8068 13.7451H26.0292C27.1447 13.7451 28.0919 13.3564 28.8693 12.583C29.6534 11.8071 30.0436 10.8378 30.0436 9.67169V4.94343C30.0436 3.77503 29.6534 2.80409 28.8693 2.02827Z" fill="#FF3300"/>
<path d="M46.4139 13.7461H42.4896L36.3477 6.53038H43.7346C44.2596 6.53038 44.5232 6.08706 44.5232 5.20275C44.5232 4.31611 44.2596 3.87435 43.7346 3.87435H34.8442V13.7461H31.9706V0.867085H43.9007C45.0005 0.867085 45.871 1.32133 46.5055 2.22047C47.0989 3.03999 47.3923 4.03279 47.3923 5.20275C47.3923 6.37038 47.0989 7.3663 46.5055 8.18348C45.871 9.08496 45.0005 9.5353 43.9007 9.5353H42.8023L46.4139 13.7461Z" fill="#FF3300"/>
<path d="M62.8544 13.7461H53.3355C52.223 13.7461 51.2729 13.3574 50.4917 12.5839C49.7113 11.8081 49.3196 10.838 49.3196 9.67189V4.94441C49.3196 3.776 49.7113 2.80428 50.4917 2.02847C51.2729 1.25577 52.223 0.867085 53.3355 0.867085H62.8544V3.87435H53.3355C52.573 3.87435 52.191 4.23338 52.191 4.94753V9.6633C52.191 10.3821 52.573 10.7412 53.3355 10.7412H62.8544V13.7461Z" fill="#FF3300"/>
<path d="M67.6465 13.7461H64.7827V0.867081H67.6465V13.7461Z" fill="#FF3300"/>
<path d="M83.2043 3.87337H77.6574V13.7451H74.7876V3.87337H69.2422V0.866108H83.2043V3.87337Z" fill="#FF3300"/>
<path d="M98.2585 3.87337H88.001V5.80354H97.5884V8.80924H88.001V10.741H98.234V13.7451H85.1311V0.866108H98.2585V3.87337Z" fill="#FF3300"/>
</svg>
)

export default LogoRed