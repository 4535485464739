import React, { useMemo, useState, useContext } from 'react';
import ContentfulContext from '../../../context/ContentfulProvider';
import { Link } from 'gatsby';
import Container from '../../atoms/Container/Container';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import { handleLink } from '../../../helpers/general';

import * as styles from './SiteMenu.module.css'


const SiteMenu = (props) => {
    const { setNavOpen } = props;
    // const [activeNav, setActiveNav] = useState(0);
    const [activeMenu, setActiveMenu] = useState([]);

    const contentful = useContext(ContentfulContext);
    const menuData = useMemo(() => (contentful && contentful.headerMenu.map(item => ({
      "menuLabel": item.title,
      "menuLink": handleLink((item.url || item.content.slug).startsWith('http') ? (item.url || item.content.slug) : `/${(item.url || item.content.slug).replace(/^\/|\/$/g, '')}/`),
      "target": item.linkTarget === 'New Tab',
      "location": item.linkLocation === 'External',
      "submenu": null
    }))) || [], [contentful]);
    
    const mobileClick = (e, subNavId, back) => {
        if (back) {
          e.stopPropagation();
          const currentSubNav = e.currentTarget.closest('[data-subnav]');
          currentSubNav.classList.toggle(styles.isActive);
        } else {
          const subNav = e.currentTarget.querySelectorAll(
            `[data-subnav="${subNavId}"]`
          );
          if (subNav.length > 0) {
            e.stopPropagation();
            subNav[0].classList.toggle(styles.isActive);
          }
        }
      };
    
      const linkTo = (e, link) => {
        e.preventDefault();
        /* Force page to reload to link in the event a link with only filter changes is clicked */
        if (typeof window !== "undefined") {
          if (window.location.pathname === link) {
            setNavOpen(false);
          } else {
            window.location = link;
          }
        }
      }
    
      // const toggleActiveNav = (e, itemIndex, level, childrenItems) => {
      //   if (level === 1 && childrenItems) {
      //     setNavOpen(true);
      //   }
      //   if (level === 2) {
      //     setActiveNav(itemIndex);
      //   }
      // };
    
      // const closeActiveNav = (level, childrenItems) => {
      //   if (level === 1 && childrenItems) {
      //     setNavOpen(false);
      //   }
      // };
    
      // const isActiveNav = (itemIndex, level) => {
      //   if (level === 2) {
      //     if (activeNav === itemIndex) return true;
      //   }
    
      //   return false;
      // };
    
      const menuLevel = (menuList, parentLabel, className, level, parentIndex) => {
        const items = menuList?.map((item, itemIndex) => {
          if (item.menuLabel === 'ImageGridStyle' && level === 3) {
            if (item.submenu && item.submenu.length > 0) {
              return (
                <li key={itemIndex} className={styles.imageGrid}>
                  {item.submenu?.map((image, imageIndex) => {
                    return (
                      <div key={imageIndex}>
                        <a href={image.menuLink} onClick={(e) => linkTo(e, image.menuLink)}>
                          <img
                            loading="lazy"
                            src={image.image}
                            alt={image.menuLabel}
                          />
                          <span>{image.menuLabel}</span>
                        </a>
                      </div>
                    );
                  })}
                </li>
              );
            } else {
              return null;
            }
          } else {
            let childrenItems = null;
            if (item.submenu && item.submenu.length > 0) {
              childrenItems = menuLevel(
                item.submenu,
                item.menuLabel,
                styles.subnav,
                level + 1,
                itemIndex
              );
            }
    
            return (
              <li
                key={itemIndex}
                // className={`${isActiveNav(itemIndex, level) ? styles.active : ''} ${
                //   childrenItems ? styles.withChildren : ''
                // }`}
                // onMouseEnter={e =>
                //   toggleActiveNav(e, itemIndex, level, childrenItems)
                // }
                // onMouseLeave={() => closeActiveNav(level, childrenItems)}
                onClick={e => mobileClick(e, `${itemIndex}-${level + 1}`)}
                role="presentation"
              >
                {item.menuLink && item.menuLink !== '' && !item.target && !item.location && (
                  <Link to={item.menuLink}>{item.menuLabel}</Link>
                  // <a href={item.menuLink} onClick={(e) => linkTo(e, item.menuLink)}>{item.menuLabel}</a>
                )}
                {item.menuLink && item.menuLink !== '' && (item.target || item.location) && (
                  <a href={item.menuLink} title={item.menuLabel} target={item.target ? '_blank' : ''} rel="noreferrer noopener" onClick={(e) => linkTo(e, item.menuLink)}>
                    {item.menuLabel}
                  </a>
                )}
                {(!item.menuLink || item.menuLink === '') && (
                  <span className={`${styles.fauxLink} ${item.menuLabel === parentLabel ? styles.duplicate : ''}`}>{item.menuLabel}</span>
                )}
                {childrenItems && childrenItems}
              </li>
            );
          }
        });
    
        return (
          <>
            {level === 2 && (
              <div
                className={`${className} ${styles[`level${level}`]}`}
                data-subnav={`${parentIndex}-${level}`}
              >
                <span
                  role="presentation"
                  className={styles.backLink}
                  onClick={e => mobileClick(e, `${parentIndex}-${level}`, true)}
                >
                  <ContentfulTranslationText keyName="back">Back</ContentfulTranslationText>
                </span>
                {parentLabel && (
                  <span className={styles.parentLabel}>{parentLabel}</span>
                )}
                {/* <div className={`${styles.shadow} ${items.length <= 1 ? styles.conceal : ''}`}> */}
                <div className={styles.shadow}>
                  <Container size="large">
                    <ul>{items}</ul>
                  </Container>
                </div>
              </div>
            )}
            {level !== 2 && (
              <div
                className={`${className} ${styles[`level${level}`]}`}
                data-subnav={`${parentIndex}-${level}`}
              >
                <span
                  role="presentation"
                  className={styles.backLink}
                  onClick={e => mobileClick(e, `${parentIndex}-${level}`, true)}
                >
                  Back
                </span>
                {parentLabel && (
                  <span className={styles.parentLabel}>{parentLabel}</span>
                )}
                <ul>{items}</ul>
              </div>
            )}
          </>
        );
      }
    
      useMemo(() => {
        if (activeMenu.length === 0) {
          setActiveMenu(menuData)
        }
      }, [activeMenu, menuData]);
    
      return menuLevel(activeMenu, null, styles.root, 1, 0);
    };
    
    export default SiteMenu;