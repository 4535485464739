import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import Loader from '../../atoms/Loader/Loader';
import * as styles from './AdjustItem.module.css';
import { debounce } from 'lodash';

const AdjustItem = ({ item, updatingItem, updateCartItemQuantity }) => {
  const isUpdating = updatingItem === item.id;

  const [qty, setQty] = useState(item.quantity);
  
  const handler = useCallback(debounce((quantity) => { // eslint-disable-line react-hooks/exhaustive-deps
    if(quantity !== '' && quantity !== '0') {
      updateCartItemQuantity(item, quantity);
    }
  }, 1000), []); 

  const handleOnChange = (qty) => {
    handler(qty);
  }

  return (
    <div className={clsx(styles.root, isUpdating && 'disable-action')}>
      {/* <div
        data-button
        role="presentation"
        className={styles.AdjustButton}
        onClick={() => updateCartItemQuantity(item, 'minus')}
      >
        -
      </div> */}

      {/* <div className={styles.AdjustButton}>
        {isUpdating ? <Loader /> : item.quantity}
      </div> */}
      {isUpdating ? 
        <Loader size={'small'} /> : 
        <input 
          type={'number'} 
          value={qty} 
          onChange={(e)=> {
            setQty(e.target.value);
            handleOnChange(e.target.value);
          }}
        />
      }

      {/* <div
        data-button
        role="presentation"
        className={styles.AdjustButton}
        onClick={() => updateCartItemQuantity(item, 'plus')}
      >
        +
      </div> */}
    </div>
  );
};

export default AdjustItem;
