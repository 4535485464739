import React, { useMemo, useContext } from 'react';
import ContentfulContext from '../../../context/ContentfulProvider';
import Icon from '../../atoms/Icon/Icon'
import { handleLink } from '../../../helpers/general';
import * as styles from './NavSocialMedia.module.css'
// TODO: Look to replace this default with data from an external system, in the same structure.
// import defaultSocialItems from './NavSocialMedia-example.json'

const NavSocialMedia = ({ socialItems, label }) => {
    const contentful = useContext(ContentfulContext);
    const socialsToRender = useMemo(() => socialItems || (contentful && Object.keys(contentful.socialLinks).map(icon => {
      return {
        "icon": icon,
        "link": contentful.socialLinks[icon],
      };
    })) || [], [socialItems, contentful]);
  
    return (
      <>
        {label && <span className={styles.socialsLabel}>{label}</span>}
        {socialsToRender.map((item, itemIndex) => (
          <a
            href={handleLink(item.link)}
            target="_blank"
            rel="noreferrer"
            key={itemIndex}
            className={styles.inline}
          >
            <Icon symbol={item.icon.toLowerCase()} />
          </a>
        ))}
      </>
    );
  };
  
  export default NavSocialMedia;
  
