import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import CartContext from '../../../context/CartProvider';
import PriceContext from '../../../context/PriceProvider';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import CouponForm from './CouponForm';
import ShippingInfo from './ShippingInfo';
// import FastCheckout from '../../atoms/FastCheckout/FastCheckout';
// import GiftCertificate from './GiftCertificate';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import * as styles from './CartSummary.module.css';
import { dataLayerPush, handleLink } from '../../../helpers/general';

const CartSummary = ({ cartType, onClick, nextStepValid, cartStep, cartFunc }) => {
  const ctx = useContext(CartContext);
  const priceCtx = useContext(PriceContext);
  const nonTaxProducts = priceCtx && priceCtx.nonTaxProducts;
  const [dateAgreement, setDateAgreement] = useState(false);
  const [agreementRequired, setAgreementRequired] = useState(false);
  const cart = get(ctx, 'state.cart', {});
  const checkout = get(ctx, 'state.checkout', {});
  // const cartShippingMethod = get(ctx, 'state.shippingMethod', 'delivery');
  const { currency, cartAmount, numberItems } = cart;
  const subtotalType = process.env.GATSBY_TAX_DISPLAY === 'ex' ? 'subtotal_ex_tax' : 'subtotal_inc_tax';
  const checkoutAmount = checkout && subtotalType in checkout && checkout[subtotalType] > -1 ? checkout[subtotalType] : cartAmount;
  const taxTotal = checkout && 'tax_total' in checkout ? checkout.tax_total : (cartAmount / 11); // GST value not provided in cart
  const grandTotal = (checkout && 'grand_total' in checkout && checkout.grand_total > -1) ? checkout.grand_total : cartAmount;
  const [displayCheckoutAmount, setCheckoutAmount] = useState(checkoutAmount);
  const [displayTaxTotal, setTaxTotal] = useState(taxTotal); // GST value not provided in cart
  const [displayGrandTotal, setGrandTotal] = useState(grandTotal);
  const [consignmentSet, setConsignmentFlag] = useState(get(checkout, 'consignments[0].shipping_address', false));
  const [manualTotals, setManualTotals] = useState(false);
  // console.log("Cart", cart);
  // console.log("Checkout", checkout);
  // console.log("Grand Total", grandTotal);
  // console.log("Display Grand Total", displayGrandTotal);
  // console.log("checkoutAmount", checkoutAmount);
  // console.log("Cart amount", cartAmount, cartAmount / 1.1);

  const splitGrandTotalCalculation = useCallback(() => {
    const runCalc = async () => {
      let manualCheckoutAmount = 0;
      let manualTaxTotal = 0;
      let manualGrandTotal = 0;
      
      const activeLines = checkout?.cart?.line_items || cart.lineItems;
      // const freshCart = await ctx.fetchCart();
      // console.log("Fresh Cart", freshCart);
      // const activeLines = freshCart.lineItems;
      // console.log(activeLines);
      const tax = (100 + Number(process.env.GATSBY_TAX_VALUE)) / 100;
      // console.log(nonTaxProducts, tax, activeCart);
    
      activeLines.physical_items.map(item => {
        if (item.sku.indexOf('DELIV-') > -1) {
          const theDate = item.name.replace('Delivery Token - ', '');
          setAgreementRequired(theDate);
        }
        // console.log(item);
        const priceType = process.env.GATSBY_TAX_DISPLAY === 'ex' ? 'extended_list_price' : 'extended_sale_price';
        if (nonTaxProducts.indexOf(item.product_id) > -1) {
          setManualTotals(true);
        manualCheckoutAmount += Number(item[priceType]);
          manualGrandTotal += (Number(item[priceType]) - Number(item.coupons));
          // console.log((Number(item.extended_sale_price) - Number(item.coupons)), manualGrandTotal)
        } else {
          manualCheckoutAmount += (Number(item[priceType]) * tax);
          manualGrandTotal += ((Number(item[priceType]) * tax) - (Number(item.coupons) * tax));
          // console.log(((Number(item.extended_sale_price) * tax) - (Number(item.coupons) * tax)), manualGrandTotal)
          manualTaxTotal += ((Number(item[priceType]) - Number(item.coupons)) * (Number(process.env.GATSBY_TAX_VALUE) / 100));
          // console.log(manualTaxTotal);
        }
        return true;
      });

      setCheckoutAmount(manualCheckoutAmount);
      setTaxTotal(manualTaxTotal);
      setGrandTotal(manualGrandTotal);
    }

    runCalc();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, nonTaxProducts]);

  useEffect(() => {
    // console.log(grandTotal, displayGrandTotal);
    if (displayGrandTotal !== grandTotal) {
      // setGrandTotal(grandTotal);
      splitGrandTotalCalculation();
    }
  }, [grandTotal, displayGrandTotal, splitGrandTotalCalculation]);

  useMemo(() => {
    setAgreementRequired(false);
    splitGrandTotalCalculation();
  }, [splitGrandTotalCalculation]);

  useEffect(() => {
    setConsignmentFlag(get(checkout, 'consignments[0].shipping_address', false));
  }, [checkout]);

  if (!numberItems) {
    return null;
  }

  if (cartType === 'full') {
    return (
      <div className={styles.bcCartSummary}>
        <p className={styles.bcCartSummaryTitle}><ContentfulTranslationText keyName="orderSummary">Order Summary</ContentfulTranslationText></p>
        <div className={styles.bcCartSummaryItemsContainer}>
          <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
            <span className={styles.bcCartSummaryItemLabel}><ContentfulTranslationText keyName="subtotal">Subtotal</ContentfulTranslationText></span>
            <span className={styles.bcCartSummaryItemValue}>
              {/* {console.log("logic check", consignmentSet, manualTotals)} */}
              {(!consignmentSet && manualTotals) ? (
                <CurrencyFormatter currency={currency.code} amount={displayCheckoutAmount} taxCalc={false} appendZero={true} />
              ) : (
                <CurrencyFormatter currency={currency.code} amount={checkoutAmount} taxCalc={!consignmentSet} appendZero={true} />
              )}
            </span>
          </div>

          <div className={styles.bcCartSummaryItem}>
            <ShippingInfo checkout={checkout} taxCalc={!consignmentSet} />
          </div>

          <div className={clsx('flex-between', styles.bcCartSummaryItem)}>
            <span className={styles.bcCartSummaryItemLabel}><ContentfulTranslationText keyName="tax">GST</ContentfulTranslationText></span>
            <span className={styles.bcCartSummaryItemValue}>
              {consignmentSet ? (
                <CurrencyFormatter
                  appendZero={true}
                  currency={currency.code}
                  amount={taxTotal} // GST value not provided in cart
                />
              ) : (
                <CurrencyFormatter
                  appendZero={true}
                  currency={currency.code}
                  amount={manualTotals ? displayTaxTotal : (Number(checkoutAmount) * (Number(process.env.GATSBY_TAX_VALUE) / 100))}
                  taxCalc={false}
                />
              )}
            </span>
          </div>

          {/* Coupon Codes */}
          <div className={styles.bcCartSummaryItem}>
            <CouponForm />
          </div>

          {/* Gift Certificate */}
          {/* <div className={styles.bcCartSummaryItem}>
            <GiftCertificate />
          </div> */}
        </div>

        <div className={clsx('flex-between', styles.bcCartSummaryItemTotal)}>
          <span className={styles.bcCartSummaryItemTotalLabel}>
            <ContentfulTranslationText keyName="grandTotal">Grand total</ContentfulTranslationText>
          </span>
          <span className={styles.bcCartSummaryItemTotalPriceLabel}>
            {(!consignmentSet && manualTotals) ? (
              <CurrencyFormatter currency={currency.code} amount={displayGrandTotal} taxCalc={false} appendZero={true} />
            ) : (
              <CurrencyFormatter appendZero={true} currency={currency.code} amount={grandTotal} taxCalc={!consignmentSet} />
            )}
          </span>
        </div>

        {cartStep && (
          <>
            {agreementRequired && (
              <div className={styles.dateAgreement}>
                <Checkbox
                  name="shipping date agreement"
                  label={(<><ContentfulTranslationText keyName='acknowledgeDate'>I acknowledge the schedule delivery date of</ContentfulTranslationText> {agreementRequired}</>)}
                  action={(e) => setDateAgreement(!dateAgreement)}
                  isChecked={dateAgreement}
                />
              </div>
            )}

            {(['EUR', 'GBP'].indexOf(currency.code) > '-1') && (
              <div className={styles.notice}>
                <ContentfulTranslationText keyName='vatNotice'>We cover the cost of all VAT/Duties import taxes, there will be no additional charges once your items are shipped.</ContentfulTranslationText>
              </div>
            )}

            <div className={clsx(styles.bcCartCheckoutButton)}>
                <Button level="primary" type="button" disabled={agreementRequired && !dateAgreement} onClick={(e) => {
                  e.preventDefault(); 
                  dataLayerPush('Proceed to Checkout', {name: 'Cart page', content: 'Proceed to Checkout'});
                  cartFunc()
                }}>
                  <ContentfulTranslationText keyName="checkout">Checkout</ContentfulTranslationText>
                </Button>
                {/* {cartShippingMethod === 'delivery' && (
                  <FastCheckout cart_id={cart.cartId} withFastInfo={true} withOr="top" />
                )} */}
            </div>
          </>
          )}
      </div>
    );
  }

  return (
    <div className={styles.bcMiniCartSummary}>
      <div data-mini-subtotal className="flex-between">
        <span className={styles.subtotalText}><ContentfulTranslationText keyName="subtotal">Subtotal</ContentfulTranslationText></span>
        <span className={styles.subtotal}>
          {(!consignmentSet && manualTotals) ? (
            <CurrencyFormatter currency={currency.code} amount={displayCheckoutAmount} taxCalc={false} appendZero={true} />
          ) : (
            <CurrencyFormatter currency={currency.code} amount={cartAmount} taxCalc={!consignmentSet} appendZero={true} />
          )}
        </span>
      </div>
      <div className={styles.buttonWrapp}>
        <Button level="primary" href={handleLink("/confirm-order/")} onClick={(e) => {
          e.preventDefault(); 
          dataLayerPush('View Cart', {name: 'Mini Cart + View cart', content: 'View cart button'}, null, handleLink('/confirm-order/'), true);
          // onClick()
        }}>
          <span><ContentfulTranslationText keyName="viewBag">View bag</ContentfulTranslationText></span>
        </Button>
        {/* <FastCheckout cart_id={cart.cartId} withOr="top" withFastInfo={true} /> */}
      </div>
    </div>
  );
};

export default CartSummary;
