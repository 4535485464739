import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Dialog from '../Dialog/Dialog';
import Icon from '../Icon/Icon';
import ContentfulRichText from '../ContentfulRichText/ContentfulRichText';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

function PdpAdditionalContent({ name, styles, labelOverride, onClick }) {
    const [openDetail, openAdditionalDetail] = useState(false);
    const contentData = useStaticQuery(graphql`
        query {
            allContentfulGlobalContent {
                nodes {
                  name
                  title
                  content {
                      raw
                  }
                }
            }
        }
    `)
    const contentBlocks = contentData?.allContentfulGlobalContent.nodes;

    const contentObj = contentBlocks.find(content => content.name === `PDP Detail: ${name}`);

    return (
        <>
            {contentObj && (
                <>
                    <span className={styles.additionalDetail} role="presentation" onClick={() => {
                        openAdditionalDetail(!openDetail);
                        onClick && onClick();
                    }}>
                        {labelOverride ? labelOverride : (<><ContentfulTranslationText keyName="additionalDetail">Additional detail</ContentfulTranslationText> <span className={styles.infoIcon}><Icon symbol='info' /></span></>)}
                    </span>
                    <Dialog open={openDetail} size="md" hideBtnCancel hideBtnOk onCancel={() => {openAdditionalDetail(false);}}>
                        {contentObj.title && (<h3>{contentObj.title}</h3>)}
                        <ContentfulRichText raw={contentObj.content.raw} />
                    </Dialog>
                </>
            )}
        </>
    )
}

export default PdpAdditionalContent