import React, { useContext, useMemo, useState } from "react"
import { get } from "lodash";
import ContentfulContext from '../../../context/ContentfulProvider';
import { dataLayerPush } from "../../../helpers/general"
import { subscribe, checkIfExisting, subscribeBackInStock } from '../../../helpers/klaviyo'
import Icon from "../Icon/Icon"

import * as styles from './KlaviyoForm.module.css'
import Checkbox from "../Checkbox/Checkbox";

const KlaviyoForm = ({ listId, contentOverrides, backInStockFlow = false }) => {
    const [consent, setConsent] = useState(false);
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const content = useMemo(() => ({
        thankYouMsg: get(translations, 'footerSubscribeThankYou.title', "Thank you for subscribing"),
        alreadySubMsg: get(translations, 'footerSubscribeSubscribed.title', "Email already subscribed"),
        dlEventName: "Klaviyo Submission",
        dlEventDataName: "Submission",
        dlEventDataContent: "Form",
        ...contentOverrides
    }), [translations, contentOverrides]);
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [message, setMessage] = useState(content.thankYouMsg);
    const [fields, setFields] = useState({
        email: '',
        first_name: '',
        last_name: '',
    });

    const setFieldValue = (fieldName, fieldValue) => {
        const newField = {};
        newField[fieldName] = fieldValue;
        setFields({...fields, ...newField});
    }

    const submitHandler = e => {
        const email = fields.email;
        e.preventDefault();
        if (listId) {
            checkIfExisting(listId, email).then((response) => {
                // user not existing
                if(response.response.length === 0) {
                    subscribe(listId, email).then(response => {
                        if (response && 'status' in response && response.status === 200) {
                            setSubmitSuccessful(true);
                            dataLayerPush(content.dlEventName, {name: content.dlEventDataName, content: content.dlEventDataContent});
                            setMessage(content.thankYouMsg);
                            setFields('email', '');
                        }
                    });
                } else {
                    setMessage(content.alreadySubMsg);
                    setSubmitSuccessful(true);
                }
            });
        }

        if(backInStockFlow) {
            let thisData = {
                email: email,
                variant: content.productId,
                platform: 'bigcommerce',
            };
            subscribeBackInStock(thisData).then((response) => {
                if (response && 'status' in response && response.status === 200) {

                    if (consent) {
                        const listId = 'UmRkRq';
                        subscribe(listId, {
                            'email': email,                        
                            '$consent': ['email']
                        }, true);
                    }

                    setSubmitSuccessful(true);
                    setMessage(content.thankYouMsg);
                    setFields('email', '');
                } else {
                    setMessage('Something went wrong, please try again later.');
                }
            });
        }
    }

    return (
        <form onSubmit={submitHandler}>
            <div data-fields className={`${submitSuccessful ? styles.hide : styles.show}`}>
                <div className={styles.emailInput}>
                    <input placeholder={get(translations, 'emailAddress.title', 'Email')} type="text" id="subscribe" onChange={(e) => setFieldValue('email', e.target.value)} />
                    <button level="primary" type="buttonSubmit" aria-label="submit-subscription"><Icon symbol="subscribeArrowRight" /></button>
                </div>
                {backInStockFlow && <div className={styles.emailCheckbox}>
                    <Checkbox
                        name="consent"
                        label={get(translations, 'backInStockConsent.title', 'By checking this box you are agreeing to the Forcite Terms of Service and Privacy Policy. You may unsubscribe at any time.')}
                        action={(e) => setConsent(!consent)}
                        isChecked={consent}/>
                </div>}
            </div>
            <div data-message className={`${submitSuccessful ? styles.show : styles.hide}`}>
                <p className={`mb-0`}>{message}</p>
            </div>
        </form>
    );
}
 
export default KlaviyoForm;