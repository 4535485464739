import React from 'react'

const Info = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6ZM1.31662 6C1.31662 3.41344 3.41344 1.31661 6 1.31661C8.58656 1.31661 10.6834 3.41344 10.6834 6C10.6834 8.58656 8.58656 10.6834 6 10.6834C3.41344 10.6834 1.31662 8.58656 1.31662 6Z" fill="currentColor"/>
    <rect width="1.11628" height="3.62791" transform="matrix(-1 0 0 1 6.69763 5.30273)" fill="currentColor" opacity="0.6"/>
    <rect width="1.11628" height="1.11628" transform="matrix(-1 0 0 1 6.69763 3.06934)" fill="currentColor" opacity="0.6"/>
    </svg>
)

export default Info