import React from 'react'

const Youtube = () => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 600 600"
        style={{
        enableBackground: "new 0 0 600 600",
        }}
        xmlSpace="preserve"
    >
        <style>{".st1{fill:#231f20}"}</style>
        <g
        style={{
            enableBackground: "new",
        }}
        >
        <path
            className="st1"
            d="M150.6 573.5h-7.9V557l-20.1-23.5H133l13.7 16 13.7-16h10.4L150.6 557v16.5zM206.5 533.5c1.7 0 3.3.3 4.7.9 1.5.6 2.8 1.4 3.9 2.5s1.9 2.4 2.5 3.9c.6 1.5.9 3 .9 4.7v16.1c0 1.7-.3 3.3-.9 4.7-.6 1.5-1.4 2.8-2.5 3.9s-2.4 1.9-3.9 2.5c-1.5.6-3 .9-4.7.9h-23c-1.7 0-3.3-.3-4.7-.9-1.5-.6-2.8-1.4-3.9-2.5s-1.9-2.4-2.5-3.9c-.6-1.5-.9-3-.9-4.7v-16.1c0-1.7.3-3.3.9-4.7.6-1.5 1.4-2.8 2.5-3.9s2.4-1.9 3.9-2.5c1.5-.6 3-.9 4.7-.9h23zm-23 7.8c-.9 0-2.2.2-3.1 1.1-.9.9-1.1 2.1-1.1 3.1v16.1c0 .9.2 2.2 1.1 3.1.9.9 2.1 1.1 3.1 1.1h23c.9 0 2.2-.2 3.1-1.1.9-.9 1.1-2.1 1.1-3.1v-16.1c0-.9-.2-2.2-1.1-3.1-.9-.9-2.1-1.1-3.1-1.1h-23zM271.4 561.5c0 1.7-.3 3.3-.9 4.7-.6 1.5-1.4 2.8-2.5 3.9s-2.4 1.9-3.9 2.5c-1.5.6-3 .9-4.7.9h-23c-1.7 0-3.3-.3-4.7-.9-1.5-.6-2.8-1.4-3.9-2.5s-1.9-2.4-2.5-3.9c-.5-1.5-.8-3-.8-4.7v-28.1h7.9v28.1c0 .9.2 2.2 1.1 3.1.9.9 2.1 1.1 3.1 1.1h23c.9 0 2.2-.2 3.1-1.1.9-.9 1.1-2.1 1.1-3.1v-28.1h7.9v28.1zM303.4 541.3v32.2h-7.9v-32.2h-17.8v-7.9h43.5v7.9h-17.8zM374.5 561.5c0 1.7-.3 3.3-.9 4.7-.6 1.5-1.4 2.8-2.5 3.9s-2.4 1.9-3.9 2.5c-1.5.6-3 .9-4.7.9h-23c-1.7 0-3.3-.3-4.7-.9-1.5-.6-2.8-1.4-3.9-2.5s-1.9-2.4-2.5-3.9c-.5-1.5-.8-3-.8-4.7v-28.1h7.9v28.1c0 .9.2 2.2 1.1 3.1.9.9 2.1 1.1 3.1 1.1h23c.9 0 2.2-.2 3.1-1.1.9-.9 1.1-2.1 1.1-3.1v-28.1h7.9v28.1zM416.3 533.5c1.7 0 3.3.3 4.7.9 1.5.6 2.8 1.4 3.9 2.5s1.9 2.4 2.5 3.9c.6 1.5.9 3 .9 4.7 0 1.7-.3 3.2-.9 4.7-.5 1.3-1.1 2.4-2 3.3.9 1 1.5 2.1 2 3.3.6 1.5.9 3 .9 4.7 0 1.7-.3 3.3-.9 4.7-.6 1.5-1.4 2.8-2.5 3.9s-2.4 1.9-3.9 2.5c-1.5.6-3 .9-4.7.9h-35v-40.1h35zm-27.1 16.1h27.1c.9 0 2.2-.2 3.1-1.1s1.1-2.1 1.1-3c0-.9-.2-2.2-1.1-3.1-.9-.9-2.1-1.1-3.1-1.1h-27.1v8.3zm0 16.1h27.1c.9 0 2.2-.2 3.1-1.1.9-.9 1.1-2.1 1.1-3.1 0-.9-.2-2.1-1.1-3s-2.1-1.1-3.1-1.1h-27.1v8.3zM442.4 541.3v8.2h34.5v7.9h-34.5v8.2h34.5v7.9h-42.3v-40.1h42.3v7.9h-34.5z"
        />
        </g>
        <path
        d="M258.3 134.4c46.9 27 93.5 53.8 140.5 80.9-47.1 26.2-93.6 52.1-140.5 78.2V134.4z"
        style={{
            fill: "#333332",
        }}
        />
        <circle
        cx={299.2}
        cy={214}
        r={189}
        style={{
            fill: "none",
            stroke: "#333332",
            strokeWidth: 20,
            strokeMiterlimit: 10,
        }}
        />
    </svg>
)

export default Youtube