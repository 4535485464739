// extracted by mini-css-extract-plugin
export var productImageThumb = "ProductImages-module--productImageThumb---Jqy4";
export var productImage = "ProductImages-module--productImage--33pf3";
export var product360 = "ProductImages-module--product360--1ytwt";
export var imageContainer = "ProductImages-module--imageContainer--T7MHF";
export var imageSlider = "ProductImages-module--imageSlider--Z4O3D";
export var productSliderImageWrapp = "ProductImages-module--productSliderImageWrapp--lYuVi";
export var productSliderImage = "ProductImages-module--productSliderImage--JBwGV";
export var mobilePagination = "ProductImages-module--mobilePagination--XV9-7";
export var customDots = "ProductImages-module--customDots--KaXqw";
export var imageRotator = "ProductImages-module--imageRotator--V9JOt";
export var productImageActive = "ProductImages-module--productImageActive--uIoSx";