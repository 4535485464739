// extracted by mini-css-extract-plugin
export var productDetails = "ProductDetails-module--productDetails--FA5ew";
export var designName = "ProductDetails-module--designName--xddFe";
export var rate = "ProductDetails-module--rate--HtTgu";
export var stickyAddToCartContainer = "ProductDetails-module--stickyAddToCartContainer--iSv9k";
export var showSticky = "ProductDetails-module--showSticky--Tl+Yb";
export var stickyTitlePriceContainer = "ProductDetails-module--stickyTitlePriceContainer--p2JZq";
export var rateWrap = "ProductDetails-module--rateWrap--lbHgI";
export var hidePricing = "ProductDetails-module--hidePricing--OtkjV";
export var prices = "ProductDetails-module--prices--T0U1u";
export var price = "ProductDetails-module--price--8MI-D";
export var salePrice = "ProductDetails-module--salePrice--x4uNU";
export var onSale = "ProductDetails-module--onSale--igsd0";
export var productStocks = "ProductDetails-module--productStocks--psTaE";
export var productStocksTime = "ProductDetails-module--productStocksTime--Bqjy-";
export var priceDetails = "ProductDetails-module--priceDetails--dt+3m";
export var priceDetailsAfterpay = "ProductDetails-module--priceDetailsAfterpay--OY1Dz";
export var afterPayHelp = "ProductDetails-module--afterPayHelp--P7vB5";
export var warranty = "ProductDetails-module--warranty--wJP0e";
export var productQtyFav = "ProductDetails-module--productQtyFav--3aWc3";
export var qtyMinus = "ProductDetails-module--qtyMinus--zop13";
export var qtyPlus = "ProductDetails-module--qtyPlus--a1t1b";
export var maxQtyMessage = "ProductDetails-module--maxQtyMessage--ctn-F";
export var addToCartButton = "ProductDetails-module--addToCartButton--Tc165";
export var preorderDetail = "ProductDetails-module--preorderDetail--u528d";
export var productMessage = "ProductDetails-module--productMessage--tFY+C";
export var productDescriptionShow = "ProductDetails-module--productDescriptionShow--ihSch";
export var productDescription = "ProductDetails-module--productDescription--eC07w";
export var active = "ProductDetails-module--active--diRwT";
export var productHighlightVideo = "ProductDetails-module--productHighlightVideo--pp33o";
export var modalContainer = "ProductDetails-module--modalContainer--hYGXI";
export var priceAfterpayDetails = "ProductDetails-module--priceAfterpayDetails--Y594Y";
export var completeTheLook = "ProductDetails-module--completeTheLook--BxWho";
export var arrowContainers = "ProductDetails-module--arrowContainers--q8yzp";
export var stockDetails = "ProductDetails-module--stockDetails--pQgZX";
export var productMeta = "ProductDetails-module--productMeta--RzGRO";
export var waitlistForm = "ProductDetails-module--waitlistForm--0Nj1I";
export var additionalDetail = "ProductDetails-module--additionalDetail--X0EAD";
export var bnplText = "ProductDetails-module--bnplText--uE7h2";
export var productMetaContainer = "ProductDetails-module--productMetaContainer--f2O2S";
export var noReviews = "ProductDetails-module--noReviews--sVpPL";