import React from "react"
import Icon from '../../atoms/Icon/Icon'
import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'
// import NavWishlist from '../../molecules/NavWishlist/NavWishlist'
import NavCart from '../../molecules/NavCart/NavCart'
// import CurrencySelect from '../../molecules/CurrencySelect/CurrencySelect'

import * as styles from './UserMenu.module.css'

const UserMenu = ({pageStyle, isScrolled, searchVisibility, searchHandler, isLoggedIn, abrvName}) => {

    return (
        <>
            {/* <div className={styles.userMenuItem} role="presentation">
                <CurrencySelect styles={styles} />
            </div>
            <div className={styles.userMenuItem} role="presentation">
                <Icon symbol="translate" />
            </div>
            <div className={`${styles.userMenuItem}`} role="presentation" onClick={() => searchHandler(!searchVisibility)}>
                <Icon symbol="search" />
            </div>
            <div className={`${styles.userMenuItem}`}>
                <NavWishlist styles={styles} />
            </div> */}
            <div className={`${styles.accountInfoContainer} ${styles.userMenuItem} ${isLoggedIn ? styles.loggedIn : null} ${pageStyle === 'transparent' ? styles.transparentStyle : styles.defaultUser} ${pageStyle === 'transparent' && isScrolled ? styles.transparentScrolled : ''}`}>
                <div className={styles.menuContainer}>
                    {!isLoggedIn &&
                        <div className={styles.userIconContainer}>
                            <Icon symbol="user" />
                            <div className={`${styles.arrowUp} ${styles.arrowUpMobile}`}></div>
                        </div>
                    }
                    {isLoggedIn &&
                        <div className={styles.nameContainer}>
                            <span>{abrvName}</span>
                            <div className={`${styles.arrowUp} ${styles.arrowUpMobile}`}></div>
                        </div>
                    }
                    <div className={styles.userDropdown}>
                        <div className={styles.arrowUp}>
                            <div className={styles.invisibleContent}></div>
                        </div>
                        <AccountPageMenu isLoggedIn={isLoggedIn} />
                    </div>
                </div>
            </div>
            <div className={`${styles.userMenuItem} ${pageStyle === 'transparent' ? styles.transparentStyleCart : styles.defaultCart } ${pageStyle === 'transparent' && isScrolled ? styles.transparentScrolled : ''}`}>
                <NavCart styles={styles} mode="drawer" />
            </div>
        </>
    )
}

export default UserMenu