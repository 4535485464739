import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import { handleLink } from "../../../helpers/general";
import Dialog from '../Dialog/Dialog';
import KlaviyoForm from '../KlaviyoForm/KlaviyoForm';
import { scroller } from 'react-scroll';

import * as styles from './Button.module.css'
import ContentfulTranslationText from "../ContentfulTranslationText/ContentfulTranslationText";
import ContentfulContext from "../../../context/ContentfulProvider";
import { get } from "lodash";

const Button = ({ children, href, target, level, type, size, alternate, disabled, onClick, name, value, className, flat, link, imageLink,fullWidth, theme }) => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const classes = [styles.button];
    const [openDialog, triggerDialog] = useState(false);

    if (level in styles) {
        classes.push(styles[level])
    }
    if (size in styles) {
        classes.push(styles[size])
    }
    if (theme in styles) {
        classes.push(styles[theme])
    }
    if (alternate) {
        classes.push(styles.alternate)
    }
    if (disabled) {
        classes.push(styles.disabled)
    }
    if (flat) {
        classes.push(styles.flat)
    }
    if (link) {
        classes.push(styles.link)
    }
    if (imageLink) {
        classes.push(styles.imageLink)
    }
    if (fullWidth) {
        classes.push(styles.fullWidth)
    }
    if (className) {
        classes.push(className)
    }
    
    const classOutput = classes.join(' ');

    const registeredEvents = [
        '#registerInterest'
    ];

    const scrollToHash = (evt) => {
        if (evt.currentTarget.getAttribute('href') && evt.currentTarget.getAttribute('href').indexOf('/#') > -1) {
            evt.preventDefault();
            const id = evt.currentTarget.getAttribute('href').split('/#')[1];
            scroller.scrollTo(
                id,
                {
                    duration: 800,
                    delay: 0,
                    offset: -180,
                    smooth: 'easeInOutQuart'
                }
            );
        }
    }

    return (
        <>
            {registeredEvents.indexOf(href) > -1 ? (
                <>
                    {href === '#registerInterest' && (
                        <>
                            <span data-button className={classOutput} role="presentation" onClick={() => triggerDialog(true)}>{children}</span>
                            <Dialog open={openDialog} size="md" hideBtnCancel hideBtnOk onCancel={() => {triggerDialog(false);}}>
                                <h3><ContentfulTranslationText keyName="registerYourInterest">Register your Interest</ContentfulTranslationText></h3>
                                <p><ContentfulTranslationText keyName="regionNotAvailableForShipping">Our helmets are not shipping in your detected region, however, we are furiously working on this to make our helmets accessible to all countries.</ContentfulTranslationText></p>
                                <p><ContentfulTranslationText keyName="registerYourEmailNotice">Register your email address below to be updated when information is available.</ContentfulTranslationText></p>
                                <div style={{marginTop: '20px'}}>
                                    <KlaviyoForm listId="UTfATq" contentOverrides={{
                                        thankYouMsg: get(translations, 'thankYouRegisterInterest.title', "Thank you for registering your interest"),
                                        alreadySubMsg: get(translations, 'registerInterestSubscribed.title', "Email already registered"),
                                        dlEventName: "Klaviyo Register Interest",
                                        dlEventDataName: "Submission",
                                        dlEventDataContent: "Form",
                                    }} />
                                </div>
                            </Dialog>
                        </>
                    )}
                </>
            ) : (
                <>
                    {(type === 'inputButton' || type === 'inputSubmit') && (
                        <input className={classOutput} type={type === 'inputSubmit' ? "submit" : "button"} name={name} value={value} {...(onClick ? {onClick} : {onClick: scrollToHash})} />
                    )}
                    {(type === 'button' || type === 'buttonSubmit') && (
                        <button className={classOutput} type={type === 'buttonSubmit' ? "submit" : ""} {...(onClick ? {onClick} : {onClick: scrollToHash})}>{children}</button>
                    )}
                    {(type === 'span') && (
                        <span data-button className={classOutput} role="presentation" {...(onClick ? {onClick} : {onClick: scrollToHash})}>{children}</span>
                    )}
                    {(!type || type === 'a') && (target || (href.startsWith('http') && !href.startsWith(process.env.GATSBY_SITE_PATH))) && (
                        <a href={handleLink(href)} className={classOutput} {...(onClick ? {onClick} : {onClick: scrollToHash})} disabled={disabled} target="_blank" rel="noopener noreferrer nofollow">{children}</a>
                    )}
                    {(!type || type === 'a') && !target && (href.startsWith('http') && href.startsWith(process.env.GATSBY_SITE_PATH)) && (
                        <a href={handleLink(href)} data-type="a" className={classOutput} {...(onClick ? {onClick} : {onClick: scrollToHash})} disabled={disabled}>{children}</a>
                    )}
                    {(!type || type === 'a') && !target && !(href.startsWith('http')) && (
                        <Link to={handleLink(href)} className={classOutput} {...(onClick ? {onClick} : {onClick: scrollToHash})} disabled={disabled}>{children}</Link>
                    )}
                </>
            )}
        </>
    )
}

export default Button