import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';
import clsx from 'clsx';
import PriceContext from '../../../../context/PriceProvider';
import CurrencyFormatter from '../../../atoms/CurrencyFormatter/CurrencyFormatter';
import Icon from '../../../atoms/Icon/Icon';
import AdjustItem from '../../../molecules/AdjustItem/AdjustItem';
import Logo from '../../../atoms/Logo/Logo';
import ContentfulProductTranslation from '../../../atoms/ContentfulProductTranslation/ContentfulProductTranslation';
import ContentfulTranslationText from '../../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import { getUriFromURL, handleLink } from '../../../../helpers/general';

import * as styles from '../Cart.module.css';

const CartItem = ({
  cartType,
  currency,
  item,
  productInfo,
  itemType,
  updatingItem,
  removeItemFromCart,
  updateCartItemQuantity,
  taxCalc
}) => {
  // const [showAdjustMobile, setShowAdjustMobile] = useState(false);
  // console.log(item, process.env.GATSBY_TAX_DISPLAY);
  const priceCxt = useContext(PriceContext);
  const nonTaxProducts = priceCxt && priceCxt.nonTaxProducts;
  // console.log(nonTaxProducts, item.product_id);
  const visibleProductsQuery = useStaticQuery(graphql`
    query {
      allBigCommerceProducts {
        nodes {
          custom_url {
            url
          }
        }
      }
    }
  `)
  const visibleProducts = visibleProductsQuery?.allBigCommerceProducts.nodes.map(product => handleLink(product.custom_url.url));
  // console.log(visibleProducts);
  const [title, uri, sku] = useMemo(() => {
    const productUri = handleLink(getUriFromURL(item.url), process.env.GATSBY_ENVIRONMENT_PATH);
    // console.log(productUri);
    if (itemType === 'gift') {
      return [
        `${item.name} - Gift Certificate for ${item.recipient.name}`,
        productUri,
        `Theme: ${item.theme}`
      ];
    }
    if (visibleProducts.indexOf(productUri) > -1) {
      return [item.name, productUri, item.sku];
    }
    return [item.name, null, item.sku];
  }, [itemType, item, visibleProducts]);

  const OutputLink = ({ children }) => {
    if (uri !== null) {
      return <a href={uri}>{children}</a>
    }

    return <React.Fragment>{children}</React.Fragment>
  }

  const itemImage = useMemo(() => {
    if (cartType === 'full') {
      return (
        <div className={styles.bcCartImage}>
          <OutputLink>
            {(!itemType && item.image_url.indexOf('ProductDefault.gif') === -1) && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
            {(itemType === 'custom' || item.image_url.indexOf('ProductDefault.gif') > -1) && (
              <Logo />
            )}
          </OutputLink>
        </div>
      );
    }
    if (cartType === 'mini') {
      return (
        <div className={styles.bcMiniCartImage}>
          <OutputLink>
            {(!itemType && item.image_url.indexOf('ProductDefault.gif') === -1) && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
            {(itemType === 'custom' || item.image_url.indexOf('ProductDefault.gif') > -1) && (
              <Logo />
            )}
          </OutputLink>
        </div>
      );
    }
  }, [cartType, item, itemType]);

  const formatDate = (date) => {
    const dateObj = new Date(date);
    return `${dateObj.getDate()} ${dateObj.toLocaleString('default', { month: 'long' })}, ${dateObj.getFullYear()}`;
  }

  const isMiniCart = cartType === 'mini';
  const isUpdating = updatingItem === item.id;

  /* Don't display products that are attached to products */
  if (item.parent_id !== null) return null;

  return (
    <div
      className={clsx(
        styles.bcCartItem,
        isMiniCart && styles.bcMiniCartItem,
        isUpdating && styles.disableAction
      )}
      data-sku={sku}
    >
      <div
        className={clsx(
          !isMiniCart && 'row',
          isMiniCart && styles.bcMiniCartItemRow
        )}
      >
        {itemImage}

          <div className={styles.bcMiniCartItemDetails}>
            <div
              className={clsx(
                !isMiniCart && styles.bcCartItemMeta,
                isMiniCart && styles.bcMiniCartItemMeta
              )}
            >
              <div className={styles.bcProductTitle}>
                <OutputLink><ContentfulProductTranslation productId={item.product_id} field="name">{title}</ContentfulProductTranslation></OutputLink>
                {/* To force load of page even if in the current page already. - JR */}
                {/* <Link to={uri}>{title}</Link> */}
              </div>

              {item.options && (
                <div className={`${styles.bcProductDetail} ${isMiniCart && styles.none}`}>
                  {item.options && item.options.map((option, optionIndex) => {
                    if (option.name.indexOf('Delivery') > -1) {
                      item.options.splice(0,0,item.options.splice(optionIndex, 1)[0]);
                    }
                    return null
                  })}
                  {item.options && item.options.map((option, optionIndex) => {
                    if (option.name.indexOf('Parts') === -1) {
                      return (
                        <div key={optionIndex} className={option.name.indexOf('Delivery') > -1 ? styles.highlight : ''}>
                          <span><ContentfulTranslationText keyName={option.name.indexOf('Delivery') > -1 ? 'scheduledDelivery' : option.name.toLowerCase()}>{option.name}</ContentfulTranslationText>:</span> <span><ContentfulTranslationText keyName={option.value.toLowerCase()}>{option.value}</ContentfulTranslationText></span>
                        </div>
                      )
                    } else {
                      return null;
                    }
                  })}
                </div>
              )}

              <div className={`${styles.bcMiniCartCloseContainer} ${!isMiniCart && styles.none}`}>
                <span
                data-button
                role="presentation"
                className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
                onClick={() => removeItemFromCart(item.id)}
                >
                <Icon symbol="close" />
                </span>
              </div>

            </div>
            <div className={`${isMiniCart === true ? 'flex-between' : styles.none}`}>
              <div className={styles.bcProductDetail}>
                Qty: {item.quantity}
                {productInfo && productInfo[item.product_id]?.availability === 'preorder' && (
                  <span className={styles.preorder}>{productInfo[item.product_id].preorder_message.replace('%%DATE%%', formatDate(productInfo[item.product_id].preorder_release_date))}</span>
                )}
              </div>
                <div className={styles.bcCartPrice}>
                <CurrencyFormatter
                  currency={currency.code}
                  amount={get(item, process.env.GATSBY_TAX_DISPLAY === 'ex' ? 'extended_list_price' : 'extended_sale_price', item.amount)}
                  taxCalc={nonTaxProducts.indexOf(item.product_id) !== -1 ? false : taxCalc}
                />
              </div>

            </div>
          </div>

        <div
          className={clsx(
            !isMiniCart && styles.bcCartItemMeta,
            isMiniCart && styles.bcMiniCartItemMeta
          )}
        >
          {/* <div className={styles.bcProductTitle}>
            <Link to={uri}>{title}</Link>
          </div> */}
          {/* <div className={styles.bcProductDetail}>
            Qty: {item.quantity}
            {productInfo && productInfo[item.product_id]?.availability === 'preorder' && (
              <span className={styles.preorder}>{productInfo[item.product_id].preorder_message.replace('%%DATE%%', formatDate(productInfo[item.product_id].preorder_release_date))}</span>
            )}
          </div> */}

          {/* mobile view */}
          {/* <div className={styles.bcCartMobileDetails}>
            {productInfo && productInfo[item.product_id]?.availability === 'preorder' && (
              <span className={styles.preorder}>{productInfo[item.product_id].preorder_message.replace('%%DATE%%', formatDate(productInfo[item.product_id].preorder_release_date))}</span>
            )}
            <div className="flex-start">
              <span>Quantity</span>
              <span className={clsx(styles.bcCartPrice, 'mx-3')}>
                {item.quantity}
              </span>
              <span
                data-button
                role="presentation"
                className={clsx(
                  styles.bcCartOpenAdjust,
                  showAdjustMobile && styles.bcCartCloseAdjust,
                  'icon-wrap'
                )}
                onClick={() => setShowAdjustMobile(!showAdjustMobile)}
              >
                <Icon symbol="caretDown" />
              </span>
            </div>
            {showAdjustMobile && (
              <div className={styles.bcCartMobileAdjust}>
                <AdjustItem
                  item={item}
                  updatingItem={updatingItem}
                  updateCartItemQuantity={updateCartItemQuantity}
                />
              </div>
            )}
          </div> */}
        </div>

        {/* {isMiniCart && (
          <div className={styles.bcMiniCartPrice}>
            <span
              data-button
              role="presentation"
              className={clsx(styles.bcCartBtn, 'icon-wrap')}
              onClick={() => removeItemFromCart(item.id)}
            >
              <Icon symbol="close" />
            </span>
            <div className={styles.bcCartPrice}>
              <CurrencyFormatter
                currency={currency.code}
                amount={get(item, 'extended_sale_price', item.amount)}
                ignoreTax={true}
              />
            </div>
          </div>
        )} */}
      </div>

      {!isMiniCart && (
        <div className={styles.bcCartDetils}>
          <div className={clsx('flex-middle', styles.bcCartPrice, styles.itemPrice)}>
            <CurrencyFormatter
              currency={currency.code}
              amount={get(item, process.env.GATSBY_TAX_DISPLAY === 'ex' ? 'list_price' : 'sale_price', item.amount)}
              taxCalc={nonTaxProducts.indexOf(item.product_id) !== -1 ? false : taxCalc}
            />
          </div>
          <div className={clsx('flex-center')}>
            <AdjustItem
              item={item}
              cartType={cartType}
              updatingItem={updatingItem}
              updateCartItemQuantity={updateCartItemQuantity}
            />
          </div>
          <div className={clsx('flex-between', styles.bcCartPrice)}>
            <div className={styles.itemPrice}>
              <CurrencyFormatter
                currency={currency.code}
                amount={get(item, process.env.GATSBY_TAX_DISPLAY === 'ex' ? 'extended_list_price' : 'extended_sale_price', item.amount)}
                taxCalc={nonTaxProducts.indexOf(item.product_id) !== -1 ? false : taxCalc}
              />
            </div>
            <span
              data-button
              role="presentation"
              className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
              onClick={() => removeItemFromCart(item.id)}
            >
              <Icon symbol="close" />
            </span>
          </div>
        </div>
      )}


      {/* {!isMiniCart && (
        <div className={styles.bcCartMobileItem}>
          <span
            data-button
            role="presentation"
            className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
            onClick={() => removeItemFromCart(item.id)}
          >
            <Icon symbol="close" />
          </span>
          <span className={styles.bcCartPrice}>
            <CurrencyFormatter
              currency={currency.code}
              amount={get(item, 'extended_sale_price', item.amount)}
              ignoreTax={true}
            />
          </span>
        </div>
      )} */}
      {!isMiniCart &&
      <div className={styles.fullCartActionsMobile}>
        <AdjustItem
          item={item}
          updatingItem={updatingItem}
          updateCartItemQuantity={updateCartItemQuantity}
        />
        <div className={`${styles.itemPrice} ${styles.bcCartPrice}`}>
          <CurrencyFormatter
            currency={currency.code}
            amount={get(item, process.env.GATSBY_TAX_DISPLAY === 'ex' ? 'extended_list_price' : 'extended_sale_price', item.amount)}
            taxCalc={nonTaxProducts.indexOf(item.product_id) !== -1 ? false : taxCalc}
          />
        </div>
      </div>}

      {!isMiniCart &&
      <div className={styles.closeContainerResponsive}>
        <span
          data-button
          role="presentation"
          className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
          onClick={() => removeItemFromCart(item.id)}
          >
        <Icon symbol="close" />
        </span>
      </div>
}

    </div>
  );
};

CartItem.propTypes = {
  removeItemFromCart: PropTypes.func,
  updateCartItemQuantity: PropTypes.func
};

CartItem.defaultProps = {
  removeItemFromCart: () => null,
  updateCartItemQuantity: () => null
};

export default CartItem;
