import React, { useContext, useMemo, useState } from 'react';
import { has, get } from 'lodash';
import AuthContext from '../../../context/AuthProvider';
import PriceContext from '../../../context/PriceProvider';
import ContentfulContext from '../../../context/ContentfulProvider';
// import { navigate } from 'gatsby';
import { dataLayerPush, dissectTitle, handleLink } from '../../../helpers/general';

import Slider from '../../atoms/Slider/Slider';
import WishlistButton from '../../atoms/WishlistButton/WishlistButton';
import Icon from '../../atoms/Icon/Icon';
import Logo from '../../atoms/Logo/Logo';

import Price from '../../molecules/Price/Price';
import ProductQuickView from '../ProductQuickView/ProductQuickView';

import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';

import * as styles from './ProductCard.module.css';

/**
 * Build an array of product images for the product card
 * BC API generated(url_thumbnail) or BC GraphQL(edges) or Empty image(https://via.placeholder.com/300x300?text=Not+Found')
 * @param {*} product
 * @returns array images
 * @todo maybe needs image resize function(320X320) based on original image url
 */

 const getImages = product => {
    if (product && product.defaultImage && product.defaultImage.url)
      return [product.defaultImage.url];
    if (!product || !product.images) return [];
  
    return product.images && has(product.images, 'edges')
      ? product.images.edges.map(image => image.node.url)
      : has(product.images[0], 'url_thumbnail')
        ? product.images.map(image => image.url_thumbnail)
        : [];
};

const ProductCardTemplate = ({ data, removeFunction, inventories = [], detailedView = false }) => {
    const priceCxt = useContext(PriceContext);
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, `productTranslationData.${data.sku}`, false);
    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    const customer = auth && auth.state.object;
    const [isQuickViewOpen, setIsQuickviewOpen] = useState(false);
    const images = useMemo(() => getImages(data), [data]);
    const thumbnail = useMemo(() => {
      if (data.images) {
        if (has(data.images, 'edges')) {
          return data.images.edges.filter((img) => img.is_thumbnail)[0] || data.images.edges[0];
        } else if ( has(data.images[0], 'url_thumbnail')) {
          return data.images.filter((img) => img.is_thumbnail)[0] || data.images[0];
        } else {
          return null;
        }
      }
      return null;
    }, [data]);

    if (!data) return null;
    // console.log(data);

    const getVariantValue = key => {
        if (key === 'variantId') {
          if ('variants' in data) {
            // BC API generated
            if (Array.isArray(data.variants) && 'entityId' in data.variants[0]) {
              return data.variants[0].entityId;
            }
            // BC API generated
            if (Array.isArray(data.variants) && 'id' in data.variants[0]) {
              return data.variants[0].id;
            }
            // BC GraphQL
            if (
              typeof data.variants === 'object' &&
              'edges' in data.variants &&
              'entityId' in data.variants.edges[0].node
            ) {
              return data.variants.edges[0].node.entityId;
            }
          }
          return null;
        }
        if (key === 'product_id' && 'entityId' in data) {
          return data.entityId;
        }
        return null;
      };
    
      const getLink = () => {
        const prepend = '';
        const append = '';
    
        // BC API generated
        if ('custom_url' in data)
          return handleLink(`${prepend}${data.custom_url.url}${append}`);
        // BC GraphQL
        else if ('path' in data) return handleLink(`${prepend}${data.path}${append}`);
        // Not found
        else return '#';
      };
    
      const getPrices = () => {
        if ('prices' in data)
          return {
            price: data.prices.price ? data.prices.price.value : null,
            calculated_price: data.prices.calculatedPrice
              ? data.prices.calculatedPrice.value
              : null,
            sale_price: data.prices.salePrice ? data.prices.salePrice.value : null,
            retail_price: data.prices.retailPrice
              ? data.prices.retailPrice.value
              : null
          };
        else if (typeof data.price === 'object')
          return {
            price: data.price[Object.keys(data.price)[0]]
              ? data.price[Object.keys(data.price)[0]].value
              : null,
            calculated_price: data.calculated_price[
              Object.keys(data.calculated_price)[0]
            ]
              ? data.calculated_price[Object.keys(data.calculated_price)[0]].value
              : null,
            sale_price: data.sale_price[Object.keys(data.sale_price)[0]]
              ? data.sale_price[Object.keys(data.sale_price)[0]].value
              : null
          };
        else
          return {
            price: data.price,
            calculated_price: data.calculated_price,
            sale_price: data.sale_price
          };
      };
    
      const onClick = (e, linkTo) => {
        e.preventDefault();
        e.stopPropagation();

        // navigate(linkTo);
        let customerRecord = null;
        if (userChecked && isLoggedIn) {
          customerRecord = {
            email: customer.email,
            $first_name: customer.first_name,
            $last_name: customer.last_name
          }
        }
        // console.log(priceCxt.prices);
        // console.log(data);
        data.price = priceCxt.prices[data.entityId].price;
        dataLayerPush('ProductClick', {currency: priceCxt.currency}, data, linkTo, false, customerRecord);
      };
    
      const toggleQuickView = e => {
        e.preventDefault();
        e.stopPropagation();
    
        setIsQuickviewOpen(!isQuickViewOpen);
      };
    
      const onChangeQuickView = () => {
        setIsQuickviewOpen(false);
      };
    
      let inventory = inventories.filter(a => a.entityId === data.entityId);
      inventory = inventory.length ? inventory[0] : false;
    
      const productId = getVariantValue('product_id');
      const variantId = getVariantValue('variantId');
      const [productName] = dissectTitle(data.name);
    
      const renderDetailedView = () => {
        return (
          <>
            <ProductQuickView
              open={isQuickViewOpen}
              data={data}
              onTouchEnd={onChangeQuickView}
              productId={productId}
              variantId={variantId}
            />
            <div
              data-productcardroot
              className={styles.productCard}
              role="button"
              tabIndex="0"
            >
              <div className={styles.productCardHead}>
                <span
                  className={styles.viewButton}
                  onClick={toggleQuickView}
                  role="presentation"
                >
                  <Icon symbol="quickView" />
                </span>
                <WishlistButton productId={productId} variantId={variantId} />
              </div>
              <div className={styles.productMainCardBody}>
                <div 
                  className={styles.imageContainer}
                  onClick={(e) => {
                    onClick(e, getLink())
                  }}
                  onKeyPress={(e) => {
                    onClick(e, getLink())
                  }}
                  role="presentation"
                >
                  {images.length > 0 ? (
                    <>
                      <img
                        loading="lazy"
                        src={thumbnail ? thumbnail.url_thumbnail : images[0]}
                        alt={data.name}
                        className={`${styles.productImage}`}
                      />
          
                      <div className={styles.imageSlider}>
                        <Slider dots={false} customArrows={true} autoplay={false}>
                          {images.map((image, index) => (
                            <div className={styles.productSliderImageWrapp} key={index}>
                              <img
                                loading="lazy"
                                src={image}
                                alt={data.name}
                                className={styles.productSliderImage}
                              />
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </>
                  ) : (
                    <Logo />
                  )}
                  {data && data.sale_price > 0 && (
                    <span className={styles.flag}>SALE</span>
                  )}
                  {inventory && typeof inventory.stockLevel === 'number' && inventory.stockLevel > 0 && inventory.stockLevel < 5 && (
                    <span className={styles.flag}>LOW STOCK</span>
                  )}
                  {inventory && inventory.availability === 'Preorder' && (
                    <span className={styles.flag}>PRE ORDER</span>
                  )}
                </div>
      
                <div className={`${styles.textBox}`}>
                  <div 
                    className={styles.productDetails}
                    onClick={(e) => {
                      onClick(e, getLink())
                    }}
                    onKeyPress={(e) => {
                      onClick(e, getLink())
                    }}
                    role="presentation"
                  >
                    <h5 className={styles.productName}>{get(translations, 'name', productName)}</h5>
                    <h5 className={styles.productSku}>
                      {/* {data.brand_name || (has(data.brand, 'name') && data.brand.name)} */}
                    </h5>
                    <Price
                      entityId={data.entityId}
                      {...getPrices()}
                      styles={styles}
                      taxCalc={!(data.tax_class_id === 1)}
                    />
                  </div>
      
                  <div className={styles.addCartButtonWrapp}>
                    {(inventory === false || (inventory && (inventory.isInStock || inventory.availability === 'Preorder'))) && (
                    <AddToCartButton
                      productId={productId}
                      variantId={variantId}
                      fullWidth={true}
                    >
                      <Icon symbol="whiteBag" />
                    </AddToCartButton>
                  )}
      
                  {inventory && !inventory.isInStock && inventory.availability !== 'Preorder' && (<button className={styles.btnOutOfStock}>
                    Out of Stock
                  </button>)}
                  </div>
                </div>
              </div>
              {removeFunction && (
                <span
                  role="presentation"
                  className={styles.removeIcon}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeFunction(
                      getVariantValue('product_id'),
                      getVariantValue('variantId')
                    );
                  }}
                >
                  <Icon symbol="close" />
                </span>
              )}
            </div>
          </>
        )
    }

    if (detailedView) {
        return renderDetailedView();
    } else {
      return (
        <div
          data-productcardroot
          className={`${styles.productCard} ${styles.productCardDefault}`}
          role="button"
          tabIndex="0"
        >
          <div className={styles.imageContainer}>
            {images.length > 0 ? (
              <img
                loading="lazy"
                src={thumbnail ? thumbnail.url_thumbnail : images[0]}
                alt={data.name}
                className={`${styles.productImage}`}
                role="presentation"
                onClick={(e) => {
                  onClick(e, getLink())
                }}
              />
            ) : (
              <Logo />
            )}
          </div>
          <div className={`${styles.textBox}`}>
            <div 
              className={styles.productDetails}
              onClick={(e) => {
                onClick(e, getLink())
              }}
              onKeyPress={(e) => {
                onClick(e, getLink())
              }}
              role="presentation"
            >
              <h5 className={styles.productName}>{get(translations, 'name', productName)}</h5>
              <Price
                entityId={data.entityId}
                {...getPrices()}
                styles={styles}
                taxCalc={!(data.tax_class_id === 1)}
              />
            </div>
          </div>
        </div>
      )
    }
}
  
export default ProductCardTemplate;
