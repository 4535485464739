import React from 'react'

const ContentfulImage = props => {
    const { 
        altText,
        image: {file: {url: image}},
    } = props;

    return (
        <img src={image} alt={altText} />
    )
}

export default ContentfulImage;