import React from 'react'

const Add = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 24 24'>
    <path d="M24 10H14V0h-4v10H0v4h10v10h4V14h10z" />
  </svg>
)

export default Add;

// url("data:image/svg+xml,%3Csvg width='27' height='27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23000' stroke-width='3' d='M14.5 0v27M27 14.5H0'/%3E%3C/svg%3E");