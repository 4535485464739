import React from "react"
import { isNumeric, formatCurrency } from "../../../helpers/general";
import ContentfulTranslationText from "../ContentfulTranslationText/ContentfulTranslationText";
import * as styles from './CurrencyFormatter.module.css';

const CurrencyFormatter = ({ currency, amount, appendZero = false, taxCalc = true }) => {
  const tax = (100 + Number(process.env.GATSBY_TAX_VALUE)) / 100;
  let withTaxAmount = 0;

  if (Array.isArray(amount)) {
    withTaxAmount = amount.map(a => a.taxCalc ? a.amount * tax : a.amount).reduce((a,b) => a + b);
  } else {
    withTaxAmount = taxCalc ? amount * tax : amount;
  }
  // console.log(amount, withTaxAmount, taxCalc, Number(process.env.GATSBY_TAX_VALUE))
  const { formattedPrice, currencySymbol } = formatCurrency(currency, withTaxAmount, appendZero);

  const priceComponent = 
    <>
      <span className={styles.symbol}>{currencySymbol}</span>
      <span>{formattedPrice}</span>
    </>;
  
  return isNumeric(withTaxAmount) ? priceComponent : <ContentfulTranslationText keyName='noPriceAvailable'>No price available</ContentfulTranslationText>
};
  
export default CurrencyFormatter;