import React from 'react';
import { get } from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';
import ContentfulImage from '../ContentfulImage/ContentfulImage';
import ContentfulButton from '../ContentfulButton/ContentfulButton';

function ContentfulEntry({ id }) {
    const entryData = useStaticQuery(graphql`
        query {
            allContentfulEntry(filter: {internal: {type: {in: ["ContentfulButton", "ContentfulImage"]}}}) {
                nodes {
                  id: contentful_id
                  internal {
                    type
                  }
                  ... on ContentfulButton {
                    name
                    buttonStyle
                    ctaLabel
                    ctaUrl
                    ctaAsset {
                      file {
                        url
                      }
                    }
                    turnOnEventTracking
                    eventTrackingKey
                    eventTrackingName
                    eventTrackingContent
                    internal {
                      type
                    }
                  }
                  ... on ContentfulImage {
                    image {
                      file {
                        url
                      }
                    }
                    altText
                  }
                }
            }
        }
    `)
    const entries = entryData?.allContentfulEntry.nodes;

    const entryObj = entries.find(entry => entry.id === id);

    const blockType = get(entryObj, 'internal.type');
    
    switch (blockType) {
        case 'ContentfulButton':
            return <p><ContentfulButton {...entryObj} /></p>

        case 'ContentfulImage':
            return <ContentfulImage {...entryObj} />

        default:
            return null
        
    }
}

export default ContentfulEntry