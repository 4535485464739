// extracted by mini-css-extract-plugin
export var bcCartSummary = "CartSummary-module--bcCartSummary--cflNM";
export var bcCartSummaryTitle = "CartSummary-module--bcCartSummaryTitle--z+I9b";
export var bcCartSummaryItemsContainer = "CartSummary-module--bcCartSummaryItemsContainer--WkynU";
export var bcCartSummaryItem = "CartSummary-module--bcCartSummaryItem--3Y-ab";
export var bcCartSummaryItemTotal = "CartSummary-module--bcCartSummaryItemTotal--bqh0G";
export var bcCartSummaryItemLabel = "CartSummary-module--bcCartSummaryItemLabel--ldFtA";
export var bcCartSummaryItemValue = "CartSummary-module--bcCartSummaryItemValue--ZHhbJ";
export var bcCartSummaryItemTotalLabel = "CartSummary-module--bcCartSummaryItemTotalLabel--AvGoQ";
export var bcCartSummaryItemTotalPriceLabel = "CartSummary-module--bcCartSummaryItemTotalPriceLabel--cryDY";
export var bcCartSubmitForm = "CartSummary-module--bcCartSubmitForm--rKfiS";
export var bcCartCheckoutButton = "CartSummary-module--bcCartCheckoutButton--riRQN";
export var bcCartSummaryToggle = "CartSummary-module--bcCartSummaryToggle--Z6igX";
export var bcMiniCartSummary = "CartSummary-module--bcMiniCartSummary--MJ1yq";
export var buttonWrapp = "CartSummary-module--buttonWrapp--dzOv9";
export var subtotalText = "CartSummary-module--subtotalText--4dRrE";
export var subtotal = "CartSummary-module--subtotal--PALyU";
export var dateAgreement = "CartSummary-module--dateAgreement--hWEFx";
export var notice = "CartSummary-module--notice--ZXBSc";