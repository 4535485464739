// extracted by mini-css-extract-plugin
export var button = "Button-module--button--tqoAU";
export var primary = "Button-module--primary--Bb0RT";
export var alternate = "Button-module--alternate--uBGyK";
export var secondary = "Button-module--secondary--zv1AS";
export var tertiary = "Button-module--tertiary--9RYxN";
export var small = "Button-module--small--I1f-u";
export var smallest = "Button-module--smallest--uPWp2";
export var large = "Button-module--large--cJ2l7";
export var fullWidth = "Button-module--fullWidth--1fQXd";
export var disabled = "Button-module--disabled--ErMnf";
export var flat = "Button-module--flat--VfNML";
export var imageLink = "Button-module--imageLink--yf0Om";
export var link = "Button-module--link--hzFaH";