import React from 'react';

import Icon from '../Icon/Icon'

import * as styles from './Checkbox.module.css'

// import * as styles FROM PARENT

const Checkbox = ({ value, label, id, name, action, isChecked, noBg = false, size='sm' }) => {
    return (
        <div className={styles.checkboxWrapper}>
            <div className={styles.inputWrapper}>
                <input type="checkbox" className={styles.input} id={id} value={value} name={name} data-label={label} onChange={(e) => action(e)} checked={isChecked} />
                <span className={`${styles.box} ${noBg ? styles.boxTransparent : ''} ${size !== 'sm' ? styles[size]: ''}`} role="presentation">
                    <Icon symbol="ticksml" />
                </span>
            </div>
            <label className={styles.label} forhtml={id}>{label}</label>
        </div>
    )
};

export default Checkbox;