// extracted by mini-css-extract-plugin
export var notify = "Notification-module--notify--kMBE7";
export var animate = "Notification-module--animate--61Q2A";
export var fadeIn = "Notification-module--fadeIn--ijdqV";
export var notification = "Notification-module--notification--GqY7B";
export var icon = "Notification-module--icon--511px";
export var content = "Notification-module--content--szKzR";
export var productName = "Notification-module--productName--5gF6b";
export var actions = "Notification-module--actions--RTiqx";
export var messageWrapper = "Notification-module--messageWrapper--bC-Sl";
export var message = "Notification-module--message--F8rRi";
export var container = "Notification-module--container--cDbpc";
export var bcCartHeader = "Notification-module--bc-cart-header--vSLFH";