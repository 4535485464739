// extracted by mini-css-extract-plugin
export var layout = "ProductQuickView-module--layout--4FWFm";
export var drawerPaper = "ProductQuickView-module--drawerPaper--R6YBz";
export var drawerWrapp = "ProductQuickView-module--drawerWrapp--tCuhX";
export var head = "ProductQuickView-module--head--k+Dhr";
export var container = "ProductQuickView-module--container--SlyQS";
export var colThumbnails = "ProductQuickView-module--colThumbnails--N4e1s";
export var middleSide = "ProductQuickView-module--middleSide--2j4XQ";
export var rightSide = "ProductQuickView-module--rightSide--CupGu";
export var closeIcon = "ProductQuickView-module--closeIcon--Z0qjB";
export var productDetailsWrapp = "ProductQuickView-module--productDetailsWrapp--guvDY";
export var productPage = "ProductQuickView-module--productPage--5L82p";
export var productTop = "ProductQuickView-module--productTop--iw6Ta";
export var productTopEntry = "ProductQuickView-module--productTopEntry--0OgKt";
export var productTopCol = "ProductQuickView-module--productTopCol---dgXH";
export var colImages = "ProductQuickView-module--colImages--X1xnN";
export var colDetails = "ProductQuickView-module--colDetails--uDxAk";
export var productImageThumb = "ProductQuickView-module--productImageThumb--qC1nz";
export var productCategories = "ProductQuickView-module--productCategories--HWzPD";
export var productDetails = "ProductQuickView-module--productDetails--vJKfA";
export var productPrice = "ProductQuickView-module--productPrice--2YVhW";
export var priceDetails = "ProductQuickView-module--priceDetails--fUNM6";
export var productQtyFav = "ProductQuickView-module--productQtyFav--Ij6LV";
export var qtyMinus = "ProductQuickView-module--qtyMinus--6frWf";
export var qtyPlus = "ProductQuickView-module--qtyPlus--MC1Jr";
export var addToCartButton = "ProductQuickView-module--addToCartButton--18jw8";
export var productMessage = "ProductQuickView-module--productMessage--VDIyf";
export var mobileSlider = "ProductQuickView-module--mobileSlider--HGFEG";
export var mainSliderImage = "ProductQuickView-module--mainSliderImage--mCujw";
export var productWishlistWrapp = "ProductQuickView-module--productWishlistWrapp--UImt9";
export var mainSliderImageWrapp = "ProductQuickView-module--mainSliderImageWrapp--sFuZ8";
export var slickThumb = "ProductQuickView-module--slickThumb--hIraj";