import React, { useEffect } from 'react';
import { has } from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import { trackRV } from '../../../helpers/general';
import Container from '../../atoms/Container/Container';
import Icon from '../../atoms/Icon/Icon';
import Slider from '../../atoms/Slider/Slider';

import './ProductQuickView.css';

import * as styles from './ProductQuickView.module.css';
import ProductImages from '../ProductImages/ProductImages';
import ProductDetails from '../ProductDetails/ProductDetails';

const ProductQuickView = ({ open, data, onTouchEnd }) => {
  const images = data.images && has(data.images, 'edges')
    ? data.images.edges.sort(( a ) => a.node.is_thumbnail ? -1 : 1)
    : data.images && has(data, 'images')
    ? data.images.sort(( a ) => a.is_thumbnail ? -1 : 1)
    : data.defaultImage && has(data, 'defaultImage')
    ? [{ url_thumbnail: data.defaultImage.url }]
    : [];

  useEffect(() => {
    if (data && 'entityId' in data && open) {
      trackRV(data.entityId);
    }
  }, [open, data]);

  const sliderSettings = {
    customPaging: function(i) {
      return (
        <a className={styles.slickThumb} href={`#mobile-product-thumb-${i}`}>
          <img
            loading="lazy"
            src={
              has(images[i], 'node')
                ? images[i].node.url
                : images[i].url_thumbnail
            }
            alt={images[i].description}
          />
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const ProductMobileSlider = () => {
    return (
      <div className="product-quickview-slider">
        <Slider {...sliderSettings}>
          {images &&
            images?.map((img, index) => (
              <div key={index} className={styles.mainSliderImageWrapp}>
                <img
                  loading="lazy"
                  src={has(img, 'node') ? img.node.url : img.url_thumbnail}
                  alt={img.description}
                  className={styles.mainSliderImage}
                />
              </div>
            ))}
        </Slider>
      </div>
    );
  };

  if (!open) {
    return null;
  }

  return (
    <Drawer
      width="100vw"
      anchor="bottom"
      open={open}
      onClose={onTouchEnd}
      className="product-quickview__drawer"
      transitionDuration={{ enter: 1000, exit: 1000 }}
      classes={{ paper: styles.drawerPaper }}
    >
      <div className={styles.drawerWrapp} id="scroll-ref">
        <div className={styles.head}>
          <span
            role="presentation"
            className={styles.closeIcon}
            onClick={onTouchEnd}
          >
            <Icon symbol="close" />
          </span>
        </div>

        <Container size="large">
          <div className={styles.layout}>
            <div
              className={styles.container}
              data-img-count={images ? images?.length || 0 : 0}
            >
              <div className={styles.mobileSlider}>
                <ProductMobileSlider />
              </div>
              <div
                className={`${styles.productTopCol} ${styles.colThumbnails}`}
              >
                <div>
                  <ProductImages images={data.images} identifier="quickview-product-images" type="thumbnails" />
                </div>
              </div>
              <div className={styles.middleSide}>
                <div className={`${styles.productTopCol} ${styles.colImages}`}>
                  <ProductImages images={images} identifier="quickview-product-images" type="gallery" />
                </div>
              </div>
              <div className={styles.rightSide}>
                <div className={`${styles.productTopCol} ${styles.colDetails}`}>
                  <div className="productDetails">
                    <ProductDetails product={data} type="summary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Drawer>
  );
};

export default ProductQuickView;
