import React from 'react'

const Logo = props => (
    <svg width="99" height="72" viewBox="0 0 99 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M37.0129 31.7777H25.0695L18.4124 23.8136H40.7116L47.304 29.4722V56.9326L35.0947 41.8594H40.8188V35.4413L37.0129 31.7777Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M61.2416 31.7777H73.185L79.8444 23.8136H57.5385L50.9573 29.4722V56.9326L63.1554 41.8594H57.4327V35.4413L61.2416 31.7777Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.0666 20.792H87.1778L49.0905 64.6116L11.0666 20.792ZM0.455322 15.9473L49.0876 72L97.8032 15.9473H0.455322Z" fill="white"/>
<path d="M27.1767 8.39185C27.1767 9.07842 26.7939 9.42096 26.0292 9.42096H18.8068C18.0413 9.42096 17.6623 9.07842 17.6623 8.39185V3.89269C17.6623 3.21134 18.0413 2.8688 18.8068 2.8688H26.0292C26.7939 2.8688 27.1767 3.21134 27.1767 3.89269V8.39185ZM28.8693 1.10845C28.0919 0.371248 27.1447 -0.000330925 26.0292 -0.000330925H19.4204H18.8068H0V12.2871H2.8669V7.57795H10.7624L12.4788 4.71031H2.8669V2.8688H14.9205C14.8379 3.18974 14.7924 3.5293 14.7924 3.88971V8.40079C14.7924 9.51329 15.1826 10.4381 15.96 11.1783C16.7412 11.9163 17.6899 12.2871 18.8068 12.2871H26.0292C27.1447 12.2871 28.0919 11.9163 28.8693 11.1783C29.6534 10.4381 30.0436 9.51329 30.0436 8.40079V3.88971C30.0436 2.77497 29.6534 1.84863 28.8693 1.10845Z" fill="white"/>
<path d="M46.4139 12.2891H42.4896L36.3477 5.40479H43.7347C44.2596 5.40479 44.5232 4.98183 44.5232 4.13814C44.5232 3.29222 44.2596 2.87075 43.7347 2.87075H34.8443V12.2891H31.9707V0.0016222H43.9007C45.0006 0.0016222 45.8711 0.435007 46.5055 1.29284C47.099 2.07472 47.3924 3.02192 47.3924 4.13814C47.3924 5.25214 47.099 6.20231 46.5055 6.98195C45.8711 7.84202 45.0006 8.27169 43.9007 8.27169H42.8024L46.4139 12.2891Z" fill="white"/>
<path d="M62.8543 12.2891H53.3355C52.223 12.2891 51.2728 11.9182 50.4917 11.1803C49.7113 10.4401 49.3196 9.5145 49.3196 8.402V3.89166C49.3196 2.77693 49.7113 1.84984 50.4917 1.10966C51.2728 0.372458 52.223 0.0016222 53.3355 0.0016222H62.8543V2.87075H53.3355C52.5729 2.87075 52.1909 3.21329 52.1909 3.89464V8.39381C52.1909 9.07963 52.5729 9.42217 53.3355 9.42217H62.8543V12.2891Z" fill="white"/>
<path d="M67.6466 12.2891H64.7827V0.00161457H67.6466V12.2891Z" fill="white"/>
<path d="M83.2043 2.8688H77.6575V12.2871H74.7876V2.8688H69.2422V-0.000330925H83.2043V2.8688Z" fill="white"/>
<path d="M98.2585 2.8688H88.001V4.71031H97.5883V7.57795H88.001V9.42096H98.2339V12.2871H85.1311V-0.000330925H98.2585V2.8688Z" fill="white"/>
</svg>

)

export default Logo