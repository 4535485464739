// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--KlZLs";
export var footerWrapper = "Footer-module--footerWrapper--YBhTa";
export var footerWrapperFlex = "Footer-module--footerWrapperFlex--qSL7H";
export var footerLogo = "Footer-module--footerLogo--NA+GM";
export var footerListStyle = "Footer-module--footerListStyle--VPnhP";
export var footerNavigation = "Footer-module--footerNavigation--MyCNP";
export var navigationMainItem = "Footer-module--navigationMainItem--0C27q";
export var footerSubscribe = "Footer-module--footerSubscribe--S6Xx0";
export var newsletterInfo = "Footer-module--newsletterInfo--ttuuO";
export var footerSubscribeForm = "Footer-module--footerSubscribeForm--Z58oh";
export var footerAgreement = "Footer-module--footerAgreement--+9hAT";
export var footerSocials = "Footer-module--footerSocials--szN2U";
export var footerPolicies = "Footer-module--footerPolicies--b0cX5";
export var copyrightLine = "Footer-module--copyrightLine--LHvPt";
export var footerSubscribePolicies = "Footer-module--footerSubscribePolicies--Vklcz";
export var navigationSecondaryItems = "Footer-module--navigationSecondaryItems--sxjym";