import React from 'react'

const User = () => (
    <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12.8396 5.73092C12.8396 8.18161 10.8521 10.1679 8.39978 10.1679C5.94749 10.1679 3.95991 8.18161 3.95991 5.73092C3.95991 3.28023 5.94749 1.29395 8.39978 1.29395C10.8521 1.29395 12.8396 3.28023 12.8396 5.73092Z" stroke="white" strokeWidth="2" strokeLinecap="square"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.7996 21.9998H1C1 20.949 1 19.9493 1 19.044C1 16.5919 2.9878 14.6049 5.43987 14.6049H11.3597C13.8118 14.6049 15.7996 16.5919 15.7996 19.044C15.7996 19.9493 15.7996 20.949 15.7996 21.9998Z" stroke="white" strokeWidth="2" strokeLinecap="square"/>
</svg>


)

export default User