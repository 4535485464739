// extracted by mini-css-extract-plugin
export var root = "SiteMenu-module--root--qNYa8";
export var backLink = "SiteMenu-module--backLink--lT6Hl";
export var fauxLink = "SiteMenu-module--fauxLink--1OjI1";
export var subnav = "SiteMenu-module--subnav--ZDb6t";
export var isActive = "SiteMenu-module--isActive--E33Jp";
export var level2 = "SiteMenu-module--level2--Qz4nn";
export var parentLabel = "SiteMenu-module--parentLabel--CgA8f";
export var duplicate = "SiteMenu-module--duplicate--kvEe5";
export var shadow = "SiteMenu-module--shadow--ebOWM";
export var imageGrid = "SiteMenu-module--imageGrid--VV3nH";
export var level3 = "SiteMenu-module--level3--q+yd+";
export var level4 = "SiteMenu-module--level4--lJPD3";