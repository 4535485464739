import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';
import CartContext from '../../../context/CartProvider';
import ContentfulContext from '../../../context/ContentfulProvider';
import { bcApi } from '../../../helpers/bigcommerce';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import Button from '../../atoms/Button/Button';
import * as styles from './CartSummary.module.css';
import Dialog from "../../atoms/Dialog/Dialog"
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

const CouponForm = () => {
  const {
    state: {
      coupons,
      cart: { currency, lineItems }
    },
    addCoupons,
    removeCoupons,
    initCheckout
  } = useContext(CartContext);

  const [initLoad, setInitLoad] = useState(true);
  const [applying, setApply] = useState(false);
  const [couponcode, setCouponcode] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [assignedCoupon, setAssignedCoupon] = useState([]);
  const [dialogMessage, setDialogMessage] = useState(false);

  const contentful = useContext(ContentfulContext);
  const translations = get(contentful, 'translationData', {});

  const onClickToggle = () => {
    setShowForm(!showForm);
  };

  const onChangeValue = e => {
    const { value } = e.target;
    setCouponcode(value);
  };

  const splitCouponCalculation = useCallback((__coupons, __lineItems) => {
    // console.log("lineItems", __lineItems);
    const _coupons = [...__coupons];
    if (_coupons.length > 0 && __lineItems) {
      _coupons[0].splitValues = [];
      const loop = Object.keys(__lineItems).map(itemType => {
        return __lineItems[itemType].map(async item => {
          if (item.coupon_amount > 0) {
            const prodData = await bcApi(`catalog/products/${item.product_id}`);
            const taxCalc = prodData?.response?.data?.tax_class_id !== 1 && prodData?.response?.data?.product_tax_code !== 'taxFree';
            _coupons[0].splitValues.push({taxCalc, amount: item.coupon_amount});
          }
          return true;
        });
      });
      
      return Promise.all(loop).then(() => {
        setAssignedCoupon(_coupons);
        return _coupons;
      });
    } else {
      setAssignedCoupon(_coupons);
      return _coupons;
    }
  }, []);

  const onSubmitForm = () => {
    setApply(true);
    addCoupons(couponcode, true).then(response => {
      // console.log("response: ", response);
      splitCouponCalculation(response.data.coupons, response.data.cart.line_items);
      initCheckout();
      setApply(false);
    }).catch(response => {
      // console.log("then: ", response);
      setApply(false);
      setDialogMessage(response.title);
    });
  };

  const handleRemovecCoupon = couponcode => () => {
    setApply(true);
    removeCoupons(couponcode).then(() => {
      setAssignedCoupon([]);
      initCheckout();
      setApply(false);
    });
  };

  const clearDialog = () => {
    setDialogMessage(false);
  }

  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
      
      splitCouponCalculation(coupons, lineItems);
    }
  }, [initLoad, coupons, assignedCoupon, lineItems, splitCouponCalculation]);

  return (
    <>
      {isEmpty(assignedCoupon) ? (
        <div className="flex-between">
          <span className={styles.bcCartSummaryItemLabel}>
            <ContentfulTranslationText keyName="addDiscountCode">
              Add discount code
            </ContentfulTranslationText>
          </span>
          <span
            data-button
            role="presentation"
            onClick={onClickToggle}
            className={styles.bcCartSummaryToggle}
          >
            {showForm ? (
              <ContentfulTranslationText keyName="cancel">
                Cancel
              </ContentfulTranslationText>
            ) : (
              <ContentfulTranslationText keyName="addCode">
                Add code
              </ContentfulTranslationText>
            )}
          </span>
        </div>
      ) : (
        assignedCoupon.map(coupon => (
          <div className="flex-between" key={coupon.code}>
            <div className={styles.bcCartSummaryItemLabel}>
              <div>{coupon.name || (<><ContentfulTranslationText keyName="coupon">Coupon</ContentfulTranslationText> ({coupon.code})</>)}</div>
              <span
                data-button
                role="presentation"
                onClick={handleRemovecCoupon(coupon.code)}
                className={styles.bcCartSummaryToggle}
              >
                {applying ? (<><ContentfulTranslationText keyName="removing">Removing</ContentfulTranslationText>...</>) : (<ContentfulTranslationText keyName="remove">Remove</ContentfulTranslationText>)}
              </span>
            </div>
            <span className={styles.bcCartSummaryItemValue}>
              <CurrencyFormatter
                currency={currency.code}
                amount={coupon.splitValues.length > 0 ? coupon.splitValues : coupon.discounted_amount}
              />
            </span>
          </div>
        ))
      )}
      {isEmpty(assignedCoupon) && showForm && (
        <div className="grid-gap20 grid-7525 pt-4">
          <div className="formField mb-0">
            <input
              type="text"
              name="couponcode"
              placeholder={translations.enterCoupon ? translations.enterCoupon.title : "Enter your coupon code"}
              value={couponcode || ''}
              onChange={onChangeValue}
            />
          </div>
          <Button
            type="button"
            level="primary"
            size="smallest"
            disabled={applying}
            onClick={onSubmitForm}
          >
            {applying ? (<><ContentfulTranslationText keyName="applying">Applying</ContentfulTranslationText>...</>) : (<ContentfulTranslationText keyName="apply">Apply</ContentfulTranslationText>)}
          </Button>
        </div>
      )}
      <Dialog open={dialogMessage ? true : false} title={(<ContentfulTranslationText keyName="applyingCoupon">Applying coupon</ContentfulTranslationText>)} size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>{dialogMessage}</Dialog>
    </>
  );
};

export default CouponForm;
