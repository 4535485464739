import React, { useContext, useMemo } from "react"
import { Link } from "gatsby"
import { handleLink } from "../../../helpers/general";
import ContentfulContext from '../../../context/ContentfulProvider';
import NavSocialMedia from '../../molecules/NavSocialMedia/NavSocialMedia'
import SubscribeForm from '../../molecules/SubscribeForm/SubscribeForm';
import Container from '../../atoms/Container/Container'
import Icon from "../../atoms/Icon/Icon"
// import Button from '../../atoms/Button/Button'
import footerBg from './footer_bg.jpg'
import award from './award_img.png'

import * as styles from './Footer.module.css'
import ContentfulTranslationText from "../../atoms/ContentfulTranslationText/ContentfulTranslationText";

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.
// TODO: Build out molecule/atom based components for below elements

const Footer = () => {
  const contentful = useContext(ContentfulContext);
  const menuData = useMemo(() => (contentful && contentful.footerMenu.map(item => {
    return {
      "menuLabel": item.title,
      "menuLink": item.url || item.content.slug,
      "target": item.linkTarget === 'New Tab',
      "location": item.linkLocation === 'External',
      "submenu": null
    };
  })) || [], [contentful]);

  const secondaryMenuData = useMemo(() => (contentful && contentful.secondaryFooterMenu.map(item => {
    return {
      "menuLabel": item.title,
      "menuLink": item.url || item.content.slug,
      "target": item.linkTarget === 'New Tab',
      "location": item.linkLocation === 'External',
      "submenu": null
    };
  })) || [], [contentful]);

  const footerLink = (menuType, menuItem, key) => {
    if (menuItem.menuLink && menuItem.menuLink !== '' && !menuItem.target && !menuItem.location) {
      return <li key={key}><Link to={handleLink(menuItem.menuLink)}>{menuItem.menuLabel}{menuType === 'secondary' && ( <Icon symbol="arrowTopRight" />)}</Link></li>
    } else if (menuItem.menuLink && menuItem.menuLink !== '') {
      return (
        <li key={key}>
          <a href={handleLink(menuItem.menuLink)} title={menuItem.menuLabel} target={menuItem.target ? '_blank' : ''} rel="noreferrer" onClick={(e) => linkTo(e, handleLink(menuItem.menuLink))}>
            {menuItem.menuLabel}{menuType === 'secondary' && ( <Icon symbol="arrowTopRight" />)}
          </a>
        </li>
      )
    }

    return null;
  }

  const linkTo = (e, link) => {
    e.preventDefault();
    /* Force page to reload to link in the event a link with only filter changes is clicked */
    if (typeof window !== "undefined") {
      window.location = link;
    }
  }

  return (
    <footer className={styles.footer} style={{ backgroundImage: `url(${footerBg})` }}>
      <Container size="large">
        <div className={styles.footerWrapper}>
          <div>
            <div className={styles.footerLogo}>
              <Icon symbol="forciteLogo" />
            </div>

            <div className={styles.footerWrapperFlex}>
              <div className={styles.footerNavigation}>
                <ul className={`${styles.footerListStyle} ${styles.navigationMainItem}`}>
                  {menuData.map((menuItem, menuItemKey) => footerLink('primary', menuItem, menuItemKey))}
                </ul>

                <ul className={`${styles.footerListStyle} ${styles.navigationSecondaryItems}`}>
                  {secondaryMenuData.map((menuItem, menuItemKey) => footerLink('secondary', menuItem, menuItemKey))}
                </ul> 
              </div>

              <div className={styles.footerSubscribePolicies}>
                <div className={styles.footerSubscribe}>
                  <SubscribeForm />

                  <div className={`${styles.footerWrapperFlex} ${styles.footerAgreement}`}>
                    <ContentfulTranslationText keyName="footerSubscribeTerms">
                      <p>By signing up you are agreeing to the Forcite Terms of Service and Privacy Policy. You may unsubscribe at any time.</p>
                    </ContentfulTranslationText>
                    
                    <div className={styles.footerSocials}><NavSocialMedia /></div>
                  </div>
                  
                </div>

                <img src={award} alt="" />
                
              </div>
            </div>


            <div className={styles.footerPolicies}>
              <p className={styles.copyrightLine}>© Forcite Helmet Systems Pty Ltd.</p>

              <ul className={styles.footerListStyle}>
                <li><Link to={handleLink("/privacy/")}><ContentfulTranslationText keyName="privacy">Privacy</ContentfulTranslationText></Link></li>
                <li><Link to={handleLink("/policy/")}><ContentfulTranslationText keyName="policy">Policy</ContentfulTranslationText></Link></li>
              </ul>
            </div>
              

          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
