import React from 'react'

const featureCompactDesign = () => (
    <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="16" y="17" width="20" height="20" stroke="currentColor" strokeWidth="2"/>
        <path d="M26.1354 39.8528L26 39.7282L25.8646 39.8528L25.3916 40.2881L20.8646 44.4539L20.7046 44.601L20.8646 44.7482L21.3375 45.1834L21.473 45.3081L21.6084 45.1834L25.4622 41.6372V52V52.2H25.6622H26.3378H26.5378V52V41.6372L30.3916 45.1834L30.527 45.3081L30.6625 45.1834L31.1354 44.7482L31.2954 44.601L31.1354 44.4539L26.1354 39.8528Z" fill="currentColor" stroke="currentColor" strokeWidth="0.4"/>
        <path d="M26.1354 13.1472L26 13.2718L25.8646 13.1472L25.3916 12.7119L20.8646 8.54613L20.7046 8.39896L20.8646 8.25179L21.3375 7.81656L21.473 7.69194L21.6084 7.81656L25.4622 11.3628V1V0.8H25.6622L26.3378 0.8H26.5378V1V11.3628L30.3916 7.81656L30.527 7.69194L30.6625 7.81656L31.1354 8.25179L31.2954 8.39896L31.1354 8.54613L26.1354 13.1472Z" fill="currentColor" stroke="currentColor" strokeWidth="0.4"/>
        <path d="M13.1472 26.8646L13.2718 27L13.1472 27.1354L12.7119 27.6084L8.54613 32.1354L8.39896 32.2954L8.25179 32.1354L7.81656 31.6625L7.69194 31.527L7.81656 31.3916L11.3628 27.5378H1H0.8V27.3378V26.6622V26.4622H1H11.3628L7.81656 22.6084L7.69194 22.473L7.81656 22.3375L8.25179 21.8646L8.39896 21.7046L8.54613 21.8646L13.1472 26.8646Z" fill="currentColor" stroke="currentColor" strokeWidth="0.4"/>
        <path d="M38.8528 27.1354L38.7282 27L38.8528 26.8646L39.2881 26.3916L43.4539 21.8646L43.601 21.7046L43.7482 21.8646L44.1834 22.3375L44.3081 22.473L44.1834 22.6084L40.6372 26.4622H51H51.2V26.6622V27.3378V27.5378H51H40.6372L44.1834 31.3916L44.3081 31.527L44.1834 31.6625L43.7482 32.1354L43.601 32.2954L43.4539 32.1354L38.8528 27.1354Z" fill="currentColor" stroke="currentColor" strokeWidth="0.4"/>
    </svg>
)

export default featureCompactDesign
