import React, { useEffect, useState } from 'react';
import Dialog from '../Dialog/Dialog';
import YouTube from 'react-youtube';

function PdpHighlightVideo({ data, styles, labelOverride }) {
    const [openModal, setOpenModal] = useState(false);
    const [videoObj, setVideoObj] = useState(null);
    const [label, setLabel] = useState(null);

    const initVideo = event => {
        setVideoObj(event.target);
    }

    const triggerModal = modal => {
        setOpenModal(modal);
        if (modal) {
            videoObj.playVideo();
        } else {
            videoObj.pauseVideo();
        }
    }

    useEffect(() => {
        if (labelOverride) {
            setLabel(labelOverride);
        }
    }, [labelOverride])

    if (data && data.indexOf('youtube.com') > -1) {
        const youTubeId = data.split('?v=')[1];
        
        return (
            <>
                {data && (
                    <>
                        <div className={styles.productHighlightVideo}>
                            <span onClick={() => triggerModal(!openModal)}
                                onKeyDown={() => {}}
                                role="button"
                                tabIndex="0"
                                data-video-highlight>
                                {label ? label : 'Watch Highlights Here'}
                            </span>
                        </div>
                        <Dialog open={openModal} size="md" hideBtnClose hideBtnCancel hideBtnOk onCancel={() => {triggerModal(false);}}>
                            <div className={styles.modalContainer}>
                                <YouTube videoId={youTubeId} opts={{playerVars: {modestbranding: 1, origin: process.env.SITE_URL}}} onReady={(e) => initVideo(e)} />
                            </div>
                        </Dialog>
                    </>
                )}
            </>
        )
    }

    return null;
}

export default PdpHighlightVideo