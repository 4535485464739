import React from 'react';

import CarouselWithCards from './widgets/CarouselWithCards';
import ProductRating from './widgets/ProductRating';
import ProductRichSnippets from './widgets/ProductRichSnippets';
import ProductExpertAnswers from './widgets/ExpertAnswers';

const ReviewsIoWidget = ({ sku, type, multiple }) => {
    if (type === 'carousel') {
        return <CarouselWithCards sku={sku} />
    } else if (type === 'rating') {
        return <ProductRating sku={sku} />
    } else if (type === 'richSnippets') {
        return <ProductRichSnippets sku={sku} multiple={multiple} />
    } else if (type === 'expertAnswers') {
        return <ProductExpertAnswers sku={sku} />
    }
}

export default ReviewsIoWidget;
