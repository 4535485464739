import { bcApi } from '../../../../helpers/bigcommerce'

const BigCommerceSearch = (searchTerm, limit) => {

    return new Promise((res) => {
        const url = new URL('catalog/products', 'https://placehold/')
        url.searchParams.set('keyword', searchTerm)
        url.searchParams.set('keyword_context', 'shopper')
        url.searchParams.set('include', 'images,variants')
        url.searchParams.set('limit', (limit || 4))
        bcApi(`catalog/products${url.search}`).then(response => {
            if (response.status === 200 && 'data' in response.response) {
                const products = response.response.data.map(product => {
                    product.search_item_type = "product"
                    return product
                });

                res({products})
            } else {
                res({products: []})
            }
        })
    })
}

export default BigCommerceSearch