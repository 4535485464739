import React, { useContext, useMemo } from 'react';
import ContentfulContext from '../../../context/ContentfulProvider';
import ContentfulRichText from '../ContentfulRichText/ContentfulRichText';

const ContentfulTranslationText = ({ keyName, dynamicData = {}, children }) => {
  const contentful = useContext(ContentfulContext);

  const contentObj = useMemo(() => (contentful && (contentful.translationData[keyName] || false)) || false, [keyName, contentful]);
  
  const replaceDynamicValues = (content) => {
    let newContent = content;
    Object.keys(dynamicData).map(dynamicKey => {
      newContent = newContent.replace(`#${dynamicKey}#`, dynamicData[dynamicKey]);
      return true;
    });
    return newContent;
  }

  return (
      <>
          {contentObj ? (
              <>
                {(contentObj.title && contentObj.title !== '') && (<>{replaceDynamicValues(contentObj.title)}</>)}
                {contentObj.content && <ContentfulRichText raw={replaceDynamicValues(contentObj.content.raw)} />}
              </>
          ) : (<>{children}</>)}
      </>
  )
};

export default ContentfulTranslationText;