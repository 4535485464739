import React from "react"
import Slick from "react-slick";
import Icon from '../Icon/Icon';

import './slick.css'
import './slick-theme.css'
import * as styles from './slick.module.css';

// https://github.com/akiran/react-slick/issues/623
function SliderNextArrow(props) {
    const { onClick } = props;
    return (
      <div className={`${styles.arrowSlider} ${styles.arrowNext} slick-arrow slick-next`} role='presentation' onClick={onClick}>
        <Icon symbol="arrowRight" />
      </div>
    );
  }
  
  function SliderPrevArrow(props) {
    const { onClick } = props;
    return (
      <div className={`${styles.arrowSlider} ${styles.arrowPrev} slick-arrow slick-prev`} role='presentation' onClick={onClick}>
        <Icon symbol="arrowLeft" />
      </div>
    );
  }

const Slider = props => {
    const { children, sliderRef } = props;
    const defaultSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />
    }

    const settings = { ...defaultSettings, ...props }
    return (
        <Slick ref={sliderRef !== undefined ? sliderRef : undefined} {...settings}>
            { children }
        </Slick>
    )
}

export default Slider