import React, { useContext, useEffect } from 'react';
import CartContext from '../../../context/CartProvider';
import Icon from '../../atoms/Icon/Icon'
import Button from '../../atoms/Button/Button'
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import { dataLayerPush, handleLink } from '../../../helpers/general';

import * as styles from './Notification.module.css';

// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const NotificationOutput = () => {
  const value = useContext(CartContext);
  const notifications = value && value.notifications;
  const hasNotifications = Array.isArray(notifications) && notifications.length;

  return hasNotifications ? (
    <section className={styles.notify}>
      {notifications.map(note => (
        <Notification key={note.id} {...note} />
      ))}
    </section>
  ) : null;
};

const Notification = ({ id, text, type }) => {
  const value = useContext(CartContext);
  const removeNotification = value && value.removeNotification;
  useEffect(() => {
    const timer = setTimeout(() => {
      removeNotification(id);
    }, 7000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  return (
    <article className={`${styles.notification} ${styles.animate}`}>
      <div className={styles.icon} role="presentation" onClick={() => removeNotification(id)}>
        <Icon symbol="close" />
      </div>

      <div className={styles.content}>
        <div className={styles.messageWrapper}>
          <span className={styles.productName}>
            {text}
            {type === 'cartCleared' && (<ContentfulTranslationText keyName="cartCleared">Your cart has been cleared as it was for this region.</ContentfulTranslationText>)}
          </span>
          {type === 'cartAdd' && (
            <p><ContentfulTranslationText keyName="addedNotification">was just added to your bag</ContentfulTranslationText>.</p>
          )}
          {type === 'cartCleared' && (
            <p><ContentfulTranslationText keyName="pageReload">Page will reload shortly</ContentfulTranslationText>.</p>
          )}
        </div>
        
        {type === 'cartAdd' && (
          <div className={styles.actions}>
            <Button href={handleLink("/confirm-order/")} level="secondary" onClick={(e) => {
              e.preventDefault(); 
              dataLayerPush('View Cart', {name: 'Notification + View cart', content: 'View cart button'}, null, handleLink('/confirm-order/'), true);
              removeNotification(id)
            }}><ContentfulTranslationText keyName="viewBag">View bag</ContentfulTranslationText></Button>
            {/* <Button href={value.state.cart.redirectUrls.checkout_url} level="primary" >Proceed to Checkout</Button> */}
          </div>
        )}

        {type === 'cartCleared' && (
          <div className={styles.actions}>
            <Button type="button" level="secondary" onClick={(e) => {
              e.preventDefault(); 
              window.location.reload();
              removeNotification(id)
            }}><ContentfulTranslationText keyName="reload">Reload</ContentfulTranslationText></Button>
          </div>
        )}
      </div>
    </article>
  );
};

export default NotificationOutput;