import React, { useContext, useMemo } from "react"
import { get } from "lodash";
import ContentfulContext from '../../../context/ContentfulProvider';
import ContentfulTranslationText from "../../atoms/ContentfulTranslationText/ContentfulTranslationText";
import KlaviyoForm from "../../atoms/KlaviyoForm/KlaviyoForm"
import * as styles from './SubscribeForm.module.css'

const SubscribeForm = () => {
    const contentful = useContext(ContentfulContext);
    
    const listId = 'UmRkRq';
    const contentOverrides = useMemo(() => ({
        thankYouMsg: (contentful && contentful.translationData && get(contentful.translationData, 'footerSubscribeThankYou.title')) || "Thank you for subscribing",
        alreadySubMsg: (contentful && contentful.translationData && get(contentful.translationData, 'footerSubscribeSubscribed.title')) || "Email already subscribed",
        dlEventName: 'Newsletter Submission',
        dlEventDataName: 'Footer Embed Newsletter Submission',
        dlEventDataContent: 'Newsletter Sign Up >> Footer'
    }), [contentful]);

    return (
        <div className={`${styles.footerListRow}`}>
            <div className={styles.subscribeDesc}>
                <h5 className={styles.footerListHeading}>
                    <ContentfulTranslationText keyName="footerSubscribeTitle">Subscribe to newsletter</ContentfulTranslationText>
                </h5>
                <ContentfulTranslationText keyName="footerSubscribeDetail">
                    <p>Receive exclusive information about new offers, events, and product batches</p>
                </ContentfulTranslationText>
            </div>

            <div className={`${styles.subscribeForm}`}>
                {/* <form onSubmit={submitHandler}>
                    <div className={`${styles.subscribeFields} ${submitSuccessful ? 'hide' : 'show'}`}>
                        <input className={styles.subscribeInput} placeholder="Email" type="text" id="subscribe" onChange={(e) => setFieldValue('email', e.target.value)} />
                        <button level="primary" type="buttonSubmit" aria-label="submit-subscription"><Icon symbol="subscribeArrowRight" /></button>
                    </div>
                    <div className={`${styles.subscribeSuccess} ${submitSuccessful ? 'show' : 'hide'}`}>
                        <p className={`mb-0 ${styles.footerListHeading}`}>{message}</p>
                    </div>
                </form> */}
                <KlaviyoForm listId={listId} contentOverrides={contentOverrides} />
            </div>
        </div>
    );
}
 
export default SubscribeForm;