import React from 'react'
import { dataLayerPush, handleLink } from '../../../helpers/general';
import Button from '../Button/Button';

const ContentfulButton = props => {
    const buttonOptions = {
        href: props.ctaUrl
    };

    switch (props.buttonStyle) {
        case 'Primary':
            buttonOptions.level = 'primary';
            break;

        case 'Secondary Solid':
            buttonOptions.level = 'secondary';
            break;

        case 'Secondary Outline':
            buttonOptions.level = 'secondary';
            buttonOptions.alternate = true;
            break;

        default:
            break;
    }

    if (props.turnOnEventTracking) {
        buttonOptions.onClick = (e) => {e.preventDefault(); dataLayerPush(props.eventTrackingKey, {name: props.eventTrackingName, content: props.eventTrackingContent}, null, handleLink(props.ctaUrl))}
    }

    if (props.ctaAsset) {
        buttonOptions.imageLink = true;
    }

    return (
        <Button {...buttonOptions}>
            {props.ctaAsset ? (
                <img loading="lazy" src={props.ctaAsset.file.url} alt={props.ctaLabel || props.name} />
            ) : (
                <>{props.ctaLabel}</>
            )}
        </Button>
    )
    // if (props.ctaUrl.startsWith('http')) {
    //     return <a href={props.ctaUrl} target="_blank" rel="noopener noreferrer nofollow"
    //         onClick={props.turnOnEventTracking ? (e) => {e.preventDefault(); dataLayerPush(props.eventTrackingKey, {name: props.eventTrackingName, content: props.eventTrackingContent}, null, props.ctaUrl)} : null}>
    //         {props.ctaAsset ? (
    //             <img loading="lazy" src={props.ctaAsset.file.url} alt={props.ctaLabel || props.name} />
    //         ) : (
    //             <>{props.ctaLabel}</>
    //         )}
    //     </a>
    // } else {
    //     return <Link to={props.ctaUrl} 
    //         onClick={props.turnOnEventTracking ? (e) => {e.preventDefault(); dataLayerPush(props.eventTrackingKey, {name: props.eventTrackingName, content: props.eventTrackingContent}, null, props.ctaUrl)} : null}>
    //         {props.ctaAsset ? (
    //             <img loading="lazy" src={props.ctaAsset.file.url} alt={props.ctaLabel || props.name} />
    //         ) : (
    //             <>{props.ctaLabel}</>
    //         )}
    //     </Link>
    // }
}

export default ContentfulButton;