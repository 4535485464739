import React from 'react';
import { Helmet } from "react-helmet"
import { scroller } from 'react-scroll';
// import Stars from '../../../atoms/Stars/Stars';
import { useScript } from '../../../../helpers/general'
import ContentfulTranslationText from '../../ContentfulTranslationText/ContentfulTranslationText';

const ProductRating = ({ sku }) => {
    useScript('https://widget.reviews.io/rating-snippet/dist.js?_t=2021071121', 'ratingSnippet', () => {    
        if (typeof document !== 'undefined') {
            const exists = document.getElementById('reviewsRatingScript');
            if (!exists) {
                const script = document.createElement('script');
                script.id = 'reviewsRatingScript';
                script.innerText = `ratingSnippet("ruk_rating_snippet",{store: "forcite-helmets",color: "#FF3503",linebreak: true,usePolaris: true});`;

                document.body.appendChild(script);
            }
        }
    });

    const scoreInterval = setInterval(() => {
        if (typeof document !== 'undefined') {
            const ratingContainer = document.querySelectorAll('[data-rate-score]');
            const exists = document.getElementsByClassName('ruk_rating_snippet');
            if (exists && exists.length > 0) {
                clearInterval(scoreInterval);
                if (exists[0].getAttribute('title')) {
                    const title = exists[0].getAttribute('title');
                    if (ratingContainer && ratingContainer.length > 0 && title.indexOf(" S") > -1) {
                        ratingContainer[0].innerHTML = title.substring(0, title.indexOf(" S"));
                    } else if (ratingContainer && ratingContainer.length > 0) {
                        ratingContainer[0].remove();
                    }
                } else if (ratingContainer[0]) {
                    ratingContainer[0].remove();
                }
            }
        }
    }, 500);

    const scrollToReviews = () => {
        scroller.scrollTo(
            'reviews',
            {
                duration: 800,
                delay: 0,
                offset: -180,
                smooth: 'easeInOutQuart'
            }
        );
    }

    return (
        <>
            <Helmet>
                <script src="https://widget.reviews.io/product/dist.js" async="false"></script>
                <link rel="stylesheet" href="https://widget.reviews.io/rating-snippet/dist.css" />
                <style>
                    {`
                        .ruk_rating_snippet {padding-right: 5px;}
                        .ruk_rating_snippet br {display: none;}
                        .ruk_rating_snippet .ruk-rating-snippet-count {font-family: Poppins, sans-serif;}
                    `}
                </style>
            </Helmet>
            <div className="ruk_rating_snippet" data-sku={sku} onClick={() => scrollToReviews()} role="presentation">
                {/* <Stars stars="0" /> */}
                <span className="ruk-rating-snippet-count">(<ContentfulTranslationText keyName="noProductReviewsNoticeShort">No reviews</ContentfulTranslationText>)</span>
            </div>
        </>
    )
}

export default ProductRating;
