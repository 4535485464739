import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { handleLink } from '../../../helpers/general';
import * as styles from './AccountPageMenu.module.css';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

const AccountPageMenu = ({ isLoggedIn, isHidden }) => {
  const menuItems = useMemo(() => {
    if (!isLoggedIn) {
      return [
        <Link activeClassName={styles.active} to={handleLink("/login")} key="login">
          <ContentfulTranslationText keyName="login">Login</ContentfulTranslationText>
        </Link>,
        <Link activeClassName={styles.active} to={handleLink("/signup")} key="signup">
          <ContentfulTranslationText keyName="signup">Signup</ContentfulTranslationText>
        </Link>
      ];
    }
    return [
      <Link activeClassName={styles.active} to={handleLink("/account/orders")} key="orders">
        <ContentfulTranslationText keyName="orders">Orders</ContentfulTranslationText>
      </Link>,
      // <Link to="/account/#" key="messages">
      //   Messages
      // </Link>,
      // <Link to="/account/wishlists" key="wishlists">
      //   Wishlists
      // </Link>,
      <Link activeClassName={styles.active} to={handleLink("/account/addresses")} key="addresses">
        <ContentfulTranslationText keyName="addresses">Addresses</ContentfulTranslationText>
      </Link>,
      // <Link to="/account/recent-items" key="recent-views">
      //   Recently Viewed
      // </Link>,
      <Link activeClassName={styles.active} to={handleLink("/account/")} key="my-details">
        <ContentfulTranslationText keyName="settings">Settings</ContentfulTranslationText>
      </Link>,
      // <div className={styles.divider} key="divider" />,
      <Link target={'logout'} to={handleLink("/logout")} key="logout">
        <ContentfulTranslationText keyName="logout">Log out</ContentfulTranslationText>
      </Link>
    ];
  }, [isLoggedIn]);

  return (
    <div className={styles.root}>
      {!isHidden && isLoggedIn && (
        <>
          {/* <div className={styles.user}>
            Hi {firstName}!
          </div> */}
          {/* <div className={styles.divider} /> */}
        </>
      )}
      {menuItems}
    </div>
  );
};

export default AccountPageMenu;
