/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React, { useContext, useMemo } from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { JsonLd, Generic } from 'react-structured-data'
 import ContentfulContext from '../../../context/ContentfulProvider';
 import { handleLink } from '../../../helpers/general';

 function Seo({ description, ogDescription, twDescription, lang, meta, title, image, ogTitle, twTitle, children, defaultMicroData = true, microDataType }) {
 
    const contentful = useContext(ContentfulContext);
    const seoData = useMemo(() => (contentful && contentful.seoData) || {}, [contentful]);

    const metaDescription = description || seoData.metaDescription;
    const siteName = "Forcite Helmet Systems"
  
    return (
      <>
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={siteName ? `%s | ${siteName}` : null}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: `${ogTitle || title} | ${siteName}`,
            },
            {
              property: `og:description`,
              content: ogDescription || metaDescription,
            },
            {
              property: `og:image`,
              content: image || seoData.ogImage,
            },
            {
              property: `og:image:width`,
              content: '1600',
            },
            {
              property: `og:image:height`,
              content: '900',
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: '',
            },
            {
              name: `twitter:title`,
              content: `${twTitle || ogTitle || title} | ${siteName}`,
            },
            {
              name: `twitter:description`,
              content: twDescription || ogDescription || metaDescription,
            },
          ].concat(meta)}
        >{children}</Helmet>
  
        {/* GENERAL PAGES */}
        {/* {(defaultMicroData && !microDataType) && (
          // TODO: Throwing an error for some random reason - look further into this
          <JsonLd>
            <Generic type="webpage" jsonldtype="WebPage" schema={{
              name: title, 
              description: metaDescription
            }}/>
          </JsonLd>
        )} */}
  
        {/* HOMEPAGE */}
        {(defaultMicroData && microDataType === 'home') && (
          <JsonLd>
            <Generic type="store" jsonldtype="Store" schema={{
              name: title, 
              url: typeof window !== 'undefined' && window.location.href,
              logo: `${process.env.SITE_URL}/logo.png`,
              telephone: '',
              priceRange: "$$$",
              image: `${process.env.SITE_URL}/logo.png`,
            }}>
              <Generic type="potentialAction" jsonldtype="SearchAction" schema={{
                "target":`${process.env.SITE_URL}${handleLink(`/search/?q={search_term}`)}`,
                "query-input": "required name=search_term"
              }}/>
              <Generic type="address" jsonldtype="PostalAddress" schema={{
                streetAddress: seoData.streetAddress,
                addressLocality: seoData.suburb,
                postalCode: seoData.postcode,
                addressCountry: seoData.country,
              }}/>
            </Generic>
          </JsonLd>
        )}
      </>
   )
 }
 
 Seo.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
 }
 
 Seo.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string,
 }
 
 export default Seo
 