import React from 'react'

const EnglishEuropeanUnion = () => (
    // SVG obtained via https://flagicons.lipis.dev/
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
        <path fill="#039" d="M0 0h640v480H0z"/>
        <g fill="#fc0" transform="translate(320 242.3) scale(23.7037)">
            <g y="-6">
            <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
            </g>
            <g>
                <g transform="rotate(72)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
            </g>
            <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
            </g>
            </g>
            <g y="6">
            <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
            </g>
            <g>
                <g transform="rotate(72)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
            </g>
            <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
            </g>
            </g>
            <g>
            <g x="-6">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(-144 -2.3 -2.1)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(144 -2.1 -2.3)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(72 -4.7 -2)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(72 -5 .5)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            </g>
            <g transform="scale(-1 1)">
            <g x="-6">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(-144 -2.3 -2.1)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(144 -2.1 -2.3)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(72 -4.7 -2)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            <g transform="rotate(72 -5 .5)">
                <g>
                <path d="m0-1-.3 1 .5.1z"/>
                <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g>
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
                <g transform="scale(-1 1)">
                <g transform="rotate(72)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                <g transform="rotate(144)">
                    <path d="m0-1-.3 1 .5.1z"/>
                    <path d="m0-1-.3 1 .5.1z"  transform="scale(-1 1)"/>
                </g>
                </g>
            </g>
            </g>
        </g>
    </svg>
)

export default EnglishEuropeanUnion;