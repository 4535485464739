import React, { useContext } from "react";
import { useStaticQuery, graphql } from 'gatsby';
import { get } from "lodash";
import { getStorage } from '../../../helpers/general';
import PriceContext from '../../../context/PriceProvider';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

// INHERIT STYLES FROM PARENT COMPONENT

const Price = props => {
    const priceCxt = useContext(PriceContext);
    const prices = get(priceCxt, 'prices', {});
    const channelId = get(priceCxt, 'channelId', 0);
    const {entityId, price, calculated_price, sale_price, override_price, returnData, taxCalc} = props
    const styles = props.styles || {};

    const currencyData = useStaticQuery(graphql`
        query {
            allBigCommerceCurrencies(filter: {enabled: {eq: true}, is_default: {eq: true}}) {
                edges {
                    node {
                        currency_code
                        currency_exchange_rate
                    }
                }
            }

            allBigCommerceCurrencyChannelAssignments {
              edges {
                node {
                  channel_id
                  default_currency
                  enabled_currencies
                }
              }
            }
        }
    `);

    let currencyCode = currencyData?.allBigCommerceCurrencies.edges[0].node.currency_code;
    let rate = currencyData?.allBigCommerceCurrencies.edges[0].node.currency_exchange_rate;
    const channelAssignments = currencyData?.allBigCommerceCurrencyChannelAssignments.edges.find(node => node.node.channel_id === channelId);
    if (channelAssignments) {
      currencyCode = channelAssignments.node.default_currency;
      const currency = currencyData?.allBigCommerceCurrencies.edges.find(node => node.node.currency_code === currencyCode);
      rate = currency?.node.currency_exchange_rate;
    }

    const latestProduct =
        prices && prices[entityId]
            ? prices[entityId]
            : {
                price,
                calculated_price,
                sale_price
                };
    // console.log("Latest prices", { ...latestProduct });
    if ('currency' in latestProduct) {
      currencyCode = latestProduct.currency;
    }

    const sessionCurrencyJson = getStorage('currency');
    if (sessionCurrencyJson) {
        const sessionCurrency = JSON.parse(sessionCurrencyJson);
        currencyCode = sessionCurrency.currentCurrency;
        rate = sessionCurrency.rates[currencyCode];
    }

    const calculatePrice = (price) => {
        return price * rate;
    }

    latestProduct.price = latestProduct.price && typeof latestProduct.price === 'object' ? latestProduct.price.tax_inclusive : latestProduct.price;
    latestProduct.calculated_price = latestProduct.calculated_price && typeof latestProduct.calculated_price === 'object' ? latestProduct.calculated_price.tax_inclusive : latestProduct.calculated_price;
    latestProduct.sale_price = latestProduct.sale_price && typeof latestProduct.sale_price === 'object' ? latestProduct.sale_price.tax_inclusive : latestProduct.sale_price;
    latestProduct.retail_price = latestProduct.retail_price && typeof latestProduct.retail_price === 'object' ? latestProduct.retail_price.tax_inclusive : latestProduct.retail_price;

    // console.log("Latest prices Updated", { ...latestProduct });
    // console.log("Override", { ...override_price });
    // console.log(prices[entityId]);

    if (returnData) {
      returnData({currencyCode, price: latestProduct.price});
    }

    return (
        <div data-pricetext className={styles.prices}>
          {override_price ? (
            <span className={styles.price}>
              <CurrencyFormatter
                currency={currencyCode}
                amount={override_price}
                taxCalc={taxCalc}
              />
            </span>
          ) : (
            <>
              {latestProduct.sale_price && latestProduct.sale_price !== 0 && latestProduct.sale_price !== null && latestProduct.sale_price !== latestProduct.price ? (
                <>
                  <span className={`${styles.price} ${styles.onSale}`}>
                    <CurrencyFormatter
                      currency={currencyCode}
                      amount={calculatePrice(latestProduct.price)}
                      taxCalc={taxCalc}
                    />
                  </span>
                  {latestProduct.sale_price && (
                    <span className={`${styles.price} ${styles.salePrice}`}>
                      <CurrencyFormatter
                        currency={currencyCode}
                        amount={calculatePrice(latestProduct.calculated_price)}
                        taxCalc={taxCalc}
                      />
                    </span>
                  )}
                </>
              ) : (
                <span className={styles.price}>
                  <CurrencyFormatter
                    currency={currencyCode}
                    amount={
                      latestProduct.calculated_price
                        ? calculatePrice(latestProduct.calculated_price)
                        : calculatePrice(latestProduct.price)
                    }
                    taxCalc={taxCalc}
                  />
                </span>
              )}
            </>
          )}
        </div>
    );
}

export default Price
