import React, { useState, useRef, useEffect } from "react"
import _ from 'lodash';
import Icon from "../../atoms/Icon/Icon";
import ProcessSearch from './ProcessSearch.js'
import { handleLink } from "../../../helpers/general";
import ProductGrid from '../../organisms/ProductGrid/ProductGrid'
import Container from '../../atoms/Container/Container';

import * as styles from './Search.module.css';

const Search = ({toggleSuggestions, showing}) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [isFocused, setFocused] = useState(false)
    const input = useRef(null)

    const submitHandler = e => {
        e.preventDefault()
        const results = suggestionsCount();
        if (results.count === 1) {
            window.location = handleLink(`/product${results.items[0].custom_url.url}`);
        } else {
            window.location = handleLink(`/search/?q=${searchTerm}`);
        }
    }

    const actionSuggestiveSearch = term => {
        setFocused(true);
        setSearchTerm(term);
        suggestiveSearch(term);
    };

    const suggestiveSearch = _.debounce(term => {
        if (term.length >= 3) {
          ProcessSearch(term, 4).then(results => {
            setSuggestions(results.items);
            if (toggleSuggestions) {
              toggleSuggestions(true);
            }
          });
        } else {
          if (toggleSuggestions) {
            toggleSuggestions(false);
          }
          setSuggestions([]);
        }
    }, 200);

    const suggestionsCount = () => {
        let merger = [];
        suggestions.map(suggestion => {
            const items = Object.values(suggestion);
            merger = merger.concat(items[0]);

            return true;
        });

        return {count: merger.length, items: merger}
    }

    const focusInput = _.debounce(() => {
        input.current?.focus();
    }, 310)

    useEffect(() => {
        if (input.current && !isFocused && showing) {
            focusInput()
        }
        if (!showing) {
            setFocused(false)
        }
    }, [showing, focusInput, isFocused]);

    useEffect(() => {
        if (window && document && document.body) {
          if (showing && !_.isEmpty(suggestions) && window.innerWidth < 767) {
            document.body.classList.add('disable-scrolling');
          } else {
            document.body.classList.remove('disable-scrolling');
          }
        }
    }, [showing, suggestions]);

    return (
        <>
          <form
            className={`${styles.root} ${showing ? 'showing' : ''}`}
            onSubmit={submitHandler}
          >
            <input
              className={styles.input}
              ref={input}
              type="text"
              placeholder="Search..."
              onChange={e => actionSuggestiveSearch(e.target.value)}
              onFocus={e => actionSuggestiveSearch(e.target.value)}
              onBlur={() => setFocused(false)}
            ></input>
            <button className={styles.button} type="submit">
              <Icon symbol="search" />
            </button>
          </form>
          {toggleSuggestions && suggestions.length > 0 && (
            <div className={styles.suggestions}>
              <Container size="large">
                {suggestions.map((suggestion, suggestionIndex) => {
                  // Add logic
                  if ('products' in suggestion) {
                    const displayProducts = suggestion.products.slice(0, 4);
                    return (
                      <ProductGrid
                        key={suggestionIndex}
                        products={displayProducts}
                      />
                    );
                  }
    
                  if ('posts' in suggestion) {
                    // TODO: Convert to component
                    return (
                      <div key={suggestionIndex}>
                        {suggestion.posts &&
                          suggestion.posts.map((post, postIndex) => (
                            <a key={postIndex} href={post.url}>
                              {post.title}
                            </a>
                          ))}
                      </div>
                    );
                  }
    
                  return null;
                })}
              </Container>
            </div>
          )}
        </>
    );
}

export default Search