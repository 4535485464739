// import { graphql, useStaticQuery } from 'gatsby';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import parse from 'html-react-parser'
import { get } from 'lodash';
import { dataLayerPush, formatCurrency } from '../../../helpers/general';
import { bcApi } from '../../../helpers/bigcommerce';

import PriceContext from '../../../context/PriceProvider';

import Options from '../../atoms/Options/Options';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import PdpAdditionalContent from '../../atoms/PdpAdditionalContent/PdpAdditionalContent';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

import glossImage from './gloss.png';
import matteImage from './matte.png';

import * as styles from './ProductOptions.module.css';

function ProductOptions(props) {
    const {product, scrollToProductInformation, setOptionsData, resetModifier = null, setResetModifier, dealModifiers = null, isSpecial = false, goToSliderId} = props;
    const [init, setInit] = useState(false);

    const priceCxt = useContext(PriceContext);
    const localPrices = get(priceCxt, 'prices', {});
    const localPricesFetched = get(priceCxt, 'pricesFetched', false);
    const currency = get(priceCxt, 'currency', 'AUD');

    const [selectedVariant, setSelectedVariant] = useState(false);
    const [variantList, setVariantList] = useState([]);

    const [selectedModifier, setSelectedModifier] = useState(false);
    const [selectedDefaultModifier, setSelectedDefaultModifier] = useState(false);
    const [requiredModifier, setRequiredModifier] = useState(false);
    const [modifierList, setModifierList] = useState([]);
    const [modifierOrder, setModifierOrder] = useState([]);

    const [configList, setConfigList] = useState([]);
    const [configOrder, setConfigOrder] = useState([]);

    const [variantInventory, setVariantInventory] = useState(false);
    const [localOptionsData, setLocalOptionsData] = useState({});
    const [defaultOptionsData, setDefaultOptionsData] = useState({});
    
    const [resetDealModifiers, setResetDealModifiers] = useState(false);
    const [thisDealModifiers, setThisDealModifiers] = useState([]);

    const tax = (100 + Number(process.env.GATSBY_TAX_VALUE)) / 100;

    useEffect(() => {
        setThisDealModifiers(dealModifiers);
    }, [dealModifiers])

    useEffect(() => {
        if (resetModifier !== null) {
            setSelectedModifier(selectedDefaultModifier);
            // console.log('useEffect for modifier reset', localOptionsData);
            setOptionsData({
                ...localOptionsData,
                'modifierOptions': resetModifier
            })
            setResetModifier(false)
        }
    }, [resetModifier, localOptionsData, setOptionsData, selectedDefaultModifier, setResetModifier]);

    useEffect(() => {
        if (!resetDealModifiers 
            && thisDealModifiers 
            && modifierList && modifierList.length > 0
            && configList && configList.length > 0
            && localOptionsData) 
        {
            // console.log('useEffect for deal reset', localOptionsData);
            const tempModifier = {};
            modifierList.map((modifierOption) => {
                if (modifierOption.options) {
                    modifierOption.options.map((option) => {
                        if (thisDealModifiers && thisDealModifiers.includes(option.sku)) {
                            let obj = {label: option.label, sku: option.sku, price: option.rawPrice};
                            if (modifierOption.type === 'rectangles') {
                                tempModifier[modifierOption.option_id] = 
                                    modifierOption.option_id in tempModifier ? 
                                    [...tempModifier[modifierOption.option_id], obj]
                                     : [obj];
                            } else {
                                tempModifier[modifierOption.option_id] = obj;
                            }
                        }
                        return option;
                    })
                }

                return modifierOption;
            })

            configList.map((modifierOption) => {
                if (modifierOption.options) {
                    modifierOption.options.map((option) => {
                        if (thisDealModifiers && thisDealModifiers.includes(option.sku)) {
                            tempModifier[modifierOption.option_id] = {label: option.label, sku: option.sku, price: option.rawPrice};
                        }
                        return option;
                    })
                }

                return modifierOption;
            })

            setSelectedModifier(tempModifier);
            setOptionsData({
                ...localOptionsData,
                'modifierOptions':  {...tempModifier}
            })
            setResetDealModifiers(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thisDealModifiers, localOptionsData, setOptionsData, resetDealModifiers])

    const handleDealsModifierPreSelect = () => {
        const element = document.querySelector('.options-container');
        const scrollElement = document.querySelector('.options-container .blurb-promotion-container + div');
        if (element && scrollElement) {
            element.classList.remove('showcase');
            // To-do dynamic way to handle deals

            scrollElement.scrollIntoView({
                behavior: 'smooth'
            }, true);

            setTimeout(() => {
                element.classList.add('showcase');
            }, 800);
        }
        
        setThisDealModifiers(['PREMPAD', 'VSS-MK1S', 'VSPL120MAX'])
    }

    const handleSelectVariant = (value, key, name) => {
        const stockState = checkVariantOptionInventory(key, value.option_value_id);
        const optionObj = (variantList && variantList[0].options.filter((opt) => opt.option_value_id === value.option_value_id)) || null;
        const tempVariant = {
            ...selectedVariant,
            [key]: {
                ...value,
                ...(optionObj && {
                    variant_id: (optionObj && optionObj[0] && optionObj[0].variant_id) || 0,
                    cost_price: (optionObj && optionObj[0] && optionObj[0].cost_price) || 0,
                    retail_price: (optionObj && optionObj[0] && optionObj[0].retail_price) || 0,
                    sale_price: (optionObj && optionObj[0] && optionObj[0].sale_price) || 0,
                    price: (optionObj && optionObj[0] && optionObj[0].price) || 0,
                    calculated_price: (optionObj && optionObj[0] && optionObj[0].calculated_price) || 0
                })
            },
        }
        dataLayerPush('Product configuration', {name: `${name} - ${value.label}`, content: product.name})
        setSelectedVariant(tempVariant);

        const tempOptionsData = {
            ...localOptionsData,
            'variantOptions': tempVariant,
            inStock: stockState
        }

        if (product.custom_fields.find(field => field.name === 'imageStyle' && field.value === '360') && tempOptionsData.imageChangeData) {
            Object.keys(tempOptionsData.imageChangeData).map(field => {
                if ((name.toLowerCase()).indexOf(field) > -1) {
                    tempOptionsData.imageChangeData[field] = value.label;
                }

                return true;
            });
        }

        setLocalOptionsData(tempOptionsData);
        // console.log('handleSelectVariant', tempOptionsData);
        setOptionsData(tempOptionsData);
    }

    const handleSelectModifier = (value, key, name, isMulti, selected) => {
        let tempField = key in selectedModifier ? selectedModifier[key] : null;
        if (isMulti) {
            if (tempField) {
                const exists = tempField.findIndex(option => option.label === value.label);
                if (exists > -1) {
                    // remove
                    tempField.splice(exists, 1);
                    dataLayerPush('Product configuration', {name: `${name} - ${value.label} (Removed)`, content: product.name});
                } else {
                    // add
                    tempField.push(value);
                    dataLayerPush('Product configuration', {name: `${name} - ${value.label}`, content: product.name});
                }
            } else {
                tempField = [value];
                dataLayerPush('Product configuration', {name: `${name} - ${value.label}`, content: product.name});
            }
        } else {
            tempField = value;
            dataLayerPush('Product configuration', {name: `${name} - ${value.label}`, content: product.name});
        }
        const tempModifier = {
            ...selectedModifier,
            [key]: tempField,
        }
        
        setSelectedModifier(tempModifier);

        const tempOptionsData = {
            ...localOptionsData,
            'modifierOptions': tempModifier
        }

        if (product.custom_fields.find(field => field.name === 'imageStyle' && field.value === '360')) {
            Object.keys(tempOptionsData.imageChangeData).map(field => {
                if ((name.toLowerCase()).indexOf(field) > -1) {
                    if (!isMulti || (isMulti && selected)) {
                        tempOptionsData.imageChangeData[field] = value.label;
                    } else if (isMulti && typeof tempField === 'object' && tempField.length > 0) {
                        tempOptionsData.imageChangeData[field] = tempField[0].label;
                    } else if (isMulti && typeof tempField === 'object' && tempField.length === 0) {
                        tempOptionsData.imageChangeData[field] = defaultOptionsData[field];
                    }
                }

                return true;
            });
        }

        setLocalOptionsData(tempOptionsData);
        // console.log('handleSelectModifier', tempOptionsData);
        setOptionsData(tempOptionsData);
    }

    const handleCheckboxSelection = (selected, value, key, name) => {
        if (selected) {
            handleSelectModifier(value, key, name);
        } else {
            const tempModifier = { ...selectedModifier }
            delete tempModifier[key];
            dataLayerPush('Product configuration', {name: `${name} - ${value.label} (Removed)`, content: product.name});
            setSelectedModifier(tempModifier);

            const tempOptionsData = {
                ...localOptionsData,
                'modifierOptions': tempModifier
            }
    
            setLocalOptionsData(tempOptionsData);
            // console.log('handleCheckboxSelection', tempOptionsData);
            setOptionsData(tempOptionsData);
        }
    }

    const isSelected = (dataSet, key, value) => {
        if (!dataSet) return false;
        if (!(key in dataSet)) return false;
        if (Array.isArray(dataSet[key])) {
            // Multi
            const exists = dataSet[key].find(option => option.label === value);
            return typeof exists === 'object';
        } else {
            // Single
            return dataSet[key].label === value;
        }
    }

    const checkVariantOptionInventory = (optionId, optionValueId) => {
        if (product.inventory_tracking === "none") return true;
        if (!variantInventory) return false;
        const setValues = Object.keys(selectedVariant).filter(oId => parseInt(oId) !== optionId);
        const inventory = variantInventory.find(data => {
            if (data[optionId] !== optionValueId) return false;
            const matchedOptions = setValues.filter(val => data[val] === selectedVariant[val].option_value_id);
            if (matchedOptions && matchedOptions.length === setValues.length) return true;

            return false;
        });

        if (inventory.inventory > 0) return true;

        return false;
    }

    useMemo(async () => {
        if (!init && localPricesFetched) {
            setInit(true);
            const optionsData = {};
            // console.log('------- FETCH ------');
            if (product && product.variants.length > 0 && !selectedVariant) {
                const tempVariantList = [...variantList];
                const tempVariant = {};
                const tempInventories = [];
                // product.options[0].option_values[1].is_default = true;
                // console.log("product", product);
                const inventory = await bcApi(`catalog/products?id=${product.entityId}&include=variants`);
                const allVariants = await bcApi(`catalog/variants?product_id=${product.entityId}&include_fields=cost_price,price,sale_price,retail_price,calculated_price,sku`);
                // console.log("Inventory", inventory);
                product.variants.map(variant => {
                    const inventoryFound = (inventory.response && inventory.response.data[0].variants.find(invVar => invVar.id === variant.entityId)) || false;
                    const variantFound = (allVariants.response && allVariants.response.data.find(invVar => invVar.id === variant.entityId)) || false;
                    const tempInventory = {inventory: inventoryFound.inventory_level || 0};
                    // console.log(variantFound);

                    variant.option_values.map(variantOption => {
                        // console.log("Option", variantOption);
                        // console.log("TempList", tempVariantList);
                        const indexFound = tempVariantList.findIndex(el => el.option_id === variantOption.option_id);
                        if (!(variantOption.option_id in tempVariant) && (inventoryFound.inventory_level > 0 || false)) {
                            const getOption = product.options.find(o => o.id === variantOption.option_id);
                            const hasDefault = getOption.option_values.find(ov => ov.is_default);
                            if (hasDefault) {
                                tempVariant[variantOption.option_id] = {
                                    label: hasDefault.label, 
                                    option_value_id: hasDefault.id
                                };
                            } else {
                                tempVariant[variantOption.option_id] = {
                                    label: variantOption.label, 
                                    option_value_id: variantOption.option_value_id
                                };
                            }
                        }

                        tempInventory[variantOption.option_id] = variantOption.option_value_id;
                        
                        if (indexFound === -1) {
                            //new variant
                            const newVariant = {
                                option_id: variantOption.option_id,
                                name: variantOption.option_display_name,
                                options: [{
                                    label: variantOption.label, 
                                    option_value_id: variantOption.option_value_id,
                                    entityId: variant.entityId, // Need for slider image update
                                    variant_id: (variantFound && variantFound.id) || 0,
                                    cost_price: (variantFound && variantFound.cost_price) || 0,
                                    retail_price: (variantFound && variantFound.retail_price) || 0,
                                    sale_price: (variantFound && variantFound.sale_price) || 0,
                                    price: (variantFound && variantFound.price) || 0,
                                    calculated_price: (variantFound && variantFound.calculated_price) || 0
                                }],
                            }
                            tempVariantList.push(newVariant);
                        } else {
                            // add to the existing list
                            const found = tempVariantList[indexFound].options.some(vl => vl.label === variantOption.label);
                            // check for duplicates
                            if (found !== true) {
                                tempVariantList[indexFound].options.push({
                                    label: variantOption.label, 
                                    option_value_id: variantOption.option_value_id,
                                    entityId: variant.entityId, // Need for slider image update
                                    variant_id: (variantFound && variantFound.id) || 0,
                                    cost_price: (variantFound && variantFound.cost_price) || 0,
                                    retail_price: (variantFound && variantFound.retail_price) || 0,
                                    sale_price: (variantFound && variantFound.sale_price) || 0,
                                    price: (variantFound && variantFound.price) || 0,
                                    calculated_price: (variantFound && variantFound.calculated_price) || 0
                                });
                            }
                        }
                        return true;
                    });

                    tempInventories.push(tempInventory);
                    return true;
                });
                // console.log("Variants", tempVariantList);
                // console.log("Inventories", tempInventories);
                setVariantList([...tempVariantList]);
                setVariantInventory([...tempInventories]);

                if (!selectedVariant) {
                    // console.log("Selected", tempVariant);
                    setSelectedVariant(tempVariant);
                }
                // console.log("setting variant option", tempVariant);
                optionsData['variantOptions'] = {...tempVariant};
            }

            if (product && product.modifiers.length > 0 && !selectedModifier) {
                const tempModifierList = [...modifierList];
                const tempModifier = {};
                const tempModifierOrder = [];

                const tempConfigList = [...configList];
                const tempConfigOrder = [];

                const thisRequiredModifier = {...requiredModifier};
                const openDelivery = {
                    date: 'None Available',
                    inventory: 0
                };

                const loops = product.modifiers.sort((a,b) => a.sort_order - b.sort_order).map(async modifier => {
                    if (modifier.display_name.startsWith('*UPSELL* ')) {
                        // Display options to add extras to the cart with the main item
                        let fieldLabel = modifier.display_name.replace('*UPSELL* ', '');
                        const checkFieldCustomType = /^#(.*)#/gm.exec(fieldLabel);
                        let fieldCustomType = modifier.type;
                        if (checkFieldCustomType) {
                            fieldLabel = fieldLabel.replace(`${checkFieldCustomType[0]} `);
                            fieldCustomType = checkFieldCustomType[1];
                        }

                        const inventoryCheckSkus = modifier.option_values.map(modifierOption => {
                            const regexOptionData = /^(.*?)\s\|\s(.*?)(?:\s\((.*)\))?$/gm;
                            const optionData = regexOptionData.exec(modifierOption.label);
                            return (optionData && optionData[3]) || null;
                        });

                        tempModifierOrder.push(modifier.option_values[0].option_id);
                        const inventory = await bcApi(`catalog/variants?sku:in=${inventoryCheckSkus.join(',')}&include_fields=inventory_level,sku,product_id,price,sale_price`);
                        const prodIds = (inventory.response && [...new Set(inventory.response.data.map(variant => variant.product_id))]) || [];
                        const inventoryParents = (prodIds.length > 0 && await bcApi(`catalog/products?id:in=${prodIds.join(',')}&include_fields=inventory_tracking`)) || {response: null, status: 404};
                        modifier.option_values.map(modifierOption => {
                            const regexOptionData = /^(.*?)\s\|\s(.*?)(?:\s\((.*)\))?$/gm;
                            const optionData = regexOptionData.exec(modifierOption.label);
                            const optionLabel = (optionData && optionData[1]) || null;
                            const optionPrice = (optionData && optionData[2]) || null;
                            const optionSKU = (optionData && optionData[3]) || null;
                            // console.log(fieldLabel, modifierOption.label, optionLabel, optionPrice, optionSKU);

                            const inventoryValue = (inventory.response && inventory.response.data.find(product => product.sku === optionSKU)) || 0;
                            const tracking = (inventoryParents.response && inventoryParents.response.data[0].inventory_tracking) || false;
                            const localProductPrice = Object.values(localPrices).find(p => p.product_id === inventoryValue.product_id);
                            const localVariantPrice = (localProductPrice && localProductPrice.variants && localProductPrice.variants.find(v => v.variant_id === inventoryValue.id)) || false;
                            const livePrice = (optionPrice === 'FREE') ? 0 : ((localVariantPrice && localVariantPrice.sale_price && localVariantPrice.sale_price > 0) ? localVariantPrice.sale_price : localVariantPrice.price) || 
                                ((inventoryValue.sale_price && inventoryValue.sale_price > 0) ? inventoryValue.sale_price : inventoryValue.price) || 
                                optionPrice.replace(/[^0-9.]/g, '');
                            
                            const withTaxLivePrice = livePrice * tax;
                            const { formattedPrice, currencySymbol } = formatCurrency(currency, withTaxLivePrice, false);

                            const indexFound = tempModifierList.findIndex(el => el.option_id === modifierOption.option_id);
                            if (indexFound === -1) {
                                //new modifier
                                const newModifier = {
                                    option_id: modifierOption.option_id,
                                    name: fieldLabel,
                                    type: fieldCustomType,
                                    options: [{label: optionLabel, price: `+${currencySymbol}${formattedPrice}`, rawPrice: livePrice, sku: optionSKU, option_value_id: modifierOption.option_value_id, inventory: inventoryValue.inventory_level, inventoryTracking: tracking}]
                                }
                                tempModifierList.push(newModifier);
                                
                            } else {
                                // add to the existing list
                                const found = tempModifierList[indexFound].options.some(vl => vl.label === optionLabel);
                                // check for duplicates
                                if (found !== true) {
                                    tempModifierList[indexFound].options.push({label: optionLabel, price: `+${currencySymbol}${formattedPrice}`, rawPrice: livePrice, sku: optionSKU, option_value_id: modifierOption.option_value_id, inventory: inventoryValue.inventory_level, inventoryTracking: tracking});
                                }
                            }

                            if (fieldCustomType !== 'checkbox' && modifierOption.is_default) {
                                tempModifier[modifierOption.option_id] = {label: optionLabel, sku: optionSKU};
                            }

                            return true;
                        })
                    } else if (modifier.display_name.startsWith('*CONFIG* ')) {
                        // Display options to add extras to the cart with the main item
                        let fieldLabel = modifier.display_name.replace('*CONFIG* ', '');
                        const checkFieldCustomType = /^#(.*)#/gm.exec(fieldLabel);
                        let fieldCustomType = modifier.type;
                        if (checkFieldCustomType) {
                            fieldLabel = fieldLabel.replace(`${checkFieldCustomType[0]} `);
                            fieldCustomType = checkFieldCustomType[1];
                        }

                        const inventoryCheckSkus = modifier.option_values.map(configOption => {
                            const regexOptionData = /^(.*?)\s\|\s(.*?)(?:\s\((.*)\))?$/gm;
                            const optionData = regexOptionData.exec(configOption.label);
                            return (optionData && optionData[3]) || null;
                        });

                        tempConfigOrder.push(modifier.option_values[0].option_id);
                        const inventory = await bcApi(`catalog/variants?sku:in=${inventoryCheckSkus.join(',')}&include_fields=inventory_level,sku,product_id,price,sale_price`);
                        const prodIds = (inventory.response && [...new Set(inventory.response.data.map(variant => variant.product_id))]) || [];
                        const inventoryParents = (prodIds.length > 0 && await bcApi(`catalog/products?id:in=${prodIds.join(',')}&include_fields=inventory_tracking`)) || {response: null, status: 404};
                        modifier.option_values.map(configOption => {
                            const regexOptionData = /^(.*?)\s\|\s(.*?)(?:\s\((.*)\))?$/gm;
                            const optionData = regexOptionData.exec(configOption.label);
                            const optionLabel = (optionData && optionData[1]) || null;
                            const optionPrice = (optionData && optionData[2]) || null;
                            const optionSKU = (optionData && optionData[3]) || null;
                            // console.log(fieldLabel, fieldCustomType, configOption.label, optionLabel, optionPrice, optionSKU);

                            const inventoryValue = (inventory.response && inventory.response.data.find(product => product.sku === optionSKU)) || 0;
                            const tracking = (inventoryParents.response && inventoryParents.response.data[0].inventory_tracking) || false;

                            const livePrice = ((inventoryValue.sale_price && inventoryValue.sale_price > 0) ? inventoryValue.sale_price : inventoryValue.price) || optionPrice.replace(/[^0-9.]/g, '');

                            const withTaxLivePrice = livePrice * tax;
                            const { formattedPrice, currencySymbol } = formatCurrency(currency, withTaxLivePrice, false);

                            const indexFound = tempConfigList.findIndex(el => el.option_id === configOption.option_id);
                            if (indexFound === -1) {
                                //new Config
                                const newConfig = {
                                    option_id: configOption.option_id,
                                    name: fieldLabel,
                                    type: fieldCustomType,
                                    options: [{label: optionLabel, price: (parseInt(withTaxLivePrice, 10) > 0 ? `+${currencySymbol}${formattedPrice}` : `${currencySymbol}${formattedPrice}`), rawPrice: livePrice, sku: optionSKU, option_value_id: configOption.option_value_id, inventory: inventoryValue.inventory_level, inventoryTracking: tracking}]
                                }
                                tempConfigList.push(newConfig);
                                
                            } else {
                                // add to the existing list
                                const found = tempConfigList[indexFound].options.some(vl => vl.label === optionLabel);
                                // check for duplicates
                                if (found !== true) {
                                    tempConfigList[indexFound].options.push({label: optionLabel, price: (parseInt(withTaxLivePrice, 10) > 0 ? `+${currencySymbol}${formattedPrice}` : `${currencySymbol}${formattedPrice}`), rawPrice: livePrice, sku: optionSKU, option_value_id: configOption.option_value_id, inventory: inventoryValue.inventory_level, inventoryTracking: tracking});
                                }
                            }

                            if (fieldCustomType !== 'checkbox' && configOption.is_default) {
                                tempModifier[configOption.option_id] = {label: optionLabel, sku: optionSKU};
                            }

                            return true;
                        })
                    } else {
                        // Hidden field that needs to check inventory
                        if (modifier.required) {
                            // Loop through options until an in-stock value is found.
                            let modifierId = false;
                            if (modifier.type === 'product_list_with_images') {
                                let availableModifier = false;
                                const inventoryCheckIds = modifier.option_values.map(option => {
                                    if (!modifierId) modifierId = option.option_id;
                                    
                                    return option.value_data.product_id;
                                });

                                const inventory = await bcApi(`catalog/products?id:in=${inventoryCheckIds.join(',')}&include_fields=inventory_level`);
                                
                                modifier.option_values.map(option => {
                                    const productInventory = (inventory.response && inventory.response.data.find(inventory => inventory.id === option.value_data.product_id)) || null;
                                    if (!availableModifier && productInventory && productInventory.inventory_level > 0) {
                                        // console.log("first available", modifier.display_name, option.label);
                                        availableModifier = {...option, inventory: productInventory.inventory_level};
                                    }
                                    
                                    return true;
                                });

                                thisRequiredModifier[modifierId] = availableModifier;
                            
                                if (modifier.display_name === "Scheduled Delivery") {
                                    openDelivery.date = availableModifier.label;
                                    openDelivery.inventory = availableModifier.inventory;
                                }
                            }
                        }
                    }
                    
                    return true;
                })

                await Promise.all(loops);

                setModifierList([...tempModifierList]);
                setModifierOrder([...tempModifierOrder]);

                setConfigList([...tempConfigList]);
                setConfigOrder([...tempConfigOrder]);
                setRequiredModifier({...thisRequiredModifier});

                if (!selectedModifier) {
                    setSelectedModifier(tempModifier);
                    setSelectedDefaultModifier(tempModifier);
                }

                optionsData['modifierOptions'] = {...tempModifier};
                optionsData['requiredInventories'] = {...thisRequiredModifier};
                optionsData['deliveryData'] = {...openDelivery};
            }

            if (product.custom_fields.find(field => field.name === 'imageStyle' && field.value === '360')) {
                const effectedFields = {};
                product.custom_fields.map(field => {
                    if (field.name.startsWith('imageStyleOption')) {
                        const number = field.name.replace('imageStyleOption_', '');
                        const defaultValue = product.custom_fields.find(field => field.name === `imageStyleDefaultOption_${number}`);
                        effectedFields[field.value] = defaultValue.value;
                    }
    
                    return true;
                });

                optionsData['imageChangeData'] = {...effectedFields};
                setDefaultOptionsData({...effectedFields});
            }

            setLocalOptionsData(optionsData);
            // console.log('Init', optionsData);
            setOptionsData(optionsData);
        }
    }, [init, variantList, modifierList, configList, product, selectedVariant, selectedModifier, requiredModifier, setOptionsData, localPrices, localPricesFetched, currency, tax]);

    const finishSwatches = {
        Gloss: glossImage,
        Matte: matteImage
    }
  
    // console.log('------- RERENDER ------');
    // console.log("Product", product);
    // console.log("Variants", variantList);
    // console.log("Raw Variants", product.variants);
    // console.log("Selected Variants", selectedVariant);
    // console.log("Modifiers", modifierList);
    // console.log("Raw Modifiers", product.modifiers);
    // console.log("Selected Modifiers", selectedModifier);
    // console.log("Required Modifiers", requiredModifier);

    return (
        <div data-product-options className={`${styles.options} options-container`} >

            {(variantList.length > 0 
            || modifierList.length > 0) && <div className={styles.border}></div>}

            {(variantList.length > 0 
            || modifierList.length) > 0 && ('custom_fields' in product && product.custom_fields.find(cf => cf.name === 'blurb_promotion_heading')) && (
                <div className={`${styles.blurbPromotionalContainer} blurb-promotion-container`}>
                    <h6>{product.custom_fields.find(cf => cf.name === 'blurb_promotion_heading').value}</h6>
                    {product.custom_fields.find(cf => cf.name === 'blurb_promotion_body') ? (
                        <p>{parse(product.custom_fields.find(cf => cf.name === 'blurb_promotion_body').value.replace(/\*\* (.*) \*\*/g, '<span style="font-weight: 700; display: block;">$1</span>'))}</p>
                    ) : ''}
                    {product.custom_fields.find(cf => cf.name === 'blurb_promotion_subnote') ? (
                        <p className={styles.blurbPromotionalSubnote}>{product.custom_fields.find(cf => cf.name === 'blurb_promotion_subnote').value}</p>
                    ) : ''}
                    {product.custom_fields.find(cf => cf.name === 'blurb_promotion_show_cta') && ['Yes', 'yes'].includes(product.custom_fields.find(cf => cf.name === 'blurb_promotion_show_cta').value) ? (
                        <Button fullWidth="true" level="primary" type="span" size="small" onClick={() => handleDealsModifierPreSelect() }>{product.custom_fields.find(cf => cf.name === 'blurb_promotion_cta_label').value || 'Create Your Bundle'}</Button>
                    ) : ''}
                </div>
            )}

            {variantList.length > 0 && 
                variantList.map((variant) => {

                    const gridLayout = variant.options.length % 3 === 0 ? styles.grid3Col : styles.grid2Col;

                    if (variant.options.length > 1) {
                        return (
                            <div className={`${styles.option}`} key={variant.option_id}>
                                <div className={variant.name === 'Finish' ? styles.finishTitleContainer : styles.headerFinish}>
                                    <span className={styles.title}><ContentfulTranslationText keyName="select">SELECT</ContentfulTranslationText> <ContentfulTranslationText keyName={variant.name.toLowerCase()}>{variant.name}</ContentfulTranslationText></span>

                                    {variant.name === 'Finish' && (
                                        <>
                                            <span className={styles.dot}></span>
                                            <span className={styles.type}>{selectedVariant && variant.option_id in selectedVariant && <ContentfulTranslationText keyName={selectedVariant[variant.option_id].label.toLowerCase()}>{selectedVariant[variant.option_id].label}</ContentfulTranslationText>}</span>
                                        </>
                                    )}

                                    {((variant.name === 'Size' || variant.name.indexOf('Size') > -1) && isSpecial) && 
                                        <span role={'presentation'} 
                                            onClick={(e) => {
                                            e.preventDefault();
                                            scrollToProductInformation('sizes')
                                        }} className={`${styles.additionalDetail} generic-hover`}>
                                            <ContentfulTranslationText keyName="sizeGuide">Size guide</ContentfulTranslationText> <span className={styles.infoIcon}><Icon symbol='info' /></span>    
                                        </span>
                                    }
                                </div>

                                {variant.name === 'Finish' ? (
                                    <div className={`${styles.optionSwatches} ${styles.finishContainer}`}>
                                        {variant.options.map((option) => 
                                            <img 
                                                role={'presentation'}
                                                alt={option.label} 
                                                key={option.label} 
                                                src={finishSwatches[option.label]}
                                                onClick={() => handleSelectVariant({label: option.label, option_value_id: option.option_value_id}, variant.option_id, variant.name)}
                                                className={`${styles.optionSwatch} ${(selectedVariant && variant.option_id in selectedVariant && selectedVariant[variant.option_id].label === option.label) ? styles.optionSwatchSelected : ''} `}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div className={`${styles.dynamicOptionContainer} ${gridLayout}`}>
                                        {product.options.find(option => option.id === variant.option_id).option_values.map(option => 
                                            <Options 
                                                key={option.label} 
                                                title={<><ContentfulTranslationText keyName={option.label}>{option.label}</ContentfulTranslationText></>} 
                                                disabled={(variant.name === 'Size' || variant.name.indexOf('Size') > -1) ? false : false}
                                                isOos={!checkVariantOptionInventory(variant.option_id, option.id)}
                                                // checkVariantOptionInventory(variant.option_id, option.id)
                                                selected={(selectedVariant && variant.option_id in selectedVariant) ? selectedVariant[variant.option_id].label === option.label : false}
                                                selectOption={() => {
                                                    handleSelectVariant({label: option.label, option_value_id: option.id}, variant.option_id, variant.name) 
                                                    const varOption = variant.options.filter((opt) => opt.option_value_id === option.id);
                                                    goToSliderId(varOption ? varOption[0].entityId : null);
                                                }}
                                            />
                                        )}
                                    </div>
                                )}

                            </div>
                        )
                    } else {
                        // Single option, set hidden field
                        return null
                    }
                })
            }

            {(configList.length > 0 && configOrder.length > 0) &&
                configOrder.map((config_id) => {
                    const config = configList.find(conf => conf.option_id === config_id);
                    // console.log("CONFIG ITEM", config);
                    switch (config.type) {
                        case 'rectangles':
                            // Series of multi select options
                            return (
                                <div className={`${styles.option}`} key={config.name}>
                                    <div className={styles.label}>
                                        <span className={styles.title}><ContentfulTranslationText keyName={config.name}>{config.name}</ContentfulTranslationText></span>
                                        {/* {config.name === 'Extended Warranty' && (
                                            <>
                                                <span className={styles.additionalDetail} role="presentation" onClick={() => openAdditionalDetail(!openDetail)}>Additional detail <span className={styles.infoIcon}><Icon symbol='info' /></span></span>
                                                <Dialog open={openDetail} size="md" hideBtnCancel hideBtnOk>
                                                    <ContentfulGlobalContent name="Extended Warranty detail" />
                                                </Dialog>
                                            </>
                                        )} */}
                                        <PdpAdditionalContent name={config.name} styles={styles} />
                                    </div>
                                    <div className={`${styles.optionContainer} ${styles.grid2Col}`}>
                                        {config.options.map((option) => 
                                            <Options 
                                                key={option.label} 
                                                title={<><ContentfulTranslationText keyName={option.label}>{option.label}</ContentfulTranslationText></>} 
                                                price={option.price} 
                                                currency={currency}
                                                disabled={option.inventory === 0 && option.inventoryTracking !== "none"}
                                                selected={isSelected(selectedModifier, config.option_id, option.label)}
                                                showChecked={isSelected(selectedModifier, config.option_id, option.label)}
                                                isMulti={true}
                                                selectOption={(selected) => handleSelectModifier({label: option.label, sku: option.sku, price: option.rawPrice}, config.option_id, config.name, true, selected)} 
                                            />
                                        )}
                                    </div>
                                </div>
                            )

                        case 'checkbox':
                            // Single checkbox
                            const option = config.options[0];
                            return (
                                <div className={`${styles.option}`} key={config.name}>
                                    <Checkbox
                                        name={config.name}
                                        label={`${<><ContentfulTranslationText keyName={option.label}>{option.label}</ContentfulTranslationText></>} ${option.price}`}
                                        action={(e) => handleCheckboxSelection(e.target.checked, {label: option.label, sku: option.sku, price: option.rawPrice}, config.option_id, config.name)}
                                        isChecked={(selectedModifier && config.option_id in selectedModifier) ? selectedModifier[config.option_id].label === option.label : false}
                                        size={'md'}
                                    />
                                </div>
                            )

                        case 'radio_buttons':
                            // Series of single select options
                            return (
                                <div className={`${styles.option}`} key={config.name}>
                                    <div className={styles.label}>
                                        <span className={styles.title}><ContentfulTranslationText keyName={config.name}>{config.name}</ContentfulTranslationText></span>
                                        <PdpAdditionalContent name={config.name} styles={styles} />
                                    </div>
                                    <div className={`${styles.optionContainer} ${styles.grid2Col}`}>
                                        {config.options.map((option) => 
                                            <Options 
                                                key={option.label} 
                                                title={<><ContentfulTranslationText keyName={option.label}>{option.label}</ContentfulTranslationText></>} 
                                                price={option.price} 
                                                currency={currency}
                                                disabled={option.inventory === 0 && option.inventoryTracking !== "none"}
                                                selected={(selectedModifier && config.option_id in selectedModifier) ? selectedModifier[config.option_id].label === option.label : false}  
                                                showChecked={(selectedModifier && config.option_id in selectedModifier) ? selectedModifier[config.option_id].label === option.label : false}  
                                                selectOption={() => handleSelectModifier({label: option.label, sku: option.sku, price: option.rawPrice}, config.option_id, config.name)} 
                                            />
                                        )}
                                    </div>
                                </div>
                            )

                        default:
                            return null;
                    }
                })
            }

            <div className={styles.border}></div>

            {(modifierList.length > 0 && modifierOrder.length > 0) &&
                modifierOrder.map((modifier_id) => {
                    const modifier = modifierList.find(mod => mod.option_id === modifier_id);
                    // console.log("MODIFIER ITEM", modifier);
                    switch (modifier.type) {
                        case 'rectangles':
                            // Series of multi select options
                            return (
                                <div className={`${styles.option}`} key={modifier.name}>
                                    <div className={styles.label}>
                                        <span className={styles.title}><ContentfulTranslationText keyName={modifier.name}>{modifier.name}</ContentfulTranslationText></span>
                                        {/* {modifier.name === 'Extended Warranty' && (
                                            <>
                                                <span className={styles.additionalDetail} role="presentation" onClick={() => openAdditionalDetail(!openDetail)}>Additional detail <span className={styles.infoIcon}><Icon symbol='info' /></span></span>
                                                <Dialog open={openDetail} size="md" hideBtnCancel hideBtnOk>
                                                    <ContentfulGlobalContent name="Extended Warranty detail" />
                                                </Dialog>
                                            </>
                                        )} */}
                                        <PdpAdditionalContent name={modifier.name} styles={styles} />
                                    </div>
                                    <div className={`${styles.optionContainer} ${styles.grid2Col}`}>
                                        {modifier.options.map((option) => 
                                            <Options 
                                                key={option.label} 
                                                title={<><ContentfulTranslationText keyName={option.label}>{option.label}</ContentfulTranslationText></>} 
                                                price={option.price} 
                                                currency={currency}
                                                disabled={option.inventory === 0 && option.inventoryTracking !== "none"}
                                                selected={isSelected(selectedModifier, modifier.option_id, option.label)}
                                                showChecked={isSelected(selectedModifier, modifier.option_id, option.label)}
                                                isMulti={true}
                                                selectOption={(selected) => handleSelectModifier({label: option.label, sku: option.sku, price: option.rawPrice}, modifier.option_id, modifier.name, true, selected)} 
                                            />
                                        )}
                                    </div>
                                </div>
                            )

                        case 'checkbox':
                            // Single checkbox
                            const option = modifier.options[0];
                            return (
                                <div className={`${styles.option}`} key={modifier.name}>
                                    <Checkbox
                                        name={modifier.name}
                                        label={`${<><ContentfulTranslationText keyName={option.label}>{option.label}</ContentfulTranslationText></>} ${option.price}`}
                                        action={(e) => handleCheckboxSelection(e.target.checked, {label: option.label, sku: option.sku, price: option.rawPrice}, modifier.option_id, modifier.name)}
                                        isChecked={(selectedModifier && modifier.option_id in selectedModifier) ? selectedModifier[modifier.option_id].label === option.label : false}
                                        size={'md'}
                                    />
                                </div>
                            )

                        case 'radio_buttons':
                            // Series of single select options
                            return (
                                <div className={`${styles.option}`} key={modifier.name}>
                                    <div className={styles.label}>
                                        <span className={styles.title}><ContentfulTranslationText keyName={modifier.name}>{modifier.name}</ContentfulTranslationText></span>
                                        <PdpAdditionalContent name={modifier.name} styles={styles} />
                                    </div>
                                    <div className={`${styles.optionContainer} ${styles.grid2Col}`}>
                                        {modifier.options.map((option) => 
                                            <Options 
                                                key={option.label} 
                                                title={<><ContentfulTranslationText keyName={option.label}>{option.label}</ContentfulTranslationText></>} 
                                                price={option.price} 
                                                currency={currency}
                                                disabled={option.inventory === 0 && option.inventoryTracking !== "none"}
                                                selected={(selectedModifier && modifier.option_id in selectedModifier) ? selectedModifier[modifier.option_id].label === option.label : false}  
                                                showChecked={(selectedModifier && modifier.option_id in selectedModifier) ? selectedModifier[modifier.option_id].label === option.label : false}  
                                                selectOption={() => handleSelectModifier({label: option.label, sku: option.sku, price: option.rawPrice}, modifier.option_id, modifier.name)} 
                                            />
                                        )}
                                    </div>
                                </div>
                            )

                        default:
                            return null;
                    }
                })
            }
        </div>
    );
}

export default ProductOptions;