import React from 'react';

import LogoSVG from './asset'
import LogoSVGRed from './assetRed'
// import LogoPNG from './asset.png'

import * as styles from './Logo.module.css';

const Logo = ({siteTitle, pageStyle, isScrolled}) => {
    // const { siteTitle } = siteTitle

    return (
        <div className={`${styles.root} ${pageStyle === 'transparent' ? '' : styles.defaultLogo}`}>
            {LogoSVG && pageStyle==='transparent' && !isScrolled ? (
                <LogoSVG siteTitle={siteTitle} />
            ) : (
                <LogoSVGRed siteTitle={siteTitle} />
            )}
        </div>
    );
};

export default Logo