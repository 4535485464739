import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { get } from 'lodash';
import clsx from 'clsx';
import CartContext from '../../../context/CartProvider';
import CartSummary from '../../molecules/CartSummary/CartSummary';
import CartHeader from './components/CartHeader';
import CustomItems from './components/CustomItems';
import StandardItems from './components/StandardItems';
import GiftCertificateItems from './components/GiftCertificateItems';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import { handleLink } from '../../../helpers/general';

import * as styles from './Cart.module.css';

// TODO: Update buttons to atom component
// TODO: Incomplete component - needs to be finished. Please update the readme when complete.

const Cart = ({ cartType, onClick }) => {
  const ctx = useContext(CartContext) || { state: { cart: {} } };
  const { state, removeItemFromCart, updateCartItemQuantity } = ctx;
  const { currency, lineItems, productInfo, numberItems } = state.cart;
  const { updatingItem, cartLoading, checkout } = state;

  const [consignmentSet, setConsignmentFlag] = useState(get(checkout, 'consignments[0].shipping_address', false));

  useEffect(() => {
    setConsignmentFlag(get(checkout, 'consignments[0].shipping_address', false));
  }, [checkout]);

  if (cartLoading) {
    return (
      <div className="bc-cart__empty">
        <h2 className="bc-cart__title--empty">
          <em>
            <ContentfulTranslationText keyName="loadingBag">
              Loading bag
            </ContentfulTranslationText>
          </em>
        </h2>
      </div>
    );
  }

  if (!numberItems) {
    return (
      <div className={`text-center ${styles.emptyMessageContainer}`}>
        <p className="subtitle small text-center">
          <ContentfulTranslationText keyName="emptyBag">
            Your bag is empty
          </ContentfulTranslationText>.
        </p>
        <Link to={handleLink("/")} className={styles.bcCartLink}>
          <ContentfulTranslationText keyName="lookAround">
            Take a look around
          </ContentfulTranslationText>.
        </Link>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        cartType === 'full' && styles.bcCartContainer,
        cartType === 'mini' && styles.bcMiniCartContainer
      )}
    >
      <div className="bc-cart-error">
        <p className="bc-cart-error__message"></p>
      </div>

      {cartType === 'full' && <CartHeader />}
      <div className={clsx(cartType === 'mini' && styles.bcMiniCartContent)}>
        <div className="bc-cart-list" data-cartitemlist>
          <StandardItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.physical_items}
            productInfo={productInfo}
            cartType={cartType}
            taxCalc={!consignmentSet}
          />
          <StandardItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.digital_items}
            productInfo={productInfo}
            cartType={cartType}
            taxCalc={!consignmentSet}
          />
          <CustomItems
            currency={currency}
            updatingItem={updatingItem}
            updateCartItemQuantity={updateCartItemQuantity}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.custom_items}
            productInfo={productInfo}
            cartType={cartType}
            taxCalc={!consignmentSet}
          />
          <GiftCertificateItems
            currency={currency}
            updatingItem={updatingItem}
            removeItemFromCart={removeItemFromCart}
            items={lineItems.gift_certificates}
            productInfo={productInfo}
            cartType={cartType}
            taxCalc={!consignmentSet}
          />
        </div>
        {cartType === 'mini' && <CartSummary cartType={cartType} onClick={onClick} />}
      </div>
    </div>
  );
};

export default Cart;
