import React from 'react'

const Bag = () => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.00019 21.555L4.89462 21.1077L4.89462 21.1077L4.00019 21.555ZM18.941 21.555L19.8355 22.0022L19.8355 22.0022L18.941 21.555ZM1.4182 9.39496H21.523V7.39496H1.4182V9.39496ZM20.8236 8.69548V9.34441H22.8236V8.69548H20.8236ZM18.6968 20.7059H4.2444V22.7059H18.6968V20.7059ZM2.11768 9.34441V8.69548H0.117676V9.34441H2.11768ZM4.89462 21.1077C3.06842 17.4553 2.11768 13.4279 2.11768 9.34441H0.117676C0.117676 13.7384 1.14071 18.0721 3.10577 22.0022L4.89462 21.1077ZM4.2444 20.7059C4.51976 20.7059 4.77148 20.8615 4.89462 21.1077L3.10577 22.0022C3.32141 22.4334 3.76221 22.7059 4.2444 22.7059V20.7059ZM18.0466 21.1077C18.1698 20.8615 18.4215 20.7059 18.6968 20.7059V22.7059C19.179 22.7059 19.6198 22.4334 19.8355 22.0022L18.0466 21.1077ZM20.8236 9.34441C20.8236 13.4279 19.8728 17.4553 18.0466 21.1077L19.8355 22.0022C21.8005 18.0721 22.8236 13.7384 22.8236 9.34441H20.8236ZM21.523 9.39496C21.1367 9.39496 20.8236 9.08179 20.8236 8.69548H22.8236C22.8236 7.97723 22.2413 7.39496 21.523 7.39496V9.39496ZM1.4182 7.39496C0.69994 7.39496 0.117676 7.97722 0.117676 8.69548H2.11768C2.11768 9.08179 1.80451 9.39496 1.4182 9.39496V7.39496ZM6.41214 8.90945L10.8491 1.5145L9.13413 0.485504L4.69716 7.88046L6.41214 8.90945ZM12.0921 1.5145L16.5291 8.90945L18.2441 7.88046L13.8071 0.485504L12.0921 1.5145Z" fill="white"/>
</svg>


)

export default Bag