import React from 'react'

// Alphabetical order please
import Add from './add';
import AddReview from './addReview'
import AddAddress from './addAddress'
import AfterPayArrows from './afterpay-arrows'
import AfterPayLogo from './afterpay-logo'
import ArrowDown from './arrowDown'
import ArrowLeft from './arrowLeft'
import ArrowRight from './arrowRight'
import ArrowTopRight from './arrowTopRight'
import Bag from './bag'
import Bin from './bin'
import Check from './check'
import CaretDown from './caretDown'
import Close from './close'
import Controller from './controller'
import Currency from './currency'
import GermanGermany from './de-de'
import DeleteAddress from './deleteAddress'
import Edit from './edit'
import EditAddress from './editAddress'
import EnglishAustralia from './en-au'
import EnglishCanada from './en-ca'
import EnglishEuropeanUnion from './en-eu'
import EnglishGlobal from './x-default'
import EnglishUnitedKingdom from './en-gb'
import EnglishUnitedStates from './en-us'
import Equaliser from './equaliser'
import FacebookBlack from './facebook-black'
import Facebook from './facebook'
import FeatureDot from './featureDot'
import FeatureEce from './featureEce'
import FeatureCompactDesign from './featureCompactDesign'
import FeatureFeather from './featureFeather'
import FeatureTech from './featureTech'
import ForciteLogo from './forciteLogo'
import Filter from './filter'
import GiftBox from './gift-box'
import Hamburger from './hamburger'
import Heart from './heart'
import Helmet from './helmet'
import HelpCircle from './help-circle'
import Info from './info'
import Instagram from './instagram'
import ItalianItaly from './it-it'
import KlarnaLogo from './klarna-logo'
import Linkedin from './linkedin'
import LineArrow from './lineArrow'
import Minus from './minus'
import Mk1s from './mk1s'
import Mobile from './mobile'
import People from './people'
import Pinterest from './pinterest'
import Play from './play'
import Plus from './plus'
import QuickView from './quickView'
import Search from './search'
import Star from './star'
import SubscribeArrowRight from './subscribeArrowRight'
import Swatch from './swatch'
import Tick from './tick'
import TickSml from './ticksml'
import Translate from './translate'
import Twitter from './twitter'
import User from './user'
import View from './view'
import WhiteBag from './whiteBag'
import Youtube from './youtube'

function Icon(props) {
  switch (props.symbol) {
    case 'add':
      return <Add />;
    case 'addReview':
      return <AddReview />
    case 'addAddress':
      return <AddAddress />
    case 'afterpayArrows':
      return <AfterPayArrows />
    case 'afterPayLogo':
      return <AfterPayLogo />
    case 'arrowDown':
      return <ArrowDown />
    case 'arrowLeft':
      return <ArrowLeft />
    case 'arrowRight':
      return <ArrowRight />
    case 'arrowTopRight':
      return <ArrowTopRight />
    case 'subscribeArrowRight':
      return <SubscribeArrowRight />
    case 'bag':
      return <Bag />
    case 'bin':
      return <Bin />
    case 'check':
      return <Check />
    case 'caretDown':
      return <CaretDown />
    case 'close':
      return <Close />
    case 'controller':
      return <Controller />
    case 'currency':
      return <Currency />
    case 'de-de':
      return <GermanGermany />
    case 'deleteAddress':
      return <DeleteAddress />
    case 'edit':
      return <Edit />
    case 'editAddress':
      return <EditAddress />
    case 'en-au':
      return <EnglishAustralia />
    case 'en-ca':
      return <EnglishCanada />
    case 'x-default':
      return <EnglishGlobal />
    case 'en-eu':
      return <EnglishEuropeanUnion />
    case 'en-gb':
      return <EnglishUnitedKingdom />
    case 'en-us':
      return <EnglishUnitedStates />
    case 'equaliser':
      return <Equaliser />
    case 'facebook':
      return <Facebook />
    case 'facebookblack':
      return <FacebookBlack />
    case 'featureDot':
      return <FeatureDot />
    case 'featureEce':
      return <FeatureEce />
    case 'featureCompactDesign':
      return <FeatureCompactDesign />
    case 'featureFeather':
      return <FeatureFeather />
    case 'featureTech':
      return <FeatureTech />
    case 'filter':
      return <Filter />
    case 'giftbox':
      return <GiftBox />
    case 'hamburger':
      return <Hamburger />
    case 'heart':
      return <Heart />
    case 'helmet':
      return <Helmet />
    case 'helpCircle':
      return <HelpCircle />
    case 'info':
      return <Info />
    case 'instagram':
      return <Instagram />
    case 'it-it':
      return <ItalianItaly />
    case 'klarnaLogo':
      return <KlarnaLogo />
    case 'linkedin':
      return <Linkedin />
    case 'lineArrow':
      return <LineArrow />
    case 'forciteLogo':
      return <ForciteLogo />
    case 'minus':
      return <Minus />
    case 'mk1s':
      return <Mk1s />
    case 'mobile':
      return <Mobile />
    case 'people':
      return <People />
    case 'pinterest':
      return <Pinterest />
    case 'play':
      return <Play />
    case 'plus':
      return <Plus />
    case 'quickView':
      return <QuickView />
    case 'search':
      return <Search />
    case 'star':
      return <Star />
    case 'swatch':
      return <Swatch />
    case 'tick':
      return <Tick />
    case 'ticksml':
      return <TickSml />
    case 'translate':
      return <Translate />
    case 'twitter':
      return <Twitter />
    case 'user':
      return <User />
    case 'view':
      return <View />
    case 'whiteBag':
      return <WhiteBag />
    case 'youtube':
      return <Youtube />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
