import React from 'react'

const Hamburger = () => (
    <svg
        viewBox="0 0 25 19"
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9.5h23M1 1.5h23" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default Hamburger