import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { get } from 'lodash';
// import ReviewContext from '../../../context/ReviewProvider';
import ContentfulContext from '../../../context/ContentfulProvider';

import Reviews from '../../organisms/Reviews/Reviews';
import ProductGrid from '../../organisms/ProductGrid/ProductGrid';
import Price from '../../molecules/Price/Price';
import ProductOptions from '../../molecules/ProductOptions/ProductOptions';
import AddToCartButton from '../../molecules/AddToCartButton/AddToCartButton';
import StockStatus from '../../molecules/StockStatus/StockStatus';
// import WishlistButton from '../../atoms/WishlistButton/WishlistButton';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import Dialog from "../../atoms/Dialog/Dialog";
// import AfterPayInfo from "../../atoms/AfterPayInfo/AfterPayInfo";
// import KlarnaInfo from "../../atoms/KlarnaInfo/KlarnaInfo";
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import * as styles from './ProductDetails.module.css'
import { dataLayerPush, getProductSku } from '../../../helpers/general';
import KlaviyoForm from '../../atoms/KlaviyoForm/KlaviyoForm';
import PdpAdditionalContent from '../../atoms/PdpAdditionalContent/PdpAdditionalContent';
import PdpHighlightVideo from '../../atoms/PdpHighlightVideo/PdpHighlightVideo';
import PdpGwpDialog from '../../organisms/PdpGwpDialog/PdpGwpDialog';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

const ProductDetails = props => {
    const { product, type, productInformationAnchor, setActive, setParentOptionsData, displayReviews = true, dealModifiers = null, isSpecial = false, goToSliderId } = props;
    // console.log(product);

    // const ctxReview = useContext(ReviewContext);
    // const showReviewForm = ctxReview && ctxReview.showReviewForm;
    
    // const [maxQtyMessage, setMaxQtyMessage] = useState(false);
    // const [stockStatusDialog, setStockStatusDialog] = useState(false);
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const [quantity, setQuantity] = useState(1);
    const [inStock, setInStock] = useState(true);
    const [ctl, setCtl] = useState([]);
    const [pageInit, setPageInit] = useState(false);
    const [showScrolled, setShowScrolled] = useState(false);
    const [optionsData, setOptionsData] = useState(false);
    const [resetModifier, setResetModifier] = useState(null);
    const [defaultPrice, setDefaultPrice] = useState(false);
    const [calculatedPrice, setCalculatedPrice] = useState(false);
    const [openWaitlist, setWaitlist] = useState(false);
    const [openGwp, setOpenGwp] = useState(false);
    const [gwpSku, setGwpSku] = useState(false);
    const [gwpMessage, setGwpMessage] = useState(false);
    const [isAccessory, setIsAccessory] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.scrollY > 30 && !showScrolled) {
            setShowScrolled(true);
        } else if (window.scrollY <= 30 && showScrolled) {
            setShowScrolled(false);
        }
    }, [showScrolled, setShowScrolled]);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [showScrolled, handleScroll]);

    useMemo(() => {
        const definedPrice = {
            calculated_price: (get(product, 'localPrices.calculated_price') || get(product, 'detailedPrices.calculated_price.tax_inclusive')) || product.calculated_price,
            sale_price: (get(product, 'localPrices.sale_price') || get(product, 'detailedPrices.sale_price.tax_inclusive')) || product.sale_price,
            price: (get(product, 'localPrices.price') || get(product, 'detailedPrices.price.tax_inclusive')) || product.price,
        };
        // console.log(definedPrice, product);
        
        setDefaultPrice(definedPrice);
    }, [product]);

    useEffect(() => {
        if (product &&
              typeof product.inventory_tracking !== 'undefined' &&
              typeof product.inventory_level !== 'undefined' &&
              typeof product.inventory_warning_level !== 'undefined' &&
              product.inventory_tracking === 'product' &&
              product.inventory_level < 1
        ) {
            console.log('No inventory found', product);
            setQuantity(0);
            setInStock(false);
        }

        // Set Complete the look
        if (product && 'completeTheLook' in product && product.completeTheLook.length > 0 && ctl.length === 0) {
            let randomIndex = 0;
            let randomiseProducts = product.completeTheLook;
            let currentIndex = randomiseProducts.length;
    
            while (0 !== currentIndex) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
    
            [randomiseProducts[currentIndex], randomiseProducts[randomIndex]] = [
                randomiseProducts[randomIndex], randomiseProducts[currentIndex]];
            }
    
            setCtl(randomiseProducts.length > 1 ? randomiseProducts : false);
        }

        // Page init functions
        if (!pageInit) {
            setPageInit(true);
            // Open first description accordion at page load
            const description = document.querySelector('[data-description]');
            if (description) {
                const h4s = description.querySelectorAll('h4');
                if (h4s.length > 0) {
                    h4s[0].classList.toggle('open');
                    let nextElement = h4s[0].nextElementSibling;
                    while (nextElement&& !nextElement.matches('h4')) {
                        nextElement.classList.toggle(styles.active);
                        nextElement = nextElement.nextElementSibling;
                    }
                }
            }
        }

        if (product && 'custom_fields' in product && product.custom_fields.find(cf => cf.name === 'gwp')) {
            const gwp = product.custom_fields.find(cf => cf.name === 'gwp');
            setGwpSku(gwp);
        }

        if (product && 'custom_fields' in product && product.custom_fields.find(cf => cf.name === 'gwp_message')) {
            const gwpMsg = product.custom_fields.find(cf => cf.name === 'gwp_message');
            setGwpMessage(gwpMsg ? gwpMsg.value : null);
        }

        if (product && product.categories) {
            if (product.categories.find(arr => arr.name === 'Accessories' || arr.id === 24)) {
                setIsAccessory(true);
            }
        }
    }, [product, ctl, pageInit]);

    useEffect(() => {
        if (optionsData.modifierOptions || optionsData.variantOptions) {
            let extraValue = 0;

            if (optionsData.modifierOptions) {
                Object.values(optionsData.modifierOptions).map(mOptions => {
                    if (Array.isArray(mOptions)) {
                        mOptions.map(mOption => {
                            if (mOption.price) {
                                extraValue += Number(mOption.price);
                            }
                            return true;
                        });
                    } else {
                        if (mOptions.price) {
                            extraValue += Number(mOptions.price);
                        }
                    }
                    
                    return true;
                });
            }
            
            const localPrice = get(product, 'localPrices.sale_price') || get(product, 'localPrices.price');
            const detailedPrice = localPrice || get(product, 'detailedPrices.calculated_price.tax_inclusive');
            let productPrice = detailedPrice || product.price;
            let variantPrice = 0;

            if (optionsData.variantOptions) {
                Object.values(optionsData.variantOptions).map(vOptions => {
                    let variantLocalPrice = 0;
                    if (vOptions.price) {
                        if (product.localPricing && product.localPricing.length > 0) {
                            const localPrices = product.localPricing.filter((localPrice) => vOptions.variant_id === localPrice.variant_id);
                            variantLocalPrice = localPrices && localPrices[0] ? localPrices[0].sale_price : localPrices[0].price;
                        }

                        variantPrice += variantLocalPrice || vOptions.price;
                    }
                    
                    return true;
                });

                if (variantPrice > 0) {
                    productPrice = variantPrice;
                    variantPrice += extraValue;
                }
            }

            if (extraValue > 0) {
                // setCalculatedPrice(extraValue + productPrice);
                setCalculatedPrice([{amount: productPrice, taxCalc: !(product.tax_class_id === 1)}, {amount: extraValue, taxCalc: true}]);
            } else if(variantPrice > 0) {
                // setCalculatedPrice(variantPrice);
                setCalculatedPrice([{amount: productPrice, taxCalc: !(product.tax_class_id === 1)}, {amount: extraValue, taxCalc: true}]);
            } else {
                setCalculatedPrice(false);
            }

            if ('inStock' in optionsData) {
                if (!optionsData.inStock) {
                    setInStock(false);
                } else {
                    setInStock(true);
                }
            }
        }

        setParentOptionsData(optionsData);
    }, [optionsData, setParentOptionsData, product])
    
    const onClickDesc = evt => {
        if (evt.target.matches('h4')) {
            const toOpen = !evt.target.classList.contains('open');
            // minimise already open accordion
            const description = document.querySelector('[data-description]');
            [...description.querySelectorAll('h4')].map(h4 => {
                h4.classList.remove('open');
                return true;
            });
            [...description.querySelectorAll(`.${styles.active}`)].map(ele => {
                ele.classList.remove(styles.active);
                return true;
            });
            // toggle clicked 
            if (toOpen) {
                evt.target.classList.add('open');
                let nextElement = evt.target.nextElementSibling;
                while (nextElement && !nextElement.matches('h4')) {
                    nextElement.classList.add(styles.active);
                    nextElement = nextElement.nextElementSibling;
                }
            }

            setTimeout(() => {
                const rect = description.getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop,
                    offset = document.querySelector('[data-header]').offsetHeight;
                const position = { top: (rect.top + scrollTop) - offset, left: rect.left + scrollLeft };
                
                window.scrollTo({
                    top: position.top,
                    behavior: 'smooth',
                });
            }, 350);
        }
    };

    const scrollToProductInformation = (activeTab) => {
        setActive(activeTab);
        window.scrollTo({
            top: productInformationAnchor.current.offsetTop - 100,
            behavior: 'smooth'
        });
    }
    
    const onStatusUpdate = (_status) => {
        setInStock(_status)
    }
    
    const calculateAfterPay = (data) => {
        let thePrice = 0;
        if ('localPrices' in data && data.localPrices) {
            if (data.localPrices.is_on_sale) {
                thePrice = data.localPrices.sale_price;
            } else {
                thePrice = data.localPrices.price;
            }
        } else if ('detailedPrices' in data && data.detailedPrices) {
            if (data.detailedPrices.sale_price.is_on_sale) {
                thePrice = data.detailedPrices.sale_price.tax_inclusive;
            } else {
                thePrice = data.detailedPrices.price.tax_inclusive;
            }
        } else if ('sale_price' in data && data.sale_price > 0) {
            thePrice = data.sale_price;
        } else {
            thePrice = data.price;
        }
        return <CurrencyFormatter
          currency='AUD'
          amount={thePrice / 4}
        />
    }

    const translation = (field) => {
        if ('translations' in product && product.translations && product.translations[field]) {
            return product.translations[field];
        } else  return false;
    }

    return (
        <div className={styles.productDetails}>
            <h1>
                {translation('name') ? translation('name') : (<>{product && product.name}</>)}
            </h1>

            {displayReviews && (
                <div className={styles.rateWrap} role="button"tabIndex="0" onKeyDown={() => {}} onClick={(e) => {
                    e.preventDefault();
                    scrollToProductInformation('reviews');
                }}>
                    {/* TODO: Calculate rating from Reviews.io data */}
                    <span data-rate-score className={styles.rate}>5.00</span>
                    <Reviews product={product} 
                        reviewsList={true} 
                        sku={product.variants.length > 1 ? getProductSku(product).join(';') : getProductSku(product)} 
                        type='rating'/>
                    {/* {type === "full" && (
                        <>
                            <Button
                                type="a"
                                href="#"
                                link
                                onClick={(e) => {
                                    e.preventDefault();
                                    dataLayerPush('Write A Review', {name: 'PDP Write A Review Button', content: 'Write a Review link under product title'});
                                    scrollToProductInformation('reviews');
                                }}
                            >
                                <ContentfulTranslationText keyName="writeAReview">
                                    Write a review
                                </ContentfulTranslationText>
                            </Button>
                        </>
                    )} */}
                </div>
            )}
            
            <Price
                entityId={product.entityId} 
                price={defaultPrice.price} 
                calculated_price={defaultPrice.calculated_price} 
                sale_price={defaultPrice.sale_price}
                styles={styles}
                override_price={calculatedPrice}
                taxCalc={!(product.tax_class_id === 1)}
            />

            {process.env.GATSBY_BC_DEFAULT_CURRENCY === "AUD" && (
                <div className={`bpnl ${styles.bnplText}`}>
                    <div className={styles.priceDetails}>
                        Pay In <span className="color-orange">&nbsp;4&nbsp;</span> instalments of <span className={styles.priceAfterpayDetails}>{calculateAfterPay(product)}</span> with
                        <div className={styles.priceDetailsAfterpay}>
                            <Icon symbol="afterPayLogo" />
                        </div>
                    </div>
                </div>
            )}

            {(optionsData && get(optionsData, 'deliveryData.date')) && Object.values(optionsData.requiredInventories).filter(oos => !oos).length === 0 &&
            <div className={styles.productMetaContainer}>
                <div className={styles.productStocks}>
                    <div className={styles.productStocksTime}>
                        <span><ContentfulTranslationText keyName="scheduledDelivery">Ships Within</ContentfulTranslationText>: </span>
                        <span>{(optionsData && (<><ContentfulTranslationText keyName={get(optionsData, 'deliveryData.date')}>{get(optionsData, 'deliveryData.date')}</ContentfulTranslationText></>)) || (<><ContentfulTranslationText keyName="checking">Checking</ContentfulTranslationText>...</>)}</span>
                    </div>
                    <div className={styles.stockDetails}>
                        {/* <div className={styles.arrowContainers}>
                            <Icon symbol={'arrowRight'}></Icon>
                            <Icon symbol={'arrowRight'}></Icon>
                            <Icon symbol={'arrowRight'}></Icon>
                        </div> */}
                        {(optionsData && (
                            <></>
                            // <div>{optionsData.deliveryData.inventory} left</div>
                        )) || (
                            <StockStatus key="status" product={product} onStatusUpdate={onStatusUpdate} />
                        )}
                        <PdpAdditionalContent name={'Scheduled Delivery'} styles={styles} labelOverride="More" onClick={() => {
                            dataLayerPush('Schedule Delivery More Info', {name: 'PDP Schedule Delivery More Info Button', content: 'More Info Button Under Scheduled Delivery'})
                        }} />
                    </div>
                </div>
                
            </div>}


            {type === "full" && (
                <>
                    {(('custom_fields' in product && product.custom_fields.find(cf => cf.name === 'blurb')) || translation('blurb')) && (
                    <div
                        key="description"
                        className={styles.productDescriptionShow}
                        onClick={onClickDesc}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex="0"
                        data-description
                    >
                        {translation('blurb') ? (<ContentfulRichText raw={translation('blurb').raw} />) : product.custom_fields.find(cf => cf.name === 'blurb').value}
                    </div>
                    )}

                    {(('custom_fields' in product && product.custom_fields.find(cf => cf.name === 'desc_highlight_video')) || translation('videoHighlightUrl')) && (
                        <PdpHighlightVideo 
                            data={translation('videoHighlightUrl') || product.custom_fields.find(cf => cf.name === 'desc_highlight_video')?.value} 
                            labelOverride={translation('videoHighlightLabel') || product.custom_fields.find(cf => cf.name === 'desc_highlight_label')?.value} 
                            styles={styles} />
                    )}
                    
                    {('completeTheLook' in product && product.completeTheLook.length > 0 && ctl) && (
                        <div key="completeTheLook" className={styles.completeTheLook}>
                            <h3>
                                <ContentfulTranslationText keyName="completeTheLook">
                                    Complete the Look
                                </ContentfulTranslationText>
                            </h3>
                            <ProductGrid products={ctl.slice(0, 4)} columns="2" loadInventory={true} />
                        </div>
                    )}
                </>
            )}

            {/* {currency === 'AUD' && ( */}
            {/* {process.env.GATSBY_BC_DEFAULT_CURRENCY === "AUD" && (
                <div className='bnpl'>
                    <div className={styles.priceDetails}>
                        Pay In 4 instalments of <span className={styles.priceAfterpayDetails}>{calculateAfterPay(product)}</span> with
                        <div className={styles.priceDetailsAfterpay}>
                            <Icon symbol="afterPayLogo" />
                        </div>
                    </div>

                    <div className={styles.priceDetails}>
                        Buy now, pay later 
                        <Icon symbol="klarnaLogo" />
                    </div>
                </div>
            )} */}

            <div className='productOptions'>
                <ProductOptions 
                    product={product} 
                    sku={product.sku} 
                    scrollToProductInformation={scrollToProductInformation}
                    setOptionsData={setOptionsData}
                    resetModifier={resetModifier}
                    setResetModifier={setResetModifier}
                    dealModifiers={dealModifiers}
                    isSpecial={isSpecial}
                    goToSliderId={goToSliderId}
                />
            </div>

            {/* AddToCartProductPop */}
            <PdpGwpDialog gwp={gwpSku} gwpMessage={gwpMessage} openModal={openGwp} triggerModal={(val) => {
                setOpenGwp(val)
            }}/>

            {/* Sticky Add to Cart */}
            <div className={`${styles.stickyAddToCartContainer} ${styles.showSticky}`}>
                <div className={styles.productMeta}>
                    <div className={styles.stickyTitlePriceContainer}>
                        <h1>{translation('name') ? translation('name') : (<>{product && product.name}</>)}</h1>
                        <Price
                            entityId={product.entityId} 
                            price={product.price} 
                            calculated_price={product.calculated_price} 
                            sale_price={product.sale_price}
                            styles={styles}
                            override_price={calculatedPrice}
                            taxCalc={!(product.tax_class_id === 1)}
                        />
                    </div>
                    {(optionsData && get(optionsData, 'deliveryData.date')) && Object.values(optionsData.requiredInventories).filter(oos => !oos).length === 0 &&
                    <div className={styles.productStocks}>
                        <div className={styles.productStocksTime}>
                            <span><ContentfulTranslationText keyName="scheduledDelivery">Ships Within</ContentfulTranslationText>: </span>
                            <span>{(optionsData && optionsData.deliveryData.date) || (<><ContentfulTranslationText keyName="checking">Checking</ContentfulTranslationText>...</>)}</span>
                        </div>
                        <div className={styles.stockDetails}>
                            {/* <div className={styles.arrowContainers}>
                                <Icon symbol={'arrowRight'}></Icon>
                                <Icon symbol={'arrowRight'}></Icon>
                                <Icon symbol={'arrowRight'}></Icon>
                            </div> */}
                            {(optionsData && (
                                <></>
                                // <div>{optionsData.deliveryData.inventory} left</div>
                            )) || (
                                <StockStatus key="status" product={product} onStatusUpdate={onStatusUpdate} />
                            )}
                        </div>
                    </div>}

                </div>
                <div key="addToCartButton" className={styles.addToCartButton}>
                    {inStock?.available === 'po-stock' && (
                        <span className={styles.preorderDetail}>
                        {inStock?.preorder_message.replace('%%DATE%%', inStock?.preorder_release_date)}
                        </span>
                    )}
                    {!optionsData && (
                        <Button fullWidth={true} disabled={true} level="primary" type="span"><ContentfulTranslationText keyName="checkingAvailability">Checking availability</ContentfulTranslationText>...</Button>
                    )}
                    {optionsData && (
                        <>
                            {((optionsData.requiredInventories && Object.values(optionsData.requiredInventories).filter(oos => !oos).length > 0) || !inStock) ? (
                                <>
                                    {product.custom_fields.find(cf => cf.name === 'waitlist') || isAccessory ? (
                                        <>
                                            <Button fullWidth={true} level="secondary" type="span" onClick={() => setWaitlist(!openWaitlist)}>                                            
                                                <ContentfulTranslationText keyName="joinTheWaitlist">
                                                    Join the waitlist
                                                </ContentfulTranslationText>
                                            </Button>
                                            <Dialog open={openWaitlist} size="sm" hideBtnCancel hideBtnOk>
                                                <div className={styles.waitlistForm}>
                                                    <h3>
                                                        <ContentfulTranslationText keyName="notifyMeWhenAvailable">
                                                            Notify me when available
                                                        </ContentfulTranslationText>
                                                    </h3>
                                                    <ContentfulTranslationText keyName="productWaitlistDetail">
                                                        <p>Be informed as soon as the next delivery block is released! Enter your email address below:</p>
                                                    </ContentfulTranslationText>
                                                    {/* <KlaviyoForm 
                                                        listId={product.custom_fields.find(cf => cf.name === 'waitlist').value} 
                                                        contentOverrides={{
                                                            thankYouMsg: get(translations, 'productWaitlistThankYou.title', "Thank you for joining the waitlist"),
                                                            alreadySubMsg: get(translations, 'productWaitlistSubscribed.title', "Email already on list"),
                                                            dlEventName: "Waitlist Submission",
                                                            dlEventDataName: "PDP Waitlist Submission",
                                                            dlEventDataContent: "Waitlist signup >> PDP"
                                                        }} 
                                                    /> */}
                                                    <KlaviyoForm 
                                                        backInStockFlow={true}
                                                        contentOverrides={{
                                                            thankYouMsg: get(translations, 'productWaitlistThankYou.title', "Thank you for your interest."),
                                                            alreadySubMsg: get(translations, 'productWaitlistSubscribed.title', "Email already on list"),
                                                            productId: product.variants && Array.isArray(product.variants)
                                                                        ? product.variants[0].entityId
                                                                        : product.base_variant_id
                                                        }} 
                                                    />
                                                </div>
                                                {/* {isAccessory ? (
                                                    <div className={styles.waitlistForm}>
                                                        <h4>Notify me when available</h4>
                                                        <p>Register to receive a notification when this item comes back in stock:</p>
                                                        <KlaviyoForm 
                                                            backInStockFlow={true}
                                                            contentOverrides={{
                                                                thankYouMsg: "Thank you for your interest.",
                                                                alreadySubMsg: "Email already on list",
                                                                productId: product.variants && Array.isArray(product.variants)
                                                                            ? product.variants[0].entityId
                                                                            : product.base_variant_id
                                                            }} 
                                                        />
                                                    </div>
                                                ): (
                                                    
                                                )} */}
                                            </Dialog>
                                        </>
                                    ) : (
                                        <Button fullWidth={true} disabled={true} level="primary" type="span">
                                            <ContentfulTranslationText keyName="outOfStock">
                                                Out of stock
                                            </ContentfulTranslationText>
                                        </Button>
                                    )}
                                </>
                            ) : (
                                <AddToCartButton
                                    productId={
                                        product.variants && Array.isArray(product.variants)
                                        ? product.variants[0].product_id
                                        : product.entityId
                                    }
                                    variantId={
                                        product.variants && Array.isArray(product.variants) ? product.variants[0].entityId : null
                                    }
                                    optionSelections={[
                                        ...(
                                            optionsData && 'requiredInventories' in optionsData ?
                                                Object.keys(optionsData.requiredInventories).map(optionId => ({option_id: parseInt(optionId, 10), option_value: optionsData.requiredInventories[optionId].option_value_id})) :
                                                []
                                        ),
                                        ...(
                                            optionsData && 'variantOptions' in optionsData ?
                                                Object.keys(optionsData.variantOptions).map(optionId => ({option_id: parseInt(optionId, 10), option_value: optionsData.variantOptions[optionId].option_value_id})) :
                                                []
                                        )
                                    ]}
                                    extraProducts={[
                                        ...(
                                            optionsData && 'modifierOptions' in optionsData ?
                                                Object.values(optionsData.modifierOptions).filter(option => {
                                                    if (Array.isArray(option)) {
                                                        let allValid = true;
                                                        option.map(o => {
                                                            if (!('sku' in o)) allValid = false;
                                                            return true;
                                                        });
                                                        return allValid;
                                                    } else {
                                                        return option.sku || false
                                                    }
                                                }) :
                                                []
                                        )
                                    ]}
                                    {...(inStock?.available === 'no-stock' && {disabled: true})}
                                    quantity={quantity}
                                    additionalAction={() => {
                                        setResetModifier({})

                                        if (gwpSku) {
                                            setOpenGwp(true)
                                        }
                                    }}
                                    priceDisplay={true}
                                    priceObject={{
                                        price: product.price,
                                        calculated_price: product.calculated_price,
                                        sale_price: product.sale_price,
                                        styles: styles,
                                        override_price: calculatedPrice
                                    }}
                                    taxCalc={!(product.tax_class_id === 1)}
                                >
                                    {
                                    (inStock?.available === "no-stock") ? (<><ContentfulTranslationText keyName="outOfStock">Out of stock</ContentfulTranslationText></>) : (
                                        (inStock?.available === "po-stock") ? (<><ContentfulTranslationText keyName="preOrder">Pre-order</ContentfulTranslationText></>) : (<><ContentfulTranslationText keyName="addToBag">Add to bag</ContentfulTranslationText></>)
                                    )
                                    }
                                </AddToCartButton>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ProductDetails