import React, { useContext, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ContentfulContext from '../../../context/ContentfulProvider';
// import ContentfulRichText from '../ContentfulRichText/ContentfulRichText';

const ContentfulProductTranslation = ({ productId, field, children }) => {
  const contentful = useContext(ContentfulContext);

  const productData = useStaticQuery(graphql`
      query {
          allBigCommerceProducts {
              nodes {
                id: bigcommerce_id
                name
                sku
              }
          }
      }
  `)
  const productBlocks = productData?.allBigCommerceProducts.nodes;
  const product = productBlocks.find(product => product.id === productId);

  const contentObj = useMemo(() => (product && contentful && (contentful.productTranslationData[product.sku] || false)) || false, [product, contentful]);
  // console.log(productId, contentful.productTranslationData, contentObj);
  return (
      <>
          {contentObj ? (
              <>
                {field === "name" && contentObj.name}
              </>
          ) : (<>{children}</>)}
      </>
  )
};

export default ContentfulProductTranslation;