// extracted by mini-css-extract-plugin
export var text = "Header-module--text--bQ03t";
export var shroud = "Header-module--shroud--8nA5C";
export var isActive = "Header-module--isActive--PwjiY";
export var headerWrap = "Header-module--headerWrap--Du5eX";
export var headerTransparent = "Header-module--headerTransparent--de3Kr";
export var headerScrolled = "Header-module--headerScrolled--oectf";
export var header = "Header-module--header--qlGHN";
export var burgerMenu = "Header-module--burgerMenu--9UK7f";
export var menuIcon = "Header-module--menuIcon--0Ci3P";
export var regionMenu = "Header-module--regionMenu--1sND5";
export var regionSelect = "Header-module--regionSelect--fMwB3";
export var regionIcon = "Header-module--regionIcon--1BALM";
export var regionDefault = "Header-module--regionDefault--FZyRd";
export var searchIcon = "Header-module--searchIcon--h68yk";
export var title = "Header-module--title--XD6fF";
export var searchWrapper = "Header-module--searchWrapper--uL8HO";
export var search = "Header-module--search--xuF7V";
export var close = "Header-module--close--81Ui3";
export var active = "Header-module--active--5Ooaz";
export var overflow = "Header-module--overflow--pWI-Z";
export var siteMenu = "Header-module--siteMenu--fUxzH";
export var siteMenuHeading = "Header-module--siteMenuHeading--viZFM";
export var siteMenuFooter = "Header-module--siteMenuFooter--+vc5N";
export var socialLinks = "Header-module--socialLinks--JMahS";
export var mobileAccount = "Header-module--mobileAccount--CDFQR";
export var fauxLink = "Header-module--fauxLink--ZqacT";
export var backLink = "Header-module--backLink--nAtZy";
export var parentLabel = "Header-module--parentLabel--eZca5";
export var accountItems = "Header-module--accountItems---vpFX";
export var buttonGroup = "Header-module--buttonGroup--NiIrr";
export var userMenu = "Header-module--userMenu--WFK7i";