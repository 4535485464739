import React from 'react'

const Mk1s = () => {
    return (  
        <svg
        width={200}
        height={28}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox='0 0 200 28'
      >
        <path
          d="M197.371 21.12c0 1.801-3.065 3.81-7.459 3.81h-25.139c-3.643 0-7.014-1.756-7.047-3.651h5.601c.265.48.774.846 1.841.878h24.281c1.905 0 3.457-1.57 3.457-3.494 0-1.928-1.552-3.5-3.457-3.5h-24.676c-3.972 0-7.456-1.779-7.456-3.807V6.44c0-2.033 3.484-3.813 7.456-3.813h25.139c4.394 0 7.118 2.008 7.118 3.813v.387h-4.924c-.483-.87-1.192-1.436-3.766-1.428h-23.151v.018c-1.703.16-3.479 1.55-3.479 3.48 0 1.931 1.779 3.322 3.486 3.482v.012h24.716c4.394 0 7.459 2.007 7.459 3.814v4.913Zm-7.459-11.356h-.211c-.026.002-.057.008-.086.008h-24.484c-.474 0-.858-.424-.858-.945 0-.52.384-.943.858-.943h24.484c.446 0 .808.37.854.852l.017.039h9.172V6.441c0-3.612-4.09-6.441-9.746-6.441h-25.139c-5.654 0-10.085 2.829-10.085 6.441v4.915c0 3.61 4.431 6.439 10.085 6.1h24.676c.01.004.018.015.026.02h.066a1.064 1.064 0 0 1 0 2.13h-24.544c-.428 0-.797-.253-.966-.619h-9.003v2.133c0 3.612 4.091 6.442 9.745 6.442h25.139c5.656 0 10.088-2.83 10.088-6.443v-4.913c0-3.613-4.432-6.442-10.088-6.442Z"
          fill="#FF3503"
        />
        <path
          d="M147.904 22.84h-13.669V8.373l-4.1 2.049h-5.628v-.585l9.526-5.119h11.372V19.68h2.499v3.16ZM119.81 8.026v6.958h-10.236c-.792-.382-1.584-.76-2.375-1.138l12.611-5.82ZM105.225 22.84l-16.157-7.458-11.135-.087v7.545H49.867V8.83l-1.38.657c-2.453 1.17-4.988 2.39-7.523 3.618-3.12 1.502-6.249 3.012-9.238 4.427L13.51 8.856V22.84H4.697V4.718h9.904l17.125 8.212 17.049-8.212h11.643v17.603l7.38-2.954V4.718h10.135v7.552h10.73l.295-.05c2.166-.699 4.3-1.73 6.361-2.725l9.918-4.777h10.034v.23l-19.09 8.81 1.832.886a1840.83 1840.83 0 0 0 10.301 4.941l21.862.095 3.997 3.16h-28.948Zm44.879-7.856V.021h-17.253l-12.878 6.92V.02h-15.58L93.207 5.298c-1.709.825-3.48 1.678-5.158 2.274h-5.42V.02H47.934L31.729 7.718 15.876.12 0 0v27.541h18.207V16.297l13.51 6.431 1.6-.751c3.129-1.48 6.414-3.062 9.689-4.647l2.161-1.04V27.54h37.462v-7.553h5.214l16.154 7.461 48.607.097V14.984h-2.5Z"
          fill="#fff"
        />
      </svg>
    );
}
 
export default Mk1s;