import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext, useState, useCallback, useEffect } from "react"
import AuthContext from '../../../context/AuthProvider';
import Container from "../../atoms/Container/Container";
// import InformationBar from "../../molecules/InformationBar/InformationBar";
import Logo from "../../atoms/Logo/Logo";
import Icon from "../../atoms/Icon/Icon";
// import Button from "../../atoms/Button/Button";
import SiteMenu from '../../molecules/SiteMenu/SiteMenu'
import UserMenu from '../../molecules/UserMenu/UserMenu'
// import AccountPageMenu from '../../molecules/AccountPageMenu/AccountPageMenu'
import NavSocialMedia from '../../molecules/NavSocialMedia/NavSocialMedia'
import Search from '../../molecules/Search/Search'
import RegionSelect from '../../molecules/RegionSelect/RegionSelect'
import { handleLink } from "../../../helpers/general";

import * as styles from './Header.module.css';

const Header = ({ siteTitle, messages, headerStyle }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchOverflow, setSearchOverflow] = useState(false);
  const [showScrolled, setShowScrolled] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [isRegionOpen, setRegionOpen] = useState(false);
  // const [isActiveAccountMenu, toggleAccountMenu] = useState(false);

  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const firstName = auth && auth.state.object && auth.state.object.first_name;
  const lastName = auth && auth.state.object && auth.state.object.last_name;

  let abrvName;
  if(isLoggedIn) {
    abrvName = firstName[0] + lastName[0];
  }
  
  // TODO: Obtain menu structure from external system to replace this default. Must match the structure example in /molecules/SiteMenu

  const handleScroll = useCallback(() => {
    if (window.scrollY > 30 && !showScrolled) {
        setShowScrolled(true);
    } else if (window.scrollY <= 30 && showScrolled) {
        setShowScrolled(false);
    }
  }, [showScrolled, setShowScrolled]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
        document.removeEventListener('scroll', handleScroll);
    };
  }, [showScrolled, handleScroll]);

  const hideSearch = () => {
    setSearchOverflow(false)
    setShowSearch(!showSearch);
  }

  const allowSearchOverflow = (toggle) => {
    setSearchOverflow(toggle)
  }

  return (
    <section className={`${styles.headerWrap} ${headerStyle === 'transparent' ? styles.headerTransparent : ''} ${showScrolled ? styles.headerScrolled : ''}`} data-header={true} data-page={headerStyle}>
      <div className={`${styles.shroud} ${isNavOpen ? styles.isActive : ''}`} onClick={() => setNavOpen(!isNavOpen)} role="presentation"></div>
      {/* <InformationBar messages={messages} /> */}
      <header className={styles.header}>
        <Container size="large">
          {/* Mobile menu */}
          <div className={styles.burgerMenu}>
            <div className={`${styles.menuIcon}`} role="presentation" onClick={() => setNavOpen(!isNavOpen)}> 
              <Icon symbol="hamburger" />
            </div>
          </div>

          {/* Region Selector */}
          <div className={styles.regionMenu}>
            <div className={`${styles.regionIcon} ${process.env.GATSBY_ENVIRONMENT_PATH === '' ? styles.regionDefault : ''}`} role="presentation" onClick={() => setRegionOpen(!isRegionOpen)}> 
              <Icon symbol={process.env.GATSBY_ENVIRONMENT_PATH !== '' ? process.env.GATSBY_ENVIRONMENT_PATH.replace('/', '') : 'x-default'} />
            </div>
            <div className={`${styles.regionSelect} ${isRegionOpen ? styles.isActive : ''}`}>
              <RegionSelect />
            </div>
          </div>

          {/* Search */}
          <div className={`${styles.searchWrapper} ${showSearch ? styles.active : ''} ${searchOverflow ? styles.overflow : ''}`}>
            <div className={styles.search}>
              <span className={styles.close} role="presentation" onClick={() => hideSearch()}><Icon symbol="close" /></span>
              <Search toggleSuggestions={allowSearchOverflow} showing={showSearch} />
            </div>
          </div>

          {/* Logo */}
          <div className={styles.title}>
            <Link to={handleLink("/")}><Logo siteTitle={siteTitle} pageStyle={headerStyle} isScrolled={showScrolled} /></Link>
          </div>

          {/* Site menu */}
           <div className={`${styles.siteMenu} ${isNavOpen ? styles.isActive : ''}`}>
            <div className={styles.siteMenuHeading}>
              <Logo siteTitle={siteTitle} pageStyle={headerStyle} isScrolled={false} />
              <span onClick={() => setNavOpen(!isNavOpen)} role="presentation"><Icon symbol="close" /></span>
            </div>
            <SiteMenu setNavOpen={setNavOpen} />
            {/* <div className={styles.mobileAccount}>
              {!isLoggedIn && (
                <>
                  <p>Save your favourite items, speed up the checkout and track your orders.</p>
                  <div className={`flex-between ${styles.buttonGroup}`}>
                    <Button level="primary" size="small" href="/login">Login</Button>
                    <Button level="secondary" size="small" href="/signup">Signup</Button>
                  </div>
                </>
              )}
              {isLoggedIn && (
                <div data-accountmenuwrap>
                  <span role="presentation" className={styles.fauxLink} onClick={() => toggleAccountMenu(!isActiveAccountMenu)}><Icon symbol="user" /> Hi {firstName}</span>
                  <div className={`${isActiveAccountMenu ? styles.isActive : ''}`} data-accountmenu>
                    <span role="presentation" className={styles.backLink} onClick={() => toggleAccountMenu(!isActiveAccountMenu)}>Back</span>
                    <span className={styles.parentLabel}>My Account</span>
                    <div className={styles.accountItems}>
                      <AccountPageMenu isLoggedIn={isLoggedIn} firstName={firstName} />
                    </div>
                  </div>
                </div>
              )}
            </div> */}
            <div className={styles.siteMenuFooter}>
              <div>
                <div className={styles.text}><p>&copy; Forcite Helmet Systems Pty Ltd.</p></div>
                <div className={styles.socialLinks}>
                  <NavSocialMedia />
                </div>
              </div>
            </div>
          </div> 
                
          {/* User menu */}
          <div className={styles.userMenu}>
            <UserMenu pageStyle={headerStyle} isScrolled={showScrolled} searchVisibility={showSearch} searchHandler={setShowSearch} isLoggedIn={isLoggedIn} abrvName={abrvName} />
          </div>

        </Container>
      </header>
    </section>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
